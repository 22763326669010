import { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import AdminService from "../../../services/admin.service";
import { useParams, useHistory } from "react-router-dom";
import Validations from "../../../validations/validations";
import { decryptData } from "../../../services/auth-service";
import Cities from "../../cities.json";
import InputFieldDropdown from "../../commonModules/InputFieldDropdown";
import ErrorMessage from "../../commonModules/ErrorMessage";
const options = [
  { value: "HEALTH", label: "HEALTH Insurance" },
  { value: "MOTOR", label: "MOTOR Insurance" },
  { value: "LIFE", label: "LIFE Insurance" },
];

const Popup = ({ onClose, show }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Branch Updated Successfully !!</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button type="button" className="primary-btn" onClick={onClose}>
          Okay!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function ContainerForBranchView(props) {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [cities, setCities] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  const [initialData, setInitialData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const uniqueStates = [...new Set(Cities.map((item) => item.state))];
    setCities(
      Cities.map((item) => ({
        label: item.name,
        value: item.name,
        state: item.state,
      }))
    );
    setAllStates(uniqueStates.map((state) => ({ label: state, value: state })));
    setFilteredStates(
      uniqueStates.map((state) => ({ label: state, value: state }))
    );
    // })
    // .catch(error => console.error('Error fetching cities:', error));
  }, []);

  const { id } = useParams();
  const fetchData = async () => {
    try {
      const data = {
        id: id,
      };
      const res = await AdminService.getBranchByID(data);
      setInitialData(res?.data?.data);
      formik.setValues(res?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const validationSchema = Validations.branchViewValidations;
  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleSubmit();
    },
  });

  const [value, setValue] = useState("");
  const maxLength = 256;
  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setValue(inputValue);
    }
  };
  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };

  const handleSubmit = async () => {
    const userData = formik.values;
    userData.lineOfBusiness = formik.values.lineOfBusiness
      .slice(1, -1)
      .split(", ");
    userData.id = id;
    try {
      await AdminService.addBranch(userData).then((data) => {
        setShowPopup(true);
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setIsEditing(false);
    window.location.href = "/ListOfBranch";
  };

  const handleDeactivate = async () => {
    const confirmation = window.confirm("Are you sure ?");
    if (confirmation) {
      const userData = formik.values;
      userData.status =
        formik.values.status === "ACTIVE" ? "DEACTIVE" : "ACTIVE";
      userData.lineOfBusiness = formik.values.lineOfBusiness
        .slice(1, -1)
        .split(", ");
      userData.id = id;
      try {
        await AdminService.addBranch(userData);
        setShowPopup(true);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleCityChange = (selectedCityId) => {
    const selectedCity = cities.find((city) => city.value === selectedCityId);
    if (selectedCity) {
      setFilteredStates([
        { label: selectedCity.state, value: selectedCity.state },
      ]);
      formik.setFieldValue("state", selectedCity.state); // Automatically set the state in formik
    } else {
      setFilteredStates(allStates); // Reset to all states if no city is selected
      formik.setFieldValue("state", ""); // Reset the state in formik
    }
  };

  return (
    <>
      {initialData && initialData !== undefined && initialData !== null ? (
        <>
          <Form className="form-bg">
            {isEditing ? <h6 className="float-end">*Editing</h6> : null}

            <Row style={{ textAlign: "left" }}>
              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="name"
                  label="Branch Name*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Control
                      name="name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      className="floating-input"
                    />
                  ) : (
                    <Form.Control
                      name="name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      className="floating-input"
                      disabled
                    />
                  )}
                </FloatingLabel>
                <Form.Text className="text-danger">
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger">{formik.errors.name}</div>
                  ) : null}
                </Form.Text>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="address"
                  label="Address*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Control
                      name="address"
                      type="address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      className="floating-input"
                      maxLength={maxLength}
                    />
                  ) : (
                    <Form.Control
                      name="address"
                      type="address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      className="floating-input"
                      maxLength={maxLength}
                      disabled
                    />
                  )}

                  <div
                    style={{
                      marginTop: "5px",
                      fontSize: "0.6rem",
                      textAlign: "right",
                    }}
                  >
                    {maxLength - formik.values.address.length}/256
                  </div>

                  {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
                  <Form.Text className="text-danger">
                    {formik.touched.address && formik.errors.address ? (
                      <div className="text-danger">{formik.errors.address}</div>
                    ) : null}
                  </Form.Text>
                </FloatingLabel>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <select
                  id="city"
                  className="form-select"
                  disabled={!isEditing}
                  {...formik.getFieldProps("city")}
                  onChange={(e) => {
                    formik.setFieldValue("city", e.target.value);
                    handleCityChange(e.target.value);
                  }}
                >
                  <option value="">Select a city</option>
                  {cities.map((data, index) => (
                    <option key={data.id || index} value={data.value}>
                      {data.label}
                    </option>
                  ))}
                </select>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <select
                  id="state"
                  className="form-select"
                  disabled={true}
                  value={formik.values.state}
                  {...formik.getFieldProps("state")}
                >
                  <option value="">Select a state</option>
                  {filteredStates.map((state, index) => (
                    <option key={index} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </select>
              </Col>

              {/* <Col md={6} sm={12} className="mb-3">
                <InputFieldDropdown
                  formikFieldName="city"
                  label="City*"
                  optionsArray={cities}
                  formik={formik}
                  disabled={!isEditing}
                  value={formik.values.city}
                />
                <ErrorMessage formik={formik} fieldValue="city" />
              </Col>
              <Col md={6} sm={12} className="mb-3">
                <InputFieldDropdown
                  formikFieldName="state"
                  label="State*"
                  optionsArray={filteredStates}
                  formik={formik}
                  disabled={isEditing}
                  value={formik.values.state}
                />
                <ErrorMessage formik={formik} fieldValue="state" />
              </Col> */}

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="mobileNumber"
                  label="Phone Number*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Control
                      name="mobileNumber"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobileNumber}
                      className="floating-input"
                    />
                  ) : (
                    <Form.Control
                      name="mobileNumber"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobileNumber}
                      className="floating-input"
                      disabled
                    />
                  )}
                </FloatingLabel>
                <Form.Text className="text-danger">
                  {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                    <div className="text-danger">
                      {formik.errors.mobileNumber}
                    </div>
                  ) : null}
                </Form.Text>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="email"
                  label="Email*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Control
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="floating-input"
                    />
                  ) : (
                    <Form.Control
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="floating-input"
                      disabled
                    />
                  )}

                  {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
                  <Form.Text className="text-danger">
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-danger">{formik.errors.email}</div>
                    ) : null}
                  </Form.Text>
                </FloatingLabel>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="headOffice"
                  label="Head Office*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Control
                      name="headOffice"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.headOffice}
                      className="floating-input"
                    />
                  ) : (
                    <Form.Control
                      name="headOffice"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.headOffice}
                      className="floating-input"
                      disabled
                    />
                  )}
                </FloatingLabel>
                <Form.Text className="text-danger">
                  {formik.touched.headOffice && formik.errors.headOffice ? (
                    <div className="text-danger">
                      {formik.errors.headOffice}
                    </div>
                  ) : null}
                </Form.Text>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="pincode"
                  label="Pincode *"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Control
                      name="pincode"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pincode}
                      className="floating-input"
                    />
                  ) : (
                    <Form.Control
                      name="pincode"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pincode}
                      className="floating-input"
                      disabled
                    />
                  )}
                </FloatingLabel>
                <Form.Text className="text-danger">
                  {formik.touched.pincode && formik.errors.pincode ? (
                    <div className="text-danger">{formik.errors.pincode}</div>
                  ) : null}
                </Form.Text>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="contactPersonName"
                  label="Contact Person Name *"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Control
                      name="contactPersonName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contactPersonName}
                      className="floating-input"
                    />
                  ) : (
                    <Form.Control
                      name="contactPersonName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contactPersonName}
                      className="floating-input"
                      disabled
                    />
                  )}
                </FloatingLabel>
                <Form.Text className="text-danger">
                  {formik.touched.contactPersonName &&
                  formik.errors.contactPersonName ? (
                    <div className="text-danger">
                      {formik.errors.contactPersonName}
                    </div>
                  ) : null}
                </Form.Text>
              </Col>

              <Col sm={12} md={6} className="pt-2">
                <Form.Group
                  controlId="lineOfBusiness"
                  className="floating-input"
                >
                  <FloatingLabel className="floating-input">
                    Line of Business
                  </FloatingLabel>

                  <Form.Control
                    name="lineOfBusiness"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lineOfBusiness.slice(1, -1)}
                    className="floating-input"
                    disabled
                  />

                  <Form.Text className="text-danger">
                    {formik.touched.lineOfBusiness &&
                    formik.errors.lineOfBusiness ? (
                      <div className="text-danger">
                        {formik.errors.lineOfBusiness}
                      </div>
                    ) : null}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            {!isEditing ? (
              <Button
                className="primary-btn"
                style={{ marginRight: "5px" }}
                type="submit"
                onClick={handleEdit}
              >
                Edit
              </Button>
            ) : (
              <Button
                className="primary-btn"
                style={{ alignItems: "right", marginRight: "5px" }}
                type="submit"
                onClick={formik.handleSubmit}
              >
                Save
              </Button>
            )}
            {!isEditing ? (
              formik.values.status === "ACTIVE" ? (
                <Button
                  type="button"
                  className="btn-secondary"
                  onClick={handleDeactivate}
                >
                  Deactivate
                </Button>
              ) : (
                <Button
                  type="button"
                  className="btn-secondary"
                  onClick={handleDeactivate}
                >
                  Activate
                </Button>
              )
            ) : (
              <>
                <Button
                  type="button"
                  className="btn-secondary"
                  onClick={handleCancel}
                >
                  {}
                  Cancel
                </Button>
              </>
            )}
          </Form>
          {showPopup && <Popup onClose={handleClosePopup} show={true} />}
        </>
      ) : (
        <span>Data not found</span>
      )}
    </>
  );
}
