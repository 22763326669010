import { useEffect, useState } from "react";
import { useFormik } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "./Popup.css";
import { useParams, useHistory } from "react-router-dom";
import AgentService from "../../../services/agent.service";
import Validations from "../../../validations/validations";
import { decryptData } from "../../../services/auth-service";

const Popup = ({ onClose, show }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
        User has been successfully updated !</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button type="button" className="primary-btn" onClick={onClose}>
          Okay!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function ContainerForAgentView(props) {

  const initialValues = {
    customer_name: null,
    test_motor_lead_1: null,
    mobile_number: null,
    RTO_no: null,
    caller_name: null,
    email_id: null,
    residential_address: null,
    date_of_birth: null,
    date_of_anniversary: null,
    pan_number: null,
    aadhar_no: null,
    driving_licence_no: null,
    make: null,
    model: null,
    varient: null,
    registration_date: null,
    Policy_Exp_date: null,
    policy_no: null,
    insurance_company: null,
    chassis_no: null,
    engine_no: null,
    customer_reference_name: null,
    customer_reference_number: null,
  };

  const history = useHistory();
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  const [isEditing, setIsEditing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const validationSchema = Validations.userValidations;

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
    },
  });

  useEffect(() => {
    fetchData();
  }, []);
  const { email } = useParams();

  const fetchData = async () => {
    try {
      await AgentService.getCustomerInformation({
        rtoNo: "MH01BB4829",
        module: "motor",
      }).then((res) => {
        setInitialData(res?.data[0]);
        formik.setValues(res?.data[0]);
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [value, setValue] = useState("");
  const maxLength = 256;
  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setValue(inputValue);
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setIsEditing(false);
    window.location.href = "/";
  };

  return (
    <>
      {initialData && initialData !== undefined && initialData !== null ? (
        <>
          <Form className="form-bg" onSubmit={formik.handleSubmit}>
            {isEditing ? <h6 className="float-end">*Editing</h6> : null}

            {/* Basic Information */}
            <Row style={{ textAlign: "left" }}>
              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="name"
                  label="Name*"
                  className=""
                  aria-autocomplete="off"
                >
             
                    <Form.Control
                    name="name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    value={formik.values.name}
                      className="floating-input"
                    disabled={!isEditing}
                    />
               
                </FloatingLabel>
                <Form.Text className="text-danger">
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger">{formik.errors.name}</div>
                  ) : null}
                </Form.Text>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="mobileNumber"
                  label="Mobile Number*"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Control
                    name="mobileNumber"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile_number}
                    className="floating-input"
                    disabled={true}
                  />
                </FloatingLabel>
                {/* Add validation for mobile number if needed */}
              </Col>
            </Row>

            <Row style={{ textAlign: "left" }}>
              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="module"
                  label="Module"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Control
                    name="module"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.module}
                    className="floating-input"
                    disabled={true}
                  />
                </FloatingLabel>
                {/* Add validation if needed */}
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="alternate_number1"
                  label="Alternate Number 1"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Control
                    name="alternateNumber1"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.alternate_number1}
                    className="floating-input"
                    disabled={!isEditing}
                  />
                </FloatingLabel>
                {/* Add validation if needed */}
              </Col>
            </Row>

            <Row style={{ textAlign: "left" }}>
              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="email_id"
                  label="Email ID"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Control
                    name="emailId"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email_id}
                    className="floating-input"
                    disabled={!isEditing}
                  />
                </FloatingLabel>
                {/* Add email validation if needed */}
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="residentialAddress"
                  label="Residential Address"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Control
                    name="residential_address"
                    as="textarea"
                    rows={3}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.residential_address}
                    className="floating-input"
                    disabled={!isEditing}
                  />
                </FloatingLabel>
                {/* Add validation if needed */}
              </Col>
            </Row>
            <Row style={{ textAlign: "left" }}>
              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="updated_address"
                  label="Updated Address"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Control
                    name="updated_address"
                    as="textarea"
                    rows={3}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.updated_address}
                    className="floating-input"
                    disabled={!isEditing}
                  />
                </FloatingLabel>
                {/* Add validation if needed */}
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="dob"
                  label="Date of Birth"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Control
                    name="dob"
                    type="date"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dob}
                    className="floating-input"
                    disabled={!isEditing}
                  />
                </FloatingLabel>
                {/* Add validation if needed */}
              </Col>
            </Row>

            <Row style={{ textAlign: "left" }}>
              {/* Add fields for doa, panNo, and aadhaarNumber similarly */}
            </Row>

            <Row style={{ textAlign: "left" }}>
              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="driving_lic"
                  label="Driving License"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Control
                    name="driving_lic"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.driving_lic}
                    className="floating-input"
                    disabled={!isEditing}
                  />
                </FloatingLabel>
                {/* Add validation if needed */}
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="rto_no"
                  label="RTO Number"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Control
                    name="rto_no"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.rto_no}
                    className="floating-input"
                    disabled={!isEditing}
                  />
                </FloatingLabel>
                {/* Add validation if needed */}
              </Col>
            </Row>
            <Row style={{ textAlign: "left" }}>
              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="doa"
                  label="Date of Admission"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Control
                    name="doa"
                    type="date"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.doa}
                    className="floating-input"
                    disabled={!isEditing}
                  />
                </FloatingLabel>
                {/* Add validation if needed */}
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="pan_no"
                  label="PAN Number"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Control
                    name="panNo"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.pan_no}
                    className="floating-input"
                    disabled={!isEditing}
                  />
                </FloatingLabel>
                {/* Add validation if needed */}
              </Col>
            </Row>

            <Row style={{ textAlign: "left" }}>
              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="aadhaar_number"
                  label="Aadhaar Number"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Control
                    name="aadhaarNumber"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.aadhaar_number}
                    className="floating-input"
                    disabled={!isEditing}
                  />
                </FloatingLabel>
                {/* Add validation if needed */}
              </Col>
            </Row>

            {!isEditing ? (
              <Button
                className="primary-btn"
                style={{ marginRight: "5px" }}
                type="submit"
                onClick={handleEdit}
              >
                Edit
              </Button>
            ) : (
              <Button
                className="primary-btn"
                style={{ alignItems: "right", marginRight: "5px" }}
                type="submit"
                onClick={formik.handleSubmit}
              >
                Save
              </Button>
            )}
            {!isEditing ? null : (
              <>
                <Button
                type="button"
                className="btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              </>
            )}
          </Form>
          {showPopup && <Popup onClose={handleClosePopup} show={true} />}
        </>
      ) : (
        <span>No data Found for {email}</span>
      )}
    </>
  );
}
