import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import { decryptData } from "../../services/auth-service";
import AdminService from "../../services/admin.service";
import Validations from "../../validations/validations"
export default function UpdatePassword(props) {
  const [user,setUser]=useState();
  const history = useHistory();
  const [initialData, setInitialData] = useState({
    // oldPassword: "",
    id:"",
    password: "",
    newpassword: "",
  });

  useEffect(() => {
    let user=decryptUser();
   
    if (!user) {
      history.push('/login');
    }

  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        setInitialData((prevData) => ({
          ...prevData,
          id: user1.id,
        }))
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  const validationSchema = Validations.updatePasswordValidations;

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      // setFormState(values);
    },
    onReset: (values) => {
      "";
    },
  });

  const handleSubmit =()=>{
    alert("yet to integrate",formik.values)
  }
  return (
    <React.Fragment>
      <Container>
        <Row>
          <h4 className=" mt-5">Update Password</h4>
          <div className="bg-image signin-wrap" style={{ height: "auto" }}>
            <div className="inner-sign-in">
              <Form className="Auth-form">
                <div className="Auth-form-content">
                  {/* <FloatingLabel
                    controlId="floatingInput"
                    label="Old Password"
                    className=""
                    aria-autocomplete="off"
                  >
                    <Form.Control
                      name="oldPassword"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.oldPassword}
                      className="floating-input"
                    />
                    <Form.Text className="text-danger">
                      {formik.touched.oldPassword && formik.errors.oldPassword ? (
                        <div className="text-danger">
                          {formik.errors.oldPassword}
                        </div>
                      ) : null}
                    </Form.Text>
                  </FloatingLabel> */}

                  <FloatingLabel
                    controlId="floatingInput"
                    label="New Password"
                    className=""
                    aria-autocomplete="off"
                  >
                    <Form.Control
                      name="password"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      className="floating-input"
                    />
                    <Form.Text className="text-danger">
                      {formik.touched.password && formik.errors.password ? (
                        <div className="text-danger">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </Form.Text>
                  </FloatingLabel>

                  <FloatingLabel
                controlId="floatingInput"
                label="Confirm Password"
                className=""
                aria-autocomplete="off"
              >
                <Form.Control
                  name="newpassword"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newpassword}
                  className="floating-input"
                />
                <Form.Text className="text-danger">
                  {formik.touched.newpassword &&
                  formik.errors.newpassword ? (
                    <div className="text-danger">
                      {formik.errors.newpassword}
                    </div>
                  ) : null}
                </Form.Text>
              </FloatingLabel>



                  <div className="inner-sign-in">
                <Button
                  className="primary-btn"
                  style={{ alignItems: "right", marginRight: "5px" }}
                  type="submit"
                  // onClick={handleSubmit}
                  onClick={formik.handleSubmit}
                >
                  Reset
                </Button>

                <Button
                  type="button"
                  className="btn-secondary"
                  disabled={!formik.dirty}
                  onClick={() => formik.resetForm()}
                >
                  Cancel
                </Button>
              </div>

                </div>
              </Form>
            </div>
          </div>

          <Col className="mb-3"></Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
