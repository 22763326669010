import React, { useState, useEffect } from "react";
import AdminService from "../../../services/admin.service";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Container, Row, Col, Form, Modal } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Select from "../../commonModules/InputFieldMultipleSelect";
import { useHistory } from 'react-router-dom';
import { decryptData } from "../../../services/auth-service";
import Validations from "../../../validations/validations";

const AddAgency = (props) => {

  const history = useHistory();
  const [user,setUser]=useState(null)

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1)
        if(user1.role!=="ADMIN" )
          history.push("/accessdenied");
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  const [showPopup, setShowPopup] = useState(false);
  const agencyTypeValues = [
    { label: "Single Branch Agency", value: "SINGLEBRANCH" },
    { label: "Multiple Branch Agency", value: "MULTIPLEBRANCH" },
  ];

  const lobValues = [
    { label: "Health", value: "HEALTH" },
    { label: "Motor", value: "MOTOR" },
    { label: "Life", value: "LIFE" },
  ];

  const validationSchema = Validations.AddAgencyValidation;

  const formik = useFormik({
    initialValues: {
      agencyName: "",
      lineOfBusiness: [],
      agencyType: "",
      status: "ACTIVE"
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const addAgencyRequest = {
        agencyName: values.agencyName,
        agencyType: [values.agencyType],
        lineOfBusiness: values.lineOfBusiness.map((ele) => ele.value),
        createdBy: user && user.email ? user.email : "",
        status: values.status
      };
      try {
        let res = await AdminService.addAgency(addAgencyRequest);
        setShowPopup(true)
      } catch (error) {
        console.error("Error:", error);
       
      }
    },
  });

  useEffect(() => {
    // Add the "car-page" class to the body element when the component mounts
    document.body.classList.add("car-page");
    // Clean up function: Remove the "car-page" class when the component unmounts
    return () => {
      document.body.classList.remove("car-page");
    };
  }, []);

  const handleAgencyTypeChange = (value) => {
    formik.setFieldValue("agencyType", value.value);
  };

  const handleLobChange = (value) => {
    formik.setFieldValue("lineOfBusiness", value);
  };
  
  const handleClosePopup = () => {
    setShowPopup(false);
    window.location.href='/ListAgency'
  };

  return (
    <>
      <Container>
        <Row>
          <h4 className="mb-5 mt-5">Add Agency</h4>
          <Col className="mb-3">
            <Form className="form-bg" onSubmit={formik.handleSubmit}>
              <Row style={{ textAlign: "left" }}>
                <Col sm={12} md={6} className="pt-2">
                  <FloatingLabel
                    controlId="agencyName"
                    label="Agency Name"
                    aria-autocomplete="off"
                  >
                    <Form.Control
                      name="agencyName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.agencyName}
                      className="floating-input"
                      placeholder="Name of Agency"
                    />
                  </FloatingLabel>
                  <Form.Text className="text-danger">
                    {formik.touched.agencyName && formik.errors.agencyName ? (
                      <div className="text-danger">
                        {formik.errors.agencyName}
                      </div>
                    ) : null}
                  </Form.Text>
                </Col>
                <Col sm={12} md={6} className="pt-2">
                  <FloatingLabel>Agency Type</FloatingLabel>
                  <Select
                    options={agencyTypeValues}
                    onChange={handleAgencyTypeChange}
                    isSearchable={false}
                    isMulti={false}
                    value={formik.values.agencyType}
                  />
                  <Form.Text className="text-danger">
                    {formik.touched.agencyType && formik.errors.agencyType ? (
                      <div className="text-danger">
                        {formik.errors.agencyType}
                      </div>
                    ) : null}
                  </Form.Text>
                </Col>
                <Col sm={12} md={6} className="pt-2">
                  <FloatingLabel className="floating-input">
                    Line of Business
                  </FloatingLabel>
                  <Select
                    options={lobValues}
                    onChange={handleLobChange}
                    isSearchable={false}
                    isMulti={true}
                    value={formik.values.lineOfBusiness}
                  />
                  <Form.Text className="text-danger">
                    {formik.touched.lineOfBusiness &&
                    formik.errors.lineOfBusiness ? (
                      <div className="text-danger">
                        {formik.errors.lineOfBusiness}
                      </div>
                    ) : null}
                  </Form.Text>
                </Col>
                <Col sm={12} md={12} className="d-flex pt-4">
                  <Button className="primary-btn" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>

            {showPopup && (
         <Modal show={showPopup} onHide={handleClosePopup}>
         <Modal.Header closeButton>
           <Modal.Title>Agency Added Successfully !</Modal.Title>
         </Modal.Header>
       
         <Modal.Footer>
           <Button type="button" className="primary-btn" onClick={handleClosePopup}>
             Okay!
           </Button>
         </Modal.Footer>
       </Modal>
     
      )}

          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddAgency;
