import React, { useEffect, useState } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import { Table, Button, Row, Pagination, Form } from "react-bootstrap";
import jsPDF from "jspdf";
import "../Agent/paginations.css";
//import jsPDF from '../../node_modules/jspdf/dist/jspdf.umd.min.js'
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { DateConvertFunction } from "../../commonModules/CommonCode";
import { useHistory } from "react-router-dom";
import AdminService from "../../../services/admin.service";
import { decryptData } from "../../../services/auth-service";
import { FaSearch } from "react-icons/fa";
var CryptoJS = require("crypto-js");

export default function ListOfAgent() {

  const history = useHistory();
  const [user, setUser] = useState(null);
  const [data1, setData1] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState(data1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNo, setpageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState(false);

  const encryptEmail = (email) => {
    const encrypted = CryptoJS.AES.encrypt(email, "dynamipass").toString();
  // Replace problematic characters
  return encrypted.replace(/\//g, "_").replace(/\+/g, "-").replace(/=/g, ".");
  };

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {

      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [user, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setPageSize(5);
    setpageNo(0);
    fetchData();
  }, [search]);

  const fetchData = async () => {
    try {
        const data = { pageSize, pageNo,role:"POSP", searchString: searchTerm };
        await AdminService.listUsers(data).then((res) => {
          setData1(res?.data?.data?.content);
          setData(res?.data?.data?.content); // Assuming API response contains 'results' key with data
          setTotalPages(res?.data?.data?.totalPages);
        });
   
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData1 = async (e) => {
    const pageSize = parseInt(e.target.value);
    try {
        const data = { pageSize, pageNo,role:"POSP", searchString: searchTerm };
        await AdminService.listUsers(data).then((res) => {
          setData1(res?.data?.data?.content);
          setData(res?.data?.data?.content); // Assuming API response contains 'results' key with data
          setTotalPages(res?.data?.data?.totalPages);
          // Assuming API response contains 'totalPages' key
        });
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (page) => {
    setpageNo(page - 1);
    setCurrentPage(page);
  };

  const handleExportPDF = () => {
    // const doc = new jsPDF();
    const doc = new jsPDF("p", "pt", "a4");
    const table = document.getElementById("data-table");
    var res = doc.autoTableHtmlToJson(table);
    doc.text("List Of Customers", 300, 50, "center");
    let str = new Date().toLocaleDateString();
    doc.setFontSize(8);
    doc.text("Date:" + str, 14, 30);
    res.columns.pop();
    let columns = res.columns;
    let rows = [];
    data1.map((element) => rows.push(Object.values(element)));

    doc.autoTable(columns, rows, {
      theme: "grid",
      startY: 70,
      headStyles: { fillColor: "#f7d0d6" },
      showHead: "firstPage",
      styles: { overflow: "hidden", fontSize: 7 },
    });

    doc.save("test Report");
  };


  return (
    <div>
      <React.Fragment>
        <h4 className="mb-5 mt-5" style={{ alignItems: "left" }}>
          List Of POSP
        </h4>
        <Container>
          <Row>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  width: "250px",
                  marginRight: "10px",
                  padding: "5px 10px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
              <button
                onClick={() => setSearch(!search)}
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  padding: "5px",
                }}
              >
                <FaSearch size={20} />
              </button>
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={handleExportPDF}
                  className="primary-btn"
                  size="sm"
                  style={{
                    marginBottom: "10px",
                    marginLeft: "10px",
                    width: "150px",
                  }}
                >
                  Export PDF
                </Button>
                <Button
                  onClick={(event) => (window.location.href = "/onBoardingPage")}
                  className="secondary-btn"
                  variant="secondary"
                  size="sm"
                  style={{
                    marginBottom: "10px",
                    marginLeft: "10px",
                    width: "150px",
                  }}
                >
                  Add POSP
                </Button>
              </div>
            </div>

            <Table
              className="basic-table"
              striped
              bordered
              hover
              id="data-table"
            >
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#f7d0d6" }}>ID</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Name</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Email</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Phone Number</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Create Date</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Status</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.firstName} {item.lastName}</td>
                    <td>{item.email}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{DateConvertFunction(item.createdAt, 2)}</td>
                    <td>{item.status}</td>
                    <td>
                      <Link to={`/viewPosp/${encryptEmail(item.email)}`}>View Details</Link>
                      {/* <Link to={`/viewAgent/${item.agentId}`}>
                        View Details
                      </Link> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {data ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "end",
                  }}
                >
                  <div
                    className="justify-content-end"
                    style={{ marginRight: "5px", width: "4cm" }}
                  >
                    <label
                      htmlFor="pageSizeDropdown"
                      style={{ marginRight: "10px" }}
                    >
                      Page Size:
                    </label>
                    <select
                      style={{ height: "68%" }}
                      id="pageSizeDropdown"
                      value={pageSize}
                      onChange={(e) => {
                        e.preventDefault();
                        setPageSize(parseInt(e.target.value));
                        fetchData1(e);
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <Pagination className="justify-content-end">
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {[...Array(totalPages).keys()].map((page) => (
                      <Pagination.Item
                        key={page + 1}
                        active={page + 1 === currentPage}
                        onClick={() => handlePageChange(page + 1)}
                        style={{ accentColor: "#f4909f" }}
                      >
                        {page + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                </div>
              </>
            ) : null}
          </Row>
        </Container>
      </React.Fragment>
    </div>
  );
}
