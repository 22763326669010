import React from "react";
import {
  Button,
  Col,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import terms from "../../components/style/assets/img/insurance/terms-and-conditions.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Testimonials() {
  return (
    <React.Fragment>
      <>
        <section className="section-testimonials">
          <div className="">
            <div className="w-full px-[12px]">
              <div
                className="bb-testimonials relative"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <div
                  className="section-title text-center mb-[20px] pb-[20px] z-[5] relative flex justify-center max-[991px]:pb-[0]"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div className="section-detail max-[991px]:mb-[12px]">
                    <h2 className="bb-title font-quicksand mb-[0] p-[0] text-[30px] font-bold text-[#3d4750] relative inline capitalize leading-[1] tracking-[0.03rem] max-[767px]:text-[23px]">
                      Our <span className="text-[#6c7fd8]">Testimonials</span>
                    </h2>
                  </div>
                </div>
  
                <div className="">
                  <div className="bb-testimonials-inner max-w-[900px] m-[auto] max-[1399px]:max-w-[800px]">
                    <div className="flex flex-wrap mx-[-12px] testimonials-row">
                      <div className="min-[768px]:w-[33.33%] w-full px-[12px] max-[767px]:hidden">
                        <div className="testimonials-image relative max-[575px]:mb-[20px] max-[575px]:max-w-[200px]">
                          <img
                            src="assets/img/testimonials/Shubham-Mahadik.jpg"
                            alt="testimonials"
                            className="w-full rounded-[30px] block"
                          />
                        </div>
                      </div>
                      <div className="min-[768px]:w-[66.66%] w-full px-[12px]">
                        <div className="testimonials-contact h-full flex flex-col justify-end">
                          <div className="user max-[767px]:flex max-[767px]:items-center">
                            <img
                              src="assets/img/testimonials/Shubham-Mahadik.jpg"
                              alt="testimonials"
                              className="w-full hidden rounded-[15px] max-[767px]:max-w-[60px] max-[767px]:mr-[15px] max-[767px]:flex"
                            />
                            <div className="detail">
                              <h4 className="font-quicksand text-[#3d4750] tracking-[0.03rem] leading-[1.2] mb-[8px] text-[20px] font-bold max-[767px]:mb-[4px] max-[767px]:text-[18px]">
                                Shubham Mahadik
                              </h4>
                            </div>
                          </div>
                          <div className="inner-contact bg-[#fff] mt-[10px] border-[1px] border-solid border-[#eee] p-[20px] rounded-[30px]">
                            <p
                              className="font-Poppins text-[#686e7d] text-[14px] leading-[25px] tracking-[0.03rem] font-light"
                              align="justify"
                            >
                              My experience with Navnit insurance is that my RM
                              Santosh poojari is very co-operative, quick service,
                              easy to communicate and very professional. So no
                              problem with service from navnit or my RM. Its great
                              to do business with you.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
  
}
