import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import travel from "../../../components/style/assets/img/banners/travel-insurance.jpg";
import Form from "react-bootstrap/Form";
import ErrorMessage from "../../commonModules/ErrorMessage";
import InputFieldText from "../../commonModules/InputFieldText";
import InputFieldDropdown from "../../commonModules/InputFieldDropdown";
import * as Yup from "yup";
import { useFormik } from "formik";
import { yearValidation } from "../../commonModules/CommonCode";
import AdhocService from "../../../services/adhoc.service";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function Travel() {
  const [showContent, setShowContent] = useState(true);
  const history = useHistory(); 
  const optionArray = [
    { value: "5L", label: "5L" },
    { value: "10L", label: "10L" },
    { value: "20L", label: "20L" },
    { value: "50L", label: "50L" },
    { value: "1Cr", label: "1Cr" },
    { value: "2Cr", label: "2Cr" },
  ];
  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    sumInsured: "5L",
    dob: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(" First Name Is Required")
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),

    lastName: Yup.string()
      .required(" Last Name Is Required")
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),

    email: Yup.string()
      .required(" Email Is Required")
      .email("Wrong email format")
      .max(50, "Maximum 50 characters allowed"),

    phoneNumber: Yup.string()
      .required(" Phone Number Is Required")
      .matches(/^[0-9\b]+$/, "Invalid Phone number !")
      .min(10, "Minimum 10 digit required")
      .max(10, "Maximum 10 digit required"),

  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
     
      const requestBody = {
        module: "Travel",
        customerName: values.firstName + values.lastName,
        mobileNumber: values.phoneNumber,
        emailID: values.email
      };
     

      await AdhocService.addLead(requestBody)
        .then((res) => {
          
          setShowContent(false);
          history.push("/travel-Thankyou");
        })
        .catch((error) => {
          
        });
    },
  });

  // ---- --useEffect---------
  useEffect(() => {
    // Add the "car-page" class to the body element when the component mounts
    document.body.classList.add("car-page");
    // Clean up function: Remove the "car-page" class when the component unmounts
    return () => {
      document.body.classList.remove("car-page");
    };
  }, []);
  // ---methods and jsx------

  return (
    <React.Fragment>
      <style>
        {`
          [type="radio"]:checked,
          [type="radio"]:not(:checked)
          {
            opacity: unset;
            display: unset;
          }
        `}
      </style>

      <section class="section-breadcrumb mb-[50px] max-[1199px]:mb-[35px] border-b-[1px] border-solid border-[#eee] bg-[#f8f8fb]">
        <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
          <div class="flex flex-wrap w-full">
            <div class="w-full px-[12px]">
              <div class="flex flex-wrap w-full bb-breadcrumb-inner m-[0] py-[20px] items-center">
                <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                  <h2 class="max-[767px]:justify-start min-[767px]:flex bb-breadcrumb-title font-quicksand tracking-[0.03rem] leading-[1.2] text-[16px] font-bold text-[#3d4750] max-[767px]:text-center max-[767px]:mb-[10px]">
                    Travel Insurance
                  </h2>
                </div>
                <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                  <ul class="bb-breadcrumb-list mx-[-5px] mb-0 flex justify-end min-[767px]:justify-end max-[768px]:justify-center">
                    <li class="bb-breadcrumb-item text-[14px] font-normal px-[5px]">
                      <a
                        href="index.html"
                        class="font-Poppins text-[14px] leading-[28px] tracking-[0.03rem] font-semibold text-[#686e7d] no-underline"
                      >
                        Home
                      </a>
                    </li>
                    <li class="text-[14px] font-normal px-[5px]">
                      <i class="ri-arrow-right-double-fill text-[14px] font-semibold leading-[28px]"></i>
                    </li>
                    <li class="bb-breadcrumb-item font-Poppins text-[#686e7d] text-[14px] leading-[28px] font-normal tracking-[0.03rem] px-[5px] active">
                      Travel Insurance
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Container className="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px] py-[50px] max-[1199px]:py-[35px]">
        <Row>
        <Col sm={6} lg={5} md={6} xs={12}>
            <div className="inner-blog-details-contact max-[992px]:mb-[30px]">
              <h3 class="sub-title flex justify-start font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[30px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                Travel Insurance
              </h3>
              <br />
              <img src={travel} alt="" width={"100%"} />
            </div>
          </Col>

          {showContent &&  <Col sm={6} lg={7} md={6} xs={12}>
            <div class="bb-blog-sidebar mb-[-24px]">
              <div
                class="blog-inner-contact p-[30px] border-[1px] border-solid border-[#eee] mb-[24px] rounded-[20px] max-[575px]:p-[15px]"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <div class="blog-sidebar-title mb-[20px]">
                  <h4 class="font-quicksand  flex justify-start tracking-[0.03rem] leading-[1.2] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[18px]">
                    Find the Best Plan
                  </h4>
                </div>
                <Form
                  as="div"
                  className="form-bg"
                  onSubmit={formik.handleSubmit}
                >
                  <Row>
                    <Col md={6} sm={12} className="mb-3">
                      <InputFieldText
                        formikFieldName="firstName"
                        placeholder="First name*"
                        formik={formik}
                      />
                      <ErrorMessage formik={formik} fieldValue="firstName" />
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                      <InputFieldText
                        formikFieldName="lastName"
                        placeholder="Last name*"
                        formik={formik}
                      />
                      <ErrorMessage formik={formik} fieldValue="lastName" />
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                      <InputFieldText
                        formikFieldName="email"
                        placeholder="Email Id*"
                        formik={formik}
                      />
                      <ErrorMessage formik={formik} fieldValue="email" />
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                      <InputFieldText
                        formikFieldName="phoneNumber"
                        placeholder="Phone Number*"
                        formik={formik}
                        onlyNumber={true}
                      />
                      <ErrorMessage formik={formik} fieldValue="phoneNumber" />
                    </Col>
                   
                  </Row>
                  <Button
                    type="submit"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    className="mt-4 primary-btn"
              
                  >
                    {showContent ? "Submit" : "Submitted"}
                  </Button>
                </Form>
              </div>
            </div>
          </Col>}
          {!showContent && (
           // <Col sm={6} lg={7} md={6} xs={12}>
           <div class="thank-you-container p-[40px] bg-[#f9f9f9] border-[1px] border-solid border-[#ddd] rounded-[20px] text-center shadow-lg max-w-[500px] mx-auto items-center justify-center h-[50%] mt-25%">

           <div class="tick-gif mb-[9px] flex items-center justify-center">
           {/* <i class="ri-verified-badge-fill" style={{color:"green"}}></i> */}
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="96" height="96" fill="rgba(46,188,100,1)"><path d="M10.007 2.10377C8.60544 1.65006 7.08181 2.28116 6.41156 3.59306L5.60578 5.17023C5.51004 5.35763 5.35763 5.51004 5.17023 5.60578L3.59306 6.41156C2.28116 7.08181 1.65006 8.60544 2.10377 10.007L2.64923 11.692C2.71404 11.8922 2.71404 12.1078 2.64923 12.308L2.10377 13.993C1.65006 15.3946 2.28116 16.9182 3.59306 17.5885L5.17023 18.3942C5.35763 18.49 5.51004 18.6424 5.60578 18.8298L6.41156 20.407C7.08181 21.7189 8.60544 22.35 10.007 21.8963L11.692 21.3508C11.8922 21.286 12.1078 21.286 12.308 21.3508L13.993 21.8963C15.3946 22.35 16.9182 21.7189 17.5885 20.407L18.3942 18.8298C18.49 18.6424 18.6424 18.49 18.8298 18.3942L20.407 17.5885C21.7189 16.9182 22.35 15.3946 21.8963 13.993L21.3508 12.308C21.286 12.1078 21.286 11.8922 21.3508 11.692L21.8963 10.007C22.35 8.60544 21.7189 7.08181 20.407 6.41156L18.8298 5.60578C18.6424 5.51004 18.49 5.35763 18.3942 5.17023L17.5885 3.59306C16.9182 2.28116 15.3946 1.65006 13.993 2.10377L12.308 2.64923C12.1078 2.71403 11.8922 2.71404 11.692 2.64923L10.007 2.10377ZM6.75977 11.7573L8.17399 10.343L11.0024 13.1715L16.6593 7.51465L18.0735 8.92886L11.0024 15.9999L6.75977 11.7573Z"></path></svg>
           </div>
           <h2 class="thank-you-heading text-[24px] font-bold text-[#3d4750] mb-[10px]">
             Thank You!
           </h2>
           <p class="thank-you-subtitle text-[16px] text-[#666]">
             We will contact you soon.
           </p>
         </div>
           // </Col>
          )}
        </Row>
      </Container>
    </React.Fragment>
  );

  // return (
  //   <React.Fragment>
  //     <Container>
  //       <img src={UnderConstruction} alt="" width={"100%"} />
  //     </Container>
  //   </React.Fragment>
  // );
}
