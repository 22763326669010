import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContainerForPospView from "../CommonComponents/ContainerForPospView";
import { useHistory } from "react-router-dom";
import { decryptData } from "../../../services/auth-service";
import { useParams } from "react-router-dom/cjs/react-router-dom";

export default function ViewPosp() {
  const email = useParams();
  const history = useHistory();
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <h4 className="mb-5 mt-5">POSP USER</h4>

          <Col className="mb-3">
            <ContainerForPospView email={email} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
