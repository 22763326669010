import React, { useState, useEffect } from 'react';

import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import UseFormContext from "../../context/UseFormContext";
import VerticallyCenteredModal from "../commonModules/Popups/VerticallyCenteredModal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import carInsurance from "../../components/style/assets/img/category/car-insurance.png"
import bikeInsurance from "../../components/style/assets/img/category/bike-insurance.png"
import healthInsurance from "../../components/style/assets/img/category/health-insurance.png"
import termLife from "../../components/style/assets/img/category/term-life.png"
import travelInsurance from "../../components/style/assets/img/category/travel-insurance.png"
import business from "../../components/style/assets/img/category/Business-1.png"
import Carousel from 'react-bootstrap/Carousel';
import slideOne from "../../components/style/assets/img/banners/hp-banner-1.jpeg"
import slideTwo from "../../components/style/assets/img/banners/hp-banner-2.jpg"
import slideThree from "../../components/style/assets/img/banners/hp-banner-3.jpg"
import serviceOne from "../../components/style/assets/img/services/1.jpg"
import serviceTwo from "../../components/style/assets/img/services/2.jpg"
import serviceThree from "../../components/style/assets/img/services/3.jpg"
import serviceFour from "../../components/style/assets/img/services/4.jpg"
import hdfc from "../../components/style/assets/img/category/hdfc.png"
import kotakLife from "../../components/style/assets/img/category/Kotak_Life.png"
import adityaBirlaHealth from "../../components/style/assets/img/category/AdityaBirla_Health.png"
import Digit from "../../components/style/assets/img/category/Digit.png"
import pnbLifeInsurance from "../../components/style/assets/img/category/pnb-life-insurance.png"
import FutureGener from "../../components/style/assets/img/category/FutureGener.png"
import icici from "../../components/style/assets/img/category/icici.png"
import maxLife from "../../components/style/assets/img/category/max-life.png"
import RelianceGeneralInsurance from "../../components/style/assets/img/category/RelianceGeneralInsurance.png"
import aegonlife from "../../components/style/assets/img/category/aegonlife.png"
import iciciLombard from "../../components/style/assets/img/category/icici-lombard.png"
import hdfcErgo from "../../components/style/assets/img/category/hdfc-ergo.png"
import bajajAllianceInsurance from "../../components/style/assets/img/category/Bajaj-Alliance-Insurance.png"
import starHealthInsurance from "../../components/style/assets/img/category/star-health-insurance.png"
import newIndiaAssurance from "../../components/style/assets/img/category/new-india-assurance.png"
import zurichKotak from "../../components/style/assets/img/category/zurich-kotak.png"
import testimonialOne from "../../components/style/assets/img/testimonials/Shubham-Mahadik.jpg"
import testimonialTwo from "../../components/style/assets/img/testimonials/Khalid-Sayyed.jpg"
import testimonialThree from "../../components/style/assets/img/testimonials/Jigisha-Jaiswar.jpg"


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function SignIn() {
  const formContext = UseFormContext();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: <div className="custom-prev-arrow text-dark">←</div>,     
    nextArrow: <div className="custom-next-arrow text-dark">→</div>,
 
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  // Update scroll position on scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollPercent = (scrolled / windowHeight) * 100;

      setScrollPosition(scrollPercent);

      // Toggle visibility of the scroll-to-top button
      if (scrolled > 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <React.Fragment>
      <div className="">


        <section class="section-banner-one overflow-hidden py-[50px] max-[1199px]:py-[35px]" style={{ width: '100%' }}>
          <div class="flex flex-col lg:flex-row lg:flex-nowrap justify-between items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px]">

            <div class="w-full lg:w-[50%] px-[12px] lg:order-2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">

              <Carousel className='mainSlider'>
                <Carousel.Item>
                  <img src={slideOne} class="d-block w-100" alt="Image 1" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={slideTwo} class="d-block w-100" alt="Image 1" />

                </Carousel.Item>
                <Carousel.Item>
                  <img src={slideThree} class="d-block w-100" alt="Image 1" />
                </Carousel.Item>
              </Carousel>


            </div>

            <div class="grid grid-cols-2 gap-[20px] lg:grid-cols-3 w-full lg:w-[50%] lg:order-1 mb-[20px] lg:mb-0 p-[15px]">
              <div class="bb-category-box p-[20px] bg-[#fef1f1] rounded-[20px] text-center">
                <a href="/car" class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize"><img src={carInsurance} alt="Car Insurance" class="w-[50px] h-[50px] mx-auto" /></a>
                <h5> <a href="/car" class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize">Car <br />Insurance</a></h5>
              </div>


              <div class="bb-category-box p-[20px] bg-[#e1fcf2] rounded-[20px] text-center">
                <a href="/bike" class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize"> <img src={bikeInsurance} alt="Bike Insurance" class="w-[50px] h-[50px] mx-auto" /></a>
                <h5> <a href="/bike" class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize">Bike<br /> Insurance</a></h5>
              </div>


              <div class="bb-category-box p-[20px] bg-[#f4f1fe] rounded-[20px] text-center">
                <a href="/health" class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize"> <img src={healthInsurance} alt="Health Insurance" class="w-[50px] h-[50px] mx-auto" /></a>
                <h5> <a href="/health" class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize">Health<br /> Insurance</a></h5>
              </div>


              <div class="bb-category-box p-[20px] bg-[#fbf9e4] rounded-[20px] text-center">
                <a href="/termlife" class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize"><img src={termLife} alt="Term Life Insurance" class="w-[50px] h-[50px] mx-auto" /></a>
                <h5> <a href="/termlife" class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize">Term Life<br /> Insurance</a></h5>
              </div>


              <div class="bb-category-box p-[20px] bg-[#eefce1] rounded-[20px] text-center">
                <a href="/travel" class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize"><img src={travelInsurance} alt="Travel Insurance" class="w-[50px] h-[50px] mx-auto" /></a>
                <h5> <a href="/travel" class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize">Travel<br /> Insurance</a></h5>
              </div>


              <div class="bb-category-box p-[20px] bg-[#e1f4fc] rounded-[20px] text-center">
                <a href="/business" class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize"><img src={business} alt="Business Insurance" class="w-[50px] h-[50px] mx-auto" /></a>
                <h5> <a href="/business" class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize">Business<br /> Insurance</a></h5>
              </div>


            </div>

          </div>
        </section>

        <section class="section-services overflow-hidden py-[50px] max-[1199px]:py-[35px]">
          <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap w-full mb-[-24px]">
              <div class="min-[992px]:w-[25%] min-[768px]:w-[50%] w-full px-[12px] mb-[24px]" data-aos="flip-up" data-aos-duration="1000" data-aos-delay="200">
                <div class="bb-services-box p-[30px] border-[1px] border-solid border-[#eee] rounded-[20px] text-center">
                  <div class="services-img mb-[20px] flex justify-center">
                    <img src={serviceOne} alt="services-1" class="w-[50px]" />
                  </div>
                  <div class="services-contact">
                    <h4 class="font-quicksand mb-[8px] text-[18px] font-bold text-[#3d4750] leading-[1.2] tracking-[0.03rem]">Over 20K </h4>
                    <p class="font-Poppins font-light text-[14px] leading-[20px] text-[#686e7d] tracking-[0.03rem]">customers trust us & have bought their insurance on Policy365</p>
                  </div>
                </div>
              </div>
              <div class="min-[992px]:w-[25%] min-[768px]:w-[50%] w-full px-[12px] mb-[24px]" data-aos="flip-up" data-aos-duration="1000" data-aos-delay="400">
                <div class="bb-services-box p-[30px] border-[1px] border-solid border-[#eee] rounded-[20px] text-center">
                  <div class="services-img mb-[20px] flex justify-center">
                    <img src={serviceTwo} alt="services-2" class="w-[50px]" />
                  </div>
                  <div class="services-contact">
                    <h4 class="font-quicksand mb-[8px] text-[18px] font-bold text-[#3d4750] leading-[1.2] tracking-[0.03rem]">25+ Insurers</h4>
                    <p class="font-Poppins font-light text-[14px] leading-[20px] text-[#686e7d] tracking-[0.03rem]">partnered with us so that you can compare easily & transparently</p>
                  </div>
                </div>
              </div>
              <div class="min-[992px]:w-[25%] min-[768px]:w-[50%] w-full px-[12px] mb-[24px]" data-aos="flip-up" data-aos-duration="1000" data-aos-delay="600">
                <div class="bb-services-box p-[30px] border-[1px] border-solid border-[#eee] rounded-[20px] text-center">
                  <div class="services-img mb-[20px] flex justify-center">
                    <img src={serviceThree} alt="services-3" class="w-[50px]" />
                  </div>
                  <div class="services-contact">
                    <h4 class="font-quicksand mb-[8px] text-[18px] font-bold text-[#3d4750] leading-[1.2] tracking-[0.03rem]">Great Price</h4>
                    <p class="font-Poppins font-light text-[14px] leading-[20px] text-[#686e7d] tracking-[0.03rem]">for all kinds of insurance plans available online</p><br />
                  </div>
                </div>
              </div>
              <div class="min-[992px]:w-[25%] min-[768px]:w-[50%] w-full px-[12px] mb-[24px]" data-aos="flip-up" data-aos-duration="1000" data-aos-delay="800">
                <div class="bb-services-box p-[30px] border-[1px] border-solid border-[#eee] rounded-[20px] text-center">
                  <div class="services-img mb-[20px] flex justify-center">
                    <img src={serviceFour} alt="services-4" class="w-[50px]" />
                  </div>
                  <div class="services-contact">
                    <h4 class="font-quicksand mb-[8px] text-[18px] font-bold text-[#3d4750] leading-[1.2] tracking-[0.03rem]">Claims</h4>
                    <p class="font-Poppins font-light text-[14px] leading-[20px] text-[#686e7d] tracking-[0.03rem]">support built in with every policy for help, when you need it the most</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="carousel overflow-hidden relative w-full h-[100px]">

          <div class="section-title text-center mb-[20px] pb-[20px] z-[5] relative flex justify-center max-[991px]:pb-[0]" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
            <div class="section-detail max-[991px]:mb-[12px]">
              <h2 class="bb-title font-quicksand mb-[0] p-[0] text-[30px] font-bold text-[#3d4750] relative inline capitalize leading-[1] tracking-[0.03rem] max-[767px]:text-[23px]">Our <span class="text-[#6c7fd8]">Partners</span></h2>

            </div>
          </div>

          <div class="carousel-track flex animate-slide">

            <div class="slide">
              <img src={hdfc} alt="HDFC" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={kotakLife} alt="Kotak Life" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={adityaBirlaHealth} alt="Aditya Birla Health" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={Digit} alt="Digit" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={pnbLifeInsurance} alt="pnb-life-insurance" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={FutureGener} alt="FutureGener" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={icici} alt="icici" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={maxLife} alt="max-life" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={RelianceGeneralInsurance} alt="RelianceGeneralInsurance" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={aegonlife} alt="aegonlife" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={iciciLombard} alt="icici lombard" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={hdfcErgo} alt="hdfc ergo" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={bajajAllianceInsurance} alt="Bajaj Alliance Insurance" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={starHealthInsurance} alt="star health Insurance" class="h-[100px] object-contain" />
            </div>
            {/* <div class="slide">
              <img src="assets/img/category/Care-Health.png" alt="Care Health Insurance" class="h-[100px] object-contain" />
            </div> */}
            <div class="slide">
              <img src={newIndiaAssurance} alt="new india assurance" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={zurichKotak} alt="zurich kotak" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={hdfc} alt="HDFC" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={kotakLife} alt="Kotak Life" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={adityaBirlaHealth} alt="Aditya Birla Health" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={Digit} alt="Digit" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={pnbLifeInsurance} alt="pnb-life-insurance" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={FutureGener} alt="FutureGener" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={icici} alt="icici" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={maxLife} alt="max-life" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={RelianceGeneralInsurance} alt="RelianceGeneralInsurance" class="h-[100px] object-contain" />
            </div>

            <div class="slide">
              <img src={aegonlife} alt="aegonlife" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={iciciLombard} alt="icici lombard" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={hdfcErgo} alt="hdfc ergo" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={bajajAllianceInsurance} alt="Bajaj Alliance Insurance" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={starHealthInsurance} alt="star health Insurance" class="h-[100px] object-contain" />
            </div>
            {/* <div class="slide">
              <img src={careHealth} alt="Care Health Insurance" class="h-[100px] object-contain" />
            </div> */}
            <div class="slide">
              <img src={newIndiaAssurance} alt="new india assurance" class="h-[100px] object-contain" />
            </div>
            <div class="slide">
              <img src={zurichKotak} alt="zurich kotak" class="h-[100px] object-contain" />
            </div>

          </div>
        </section>

        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <section class="section-testimonials overflow-hidden py-[100px] max-[1199px]:py-[70px] max-[991px]:p-[0]">


                  <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">

                    <div class="flex flex-wrap w-full">

                      <div class="w-full px-[12px]">
                        <div class="bb-testimonials relative" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">

                          <div class="section-title text-center mb-[20px] pb-[20px] z-[5] relative flex justify-center max-[991px]:pb-[0]" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                            <div class="section-detail max-[991px]:mb-[12px]">
                              <h2 class="bb-title font-quicksand mb-[0] p-[0] text-[30px] font-bold text-[#3d4750] relative inline capitalize leading-[1] tracking-[0.03rem] max-[767px]:text-[23px]">Our <span class="text-[#6c7fd8]">Testimonials</span></h2>

                            </div>
                          </div>


                          <Slider {...settings} className="testimonials-slider">
                            <div>
                              <div class="bb-testimonials-inner max-w-[900px] m-[auto] max-[1399px]:max-w-[800px]">
                                <div class="flex flex-wrap mx-[-12px] testimonials-row">
                                  <div class="min-[768px]:w-[33.33%] w-full px-[12px] max-[767px]:hidden">
                                    <div class="testimonials-image relative max-[575px]:mb-[20px] max-[575px]:max-w-[200px]">
                                      <img src={testimonialOne} alt="testimonials" class="w-full rounded-[30px] block" />
                                    </div>
                                  </div>
                                  <div class="min-[768px]:w-[66.66%] w-full px-[12px]">
                                    <div class="testimonials-contact h-full flex flex-col justify-end">
                                      <div class="user max-[767px]:flex max-[767px]:items-center">
                                        <img src={testimonialOne} alt="testimonials" class="w-full  rounded-[15px] max-[767px]:max-w-[60px] max-[767px]:mr-[15px] max-[767px]:flex" />
                                        <div class="detail">
                                          <h4 class="text-left font-quicksand text-[#3d4750] tracking-[0.03rem] leading-[1.2] mb-[8px] text-[20px] font-bold max-[767px]:mb-[4px] max-[767px]:text-[18px]">Shubham Mahadik </h4>
                                        </div>
                                      </div>
                                      <div class="inner-contact bg-[#fff] mt-[10px] border-[1px] border-solid border-[#eee] p-[20px] rounded-[30px]">
                                        <p class="text-left font-Poppins text-[#686e7d] text-[14px] leading-[25px] tracking-[0.03rem] font-light">My experience with Navnit insurance is that my RM Santosh poojari is very co-operative, quick service, easy to communicate and very professional. So no problem with service from navnit or my RM. Its great to do business with you.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="bb-testimonials-inner max-w-[900px] m-[auto] max-[1399px]:max-w-[800px]">
                              <div class="flex flex-wrap mx-[-12px] testimonials-row">
                                <div class="min-[768px]:w-[33.33%] w-full px-[12px] max-[767px]:hidden">
                                  <div class="testimonials-image relative max-[575px]:mb-[20px] max-[575px]:max-w-[200px]">
                                    <img src={testimonialTwo} alt="testimonials" class="w-full rounded-[30px] block" />
                                  </div>
                                </div>
                                <div class="min-[768px]:w-[66.66%] w-full px-[12px]">
                                  <div class="testimonials-contact h-full flex flex-col justify-end">
                                    <div class="user max-[767px]:flex max-[767px]:items-center">
                                      <img src={testimonialTwo} alt="testimonials" class="w-full  rounded-[15px] max-[767px]:max-w-[60px] max-[767px]:mr-[15px] max-[767px]:flex" />
                                      <div class="detail">
                                        <h4 class="text-left font-quicksand text-[#3d4750] tracking-[0.03rem] leading-[1.2] mb-[8px] text-[20px] font-bold max-[767px]:mb-[4px] max-[767px]:text-[18px]">Khalid Sayyed </h4>
                                      </div>
                                    </div>
                                    <div class="inner-contact bg-[#fff] mt-[10px] border-[1px] border-solid border-[#eee] p-[20px] rounded-[30px]">
                                      <p class="text-left font-Poppins text-[#686e7d] text-[14px] leading-[25px] tracking-[0.03rem] font-light">Wonderful services with lot of benefits of discount vouchers, friendly employees great experience thank a lot </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="bb-testimonials-inner max-w-[900px] m-[auto] max-[1399px]:max-w-[800px]">
                              <div class="flex flex-wrap mx-[-12px] testimonials-row">
                                <div class="min-[768px]:w-[33.33%] w-full px-[12px] max-[767px]:hidden">
                                  <div class="testimonials-image relative max-[575px]:mb-[20px] max-[575px]:max-w-[200px]">
                                    <img src={testimonialThree} alt="testimonials" class="w-full rounded-[30px] block" />
                                  </div>
                                </div>
                                <div class="min-[768px]:w-[66.66%] w-full px-[12px]">
                                  <div class="testimonials-contact h-full flex flex-col justify-end">
                                    <div class="user max-[767px]:flex max-[767px]:items-center">
                                      <img src={testimonialThree} alt="testimonials" class="w-full rounded-[15px] max-[767px]:max-w-[60px] max-[767px]:mr-[15px] max-[767px]:flex" />
                                      <div class="detail">
                                        <h4 class="text-left font-quicksand text-[#3d4750] tracking-[0.03rem] leading-[1.2] mb-[8px] text-[20px] font-bold max-[767px]:mb-[4px] max-[767px]:text-[18px]">Jigisha Jaiswar </h4>
                                      </div>
                                    </div>
                                    <div class="inner-contact bg-[#fff] mt-[10px] border-[1px] border-solid border-[#eee] p-[20px] rounded-[30px]">
                                      <p class="text-left font-Poppins text-[#686e7d] text-[14px] leading-[25px] tracking-[0.03rem] font-light">I have no any issues about Navnit insurance,  Quotation will received on time and the most important think that your response about claim I'm very thankful to claim team & sales team.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                          </Slider>
                        </div>
                        <br /><br />



                        <div class="blog-btn flex justify-center">

                          <a href="testimonial.html" class="bb-btn-2 transition-all duration-[0.3s] ease-in-out font-Poppins leading-[28px] tracking-[0.03rem] py-[2px] px-[14px] text-[14px] font-normal text-[#fff] bg-[#6c7fd8] rounded-[10px] border-[1px] border-solid border-[#6c7fd8] hover:bg-transparent hover:border-[#3d4750] hover:text-[#3d4750] align=center" >View More Testimonial</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

            </Col>
          </Row>
        </Container>

        <div className="scroll-to-top">
          {scrollPosition > 0 && (
            <div
              className="progress-border"
              style={{
                background: `conic-gradient(#007bff ${scrollPosition}%, #ccc ${scrollPosition}%)`,
              }}
            >
              <Button
                variant="link"
                onClick={scrollToTop}
                className="scroll-button"
              >
                ↑
              </Button>
            </div>
          )}
        </div>

        {/* <Container>
          <div className="inner-sign-in">
            <div>
              <h2 className="heading">Personalized Insurance Jo Aapke Liye Sahi!!</h2>
              <div className="form-floating mb-4">
                <select id="idunique" className="form-select">
                  <option value="petrol">Healthcare and Doctor</option>
                  <option value="disel">IT, ITES & Tech Startup</option>
                  <option value="lphcng">CA & Financial Services</option>
                  <option value="lphcng">Retail and E Commerce</option>
                </select>
                <label htmlFor="idunique select-label">Select your occupation</label>
              </div>
            </div>
            <Button className="primary-btn w-auto">Get Insurance Quotes</Button>
          </div>
        </Container> */}
      </div>
      {/* popup for timeout */}
      <VerticallyCenteredModal
        show={formContext.errorPopUp}
        onHide={() => formContext.seterrorPopUp(false)}
        heading="Your session has expired. Please login to pick up where you left off."
      >
        {formContext.popupForTimeout}
      </VerticallyCenteredModal>
    </React.Fragment>
  );
}
