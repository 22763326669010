import React from "react";
import {
  Button,
  Col,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import GrievancePolicy from "../../components/style/assets/img/insurance/grievance-redressal-policy.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function GrievanceRedressalPolicy() {
  return (
    <React.Fragment>
      <>
        <section class="section-breadcrumb mb-[50px] max-[1199px]:mb-[35px] border-b-[1px] border-solid border-[#eee] bg-[#f8f8fb]">
          <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap w-full">
              <div class="w-full px-[12px]">
                <div class="flex flex-wrap w-full bb-breadcrumb-inner m-[0] py-[20px] items-center">
                  <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                    <h2 class="max-[767px]:justify-start min-[767px]:flex bb-breadcrumb-title font-quicksand tracking-[0.03rem] leading-[1.2] text-[16px] font-bold text-[#3d4750] max-[767px]:text-center max-[767px]:mb-[10px]">
                      Grievance Redressal Policy
                    </h2>
                  </div>
                  <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                    <ul class="bb-breadcrumb-list mx-[-5px] mb-0 flex justify-end min-[767px]:justify-end max-[768px]:justify-center">
                      <li class="bb-breadcrumb-item text-[14px] font-normal px-[5px]">
                        <a
                          href="index.html"
                          class="font-Poppins text-[14px] leading-[28px] tracking-[0.03rem] font-semibold text-[#686e7d] no-underline"
                        >
                          Home
                        </a>
                      </li>
                      <li class="text-[14px] font-normal px-[5px]">
                        <i class="ri-arrow-right-double-fill text-[14px] font-semibold leading-[28px]"></i>
                      </li>
                      <li class="bb-breadcrumb-item font-Poppins text-[#686e7d] text-[14px] leading-[28px] font-normal tracking-[0.03rem] px-[5px] active">
                        Grievance Redressal Policy
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-blog-details py-[50px] max-[1199px]:py-[35px]">
          <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap mb-[-24px]">
              <div class="min-[1200px]:w-[100%] min-[992px]:w-[58.33%] w-full px-[12px] mb-[24px]">
                <div
                  class="bb-blog-details-contact"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >
                  <div class="inner-blog-details-image mb-[24px] flex">
                    <div className="w-[45%]">
                      <img
                        src={GrievancePolicy}
                        alt="details-one"
                        class="w-full rounded-[15px]"
                      />
                    </div>
                    <div class="min-[1200px]:w-[55%] min-[992px]:w-[41.66666667%] w-full px-[12px] mb-[24px]">
                      <div class="bb-blog-sidebar mb-[-24px]">
                        <div
                          class="blog-inner-contact p-[30px] border-[1px] border-solid border-[#eee] mb-[24px] rounded-[20px] max-[575px]:p-[15px]"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-delay="200"
                        >
                          <div class="blog-sidebar-title mb-[20px]">
                            <h4 class="font-quicksand tracking-[0.03rem] leading-[1.2] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[18px] text-left">
                              Get In Touch
                            </h4>
                            <Form
                className="form-bg p-3"
                name="contactForm"
                //onSubmit={submitContactsForm()}
              >
                <Row>
                  <Col md={12} sm={12}>
                    <FloatingLabel
                      controlId="name"
                      label="Name*"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Name*"
                        className="floating-input"
                      />
                    </FloatingLabel>
                  </Col>

                  <Col md={12} sm={12}>
                    <FloatingLabel
                      controlId="mobile"
                      label="Mobile*"
                      className="mb-3"
                    >
                      <Form.Control
                        type="number"
                        placeholder="Mobile*"
                        className="floating-input"
                      />
                    </FloatingLabel>
                  </Col>

                  <Col md={12} sm={12}>
                    <FloatingLabel
                      controlId="email"
                      label="Email*"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Email*"
                        className="floating-input"
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} lg={12}>
                    <Row>
                      <Col sm={12} lg={12}>
                        <InputGroup className="mb-3 mt-3 w-100">
                          <InputGroup.Text>Service Type</InputGroup.Text>
                          <Dropdown>
                            <Dropdown.Toggle variant="success">
                              Complaint
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#" value="query">
                                Query
                              </Dropdown.Item>
                              <Dropdown.Item href="#">Complaint</Dropdown.Item>
                              <Dropdown.Item href="#">Request</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </InputGroup>
                      </Col>

                      {/* <Col sm={12} lg={12}>
                        <InputGroup className="mb-3 w-100">
                          <InputGroup.Text id="">Service On</InputGroup.Text>
                          <Dropdown>
                            <Dropdown.Toggle variant="success">
                              Buy Car Insurance
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#" value="query">
                                Buy Car Insurance
                              </Dropdown.Item>
                              <Dropdown.Item href="#">
                                Buy Two-Wheeler Insurance
                              </Dropdown.Item>
                              <Dropdown.Item href="#">
                                Buy Life Insurance
                              </Dropdown.Item>
                              <Dropdown.Item href="#">
                                Buy Health Insurance
                              </Dropdown.Item>
                              <Dropdown.Item href="#">Others</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </InputGroup>
                      </Col> */}
                    </Row>
                  </Col>
                  <Col sm={12} lg={12}>
                    <Form.Control
                      className="form form-control h-6rem"
                      as="textarea"
                      placeholder="Feedback / Complaint / Query"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} lg={12}>
                    <Button className="w-25 mt-4 primary-btn btn btn-primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="inner-blog-details-contact mb-[30px] text-left">
                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[30px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Grievance Redressal Policy
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      A: The organization shall strive towards a customer
                      service, which does not lead customer to feel aggrieved.
                      NIBPL shall conduct its business in an open transparent
                      and customer centric manner wherein the customer receives
                      options, best advice and exercise its discretion to choose
                      both the provider of the insurance and coverages. The sale
                      & service of insurance shall lead to a satisfactory and
                      trustworthy long term relationship.
                    </p>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      B: If due to any unforeseeable reason, the customer feels
                      aggrieved, NIBPL and its employee must accept that as a
                      customer’s basic right on the matter and shall address his
                      grievance soonest, with all seriousness and this time
                      around must strive towards gaining confidence &
                      satisfaction.
                    </p>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      C: The Organization shall take adequate steps for
                      redressal of grievances of its clients within 14 days of
                      receipt of such complaint and keep the Authority informed
                      about the number, nature and other particulars of the
                      complaints received from such clients in format and manner
                      as may be specified by the Authority.
                    </p>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      D: A grievance shall not be handled by a person who has
                      provided the service at the first instance, it shall be
                      handled at a step up or his line manager – 1st level of
                      escalation. The concerned employee can participate in
                      addressing the issue along with line manager.
                    </p>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      E: The grievance shall be acknowledged on the same working
                      day or maximum the next working day.
                    </p>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      F: The acknowledgment shall mention the time period within
                      which the organization will revert to the customer with
                      possible solutions or facts. This shall not be ideally
                      longer than 7 working days.
                    </p>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      G: If matter gets resolved at the level ‘1’, it stands
                      closed. Due to any reason the matter does not get closed
                      in 7 working days, it shall be escalated to level 2. Even
                      if it was revealed that the grievance is not genuine and
                      it cannot be resolved, it shall not be closed without
                      escalation to level 2. It must be escalated to level 2,
                      who will strive to address the issue and satisfy the
                      customer and shall be the final authority to take decision
                      on the matter in next 7 working days.
                      <br />
                      Level ‘1’ escalation – Head of Business
                      <br />
                      Level ‘2’ escalation – PO/CEO
                      <br />
                      The PO/CEO is the Grievance Manager, who will keep an eye
                      on all reported Grievances and its satisfactory
                      resolutions and be accountable for it.
                    </p>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      H: If the customer has reported a matter to
                      IRDAI/Ombudsman/Consumer Court/Civil Court and NIBPL has
                      been intimated of the same by the respected authority,
                      this shall be attended to at utmost priority. There shall
                      be an immediate acknowledgement and it shall be handled at
                      level 1 of escalation all the time and the person at the
                      escalation level 2 is overall accountable for its
                      resolution. The Board must be informed of this immediately
                      and shall be kept in loop throughout the period of
                      resolution.
                    </p>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      I: If the matter is not getting resolved at the escalation
                      level 2, the board must be apprised of the case which will
                      have a final say on the matter.
                    </p>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      J: The Board must also be informed if there is any penalty
                      imposed, adverse court decision, ombudsman award etc. The
                      Board apart from complying with the directive of IRDAI /
                      Ombudsman/ Consumer court/ Civil court may also initiate
                      action against any concerned employees and bring process /
                      procedural changes so as to avoid recurrence of the case.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
