import React, { createContext, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Disclaimer from "../style/assets/img/insurance/disclaimer.jpg";

export default function AccessDenied() {
  const history = useHistory();
  const user =localStorage.getItem("user")
  //check login
  // useEffect(() => {
  //   if (!user) {
      
  //     history.push("/login");
  //   } 
  // }, [history]);
  return (  <><h1 style={{ fontSize: "3rem" }}>Access Denied</h1>
    <img src={Disclaimer} style={{ width: "50%", height: "auto" ,margin: "0 auto"}} className="justice-centent-center" /></>);
}
