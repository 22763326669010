import "./App.css";
import { useState, useEffect } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { routesArray } from "./routing/RoutingPaths";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/commonModules/Header";
import { FormProvider } from "../src/context/FormContext";
import "../src/style.scss";
import AutoLogout from "./components/AutoLogOutFolder/AutoLogOutFile";
import { Notification } from "./components/Notification/Notification";
import { Footer } from "./components/pages/Footer";
import UserContext from "./components/Context/UserContext";
import { MyProfile } from "./components/pages/MyProfile";
import { MyProfileAdmin } from "./components/pages/MyprofileAdmin";
import { useLocation } from "react-router";
import { RoutingMap } from "../src/routing/Routingmap";
import "../src/components/style/assets/js/vendor/tailwindcss3.4.5.js";
import usePageTracking from "./components/commonModules/usePageTracking.js";
import ReactGA from "react-ga";
const TRACKING_ID = "G-2JD6WYDX0P"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  // usePageTracking();

  const [userRole, setUserRole] = useState("");

  const setUser = (role) => {
    //userRole=role;
    setUserRole(role);
  };

  useEffect(() => {
    // Inject Google Tag script
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-2JD6WYDX0P";
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-2JD6WYDX0P', { 'page_path': window.location.pathname + window.location.hash });
      `;
    document.head.appendChild(script2);
    // Track hash changes
    const trackPageView = () => {
      if (window.gtag) {
        window.gtag("config", "G-2JD6WYDX0P", {
          page_path: window.location.pathname + window.location.hash,
        });
      }
    };

    // Initial page view
    trackPageView();

    // Add hash change listener
    window.addEventListener("hashchange", trackPageView);
    // Inject Meta Pixel Code
    const fbScript = document.createElement("script");
    fbScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '440117342470274');
        fbq('track', 'PageView');
      `;
    document.head.appendChild(fbScript);

    const fbNoscript = document.createElement("noscript");
    fbNoscript.innerHTML = `
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=440117342470274&ev=PageView&noscript=1" />
      `;
    document.body.appendChild(fbNoscript);

    // Inject Google Tag Manager Script
    const gtmScript = document.createElement("script");
    gtmScript.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-N9PNKD4S');
      `;
    document.head.appendChild(gtmScript);

    const gtmNoscript = document.createElement("noscript");
    gtmNoscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N9PNKD4S"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
    document.body.insertBefore(gtmNoscript, document.body.firstChild);
    return () => {
      // Cleanup scripts
      document.head.removeChild(script1);
      document.head.removeChild(script2);
      document.head.removeChild(fbScript);
      document.body.removeChild(fbNoscript);
      document.head.removeChild(gtmScript);
      document.body.removeChild(gtmNoscript);
      window.removeEventListener("hashchange", trackPageView);
    };
  }, []);

  //usePageTracking();
  return (
    <FormProvider>
      <div className="App">
        <BrowserRouter>
          {/* {usePageTracking()} */}
          <AutoLogout />
          <Header />
          <div className="height-container">
            <Switch>
              <UserContext.Provider value={{ userRole, setUser }}>
                <RoutingMap></RoutingMap>
              </UserContext.Provider>
            </Switch>
          </div>
          <Footer />
        </BrowserRouter>
      </div>
      <Notification />
    </FormProvider>
  );
}

export default App;
