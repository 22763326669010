import React, { useEffect,useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContainerForBranch from "../CommonComponents/ContainerForBranch"
import { useHistory } from 'react-router-dom';
import { decryptData } from "../../../services/auth-service";
export default function AddBranch() {
 
    const history = useHistory();
    const [user,setUser]=useState(null)
  
    useEffect(() => {
      let user1 = decryptUser();
      if (!user1) {
        history.push("/login");
      }
    }, [history]);
  
    const decryptUser = async () => {
      const encryptedUser = localStorage.getItem("user");
      if (encryptedUser) {
        try {
          const user1 = await decryptData(encryptedUser);
          setUser(user1)
          if(user1.role!=="ADMIN" && user1.role!=="MANAGER")
            history.push("/accessdenied");
          return user1;
        } catch (error) {
          console.error("Error decrypting user:", error);
        }
      }
    };
    
    return (
        <React.Fragment>
            <Container>
                <Row>
                    <h4 className="mb-5 mt-5">Add Branch</h4>
                    <Col className="mb-3">
                        <ContainerForBranch />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}
