import { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import AdminService from "../../../services/admin.service";
import Select from "../../commonModules/InputFieldMultipleSelect";
import { decryptData } from "../../../services/auth-service";
import Validations from "../../../validations/validations";

const options = [
  { value: "HEALTH", label: "HEALTH Insurance" },
  { value: "MOTOR", label: "MOTOR Insurance" },
  { value: "LIFE", label: "LIFE Insurance" },
  // Add more options as needed
];

const Popup = ({ onClose, show }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Agency Updated Successfully !!</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button type="button" className="primary-btn" onClick={onClose}>
          Okay!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function ContainerForBranchView(props) {
  const [disabledType, setDisabledType] = useState(false);
  const [defValues, setDefValues] = useState([]);
  const [defType, setDefType] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();
  const [user,setUser]=useState(null)

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1)
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };
  
  const agencyTypeValues = [
    { label: "Single Branch", value: "SINGLEBRANCH" },
    { label: "Multiple Branches", value: "MULTIPLEBRANCH" },
  ];

  const lobValues = [
    { value: "HEALTH", label: "HEALTH Insurance" },
    { value: "MOTOR", label: "MOTOR Insurance" },
    { value: "LIFE", label: "LIFE Insurance" },
  ];

  const validationSchema = Validations.AgencyViewValidation;

  //set initial
  const [initialData, setInitialData] = useState({
    id: "",
    agencyName: "",
    agencyType: "",
    lineOfBusiness: "",
    createdBy: user?.email ? user?.email : "",
    status: "",
  });

  const { id } = useParams();
  useEffect(() => {
    fetchData();

    document.body.classList.add("car-page");
    return () => {
      document.body.classList.remove("car-page");
    };
  }, []);

  const fetchData = async () => {
    try {
      const data = {
        id: id,
      };
      await AdminService.getAgencyById(data).then(async (res) => {
        let arr = [];
        fetchBranches(res?.data?.data?.agencyType);
        await res.data.data.lineOfBusiness
          .slice(1, -1)
          .split(",")
          .map((lob) => {
            arr.push(
              lobValues.filter((item) => {
                return lob.includes(item.value);
              })[0]
            );
          });

        setDefValues(arr);
        setDefType(
          agencyTypeValues.filter((item) =>
            res.data.data.agencyType
              .slice(1, -1)
              .split(",")
              .includes(item.value)
          )[0]
        );
        setInitialData(res.data.data);
        await formik.setValues(res.data.data);
        setValues(res.data.data);
        // Assuming API response contains 'totalPages' key
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchBranches = async (type) => {
    try {
      const data = {
        agencyId: id,
      };
      await AdminService.getAllBranchByAgencyId(data).then((response) => {
        let length = response.data.data.length;
        if (type === "[MULTIPLEBRANCH]" && length >= 2) {
          setDisabledType(true);
        } else {
          setDisabledType(false);
        }
      });
    } catch (error) {
     
    }
  };

  const setValues = (data) => {
    formik.setFieldValue("agencyType", data.agencyType.slice(1, -1).split(","));
    formik.setFieldValue(
      "lineOfBusiness",
      data.lineOfBusiness.slice(1, -1).split(",")
    );
  };

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const addAgencyRequest = {
        id: values.id,
        agencyName: values.agencyName,
        agencyType: values.agencyType,
        lineOfBusiness: values.lineOfBusiness,
        createdBy: user?.email ? user?.email : "",
        status: values.status,
      };
      try {
        await AdminService.addAgency(addAgencyRequest).then((data)=>{
          setShowPopup(true);
        })
       
      } catch (error) {
        // console.error("Error:", error.response.data.data);
        // let errorObj = error.response.data.data;
        // alert(Object.values(errorObj));
      }
    },
  });

  const handleAgencyTypeChange = (value) => {
    formik.setFieldValue("agencyType", [value.value]);
  };

  const handleLobChange = (value) => {
    formik.setFieldValue(
      "lineOfBusiness",
      value.map((obj) => obj.value)
    );
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setIsEditing(false);
    window.location.href = "/ListAgency";
  };

  const handleDeactivate = () => {
    const confirmation = window.confirm("Are you sure ?");
    if (confirmation) {
      let status = formik.values.status === "ACTIVE" ? "DEACTIVE" : "ACTIVE";
      formik.setFieldValue("status", status);
      formik.handleSubmit();
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };

  //onSubmit={formik.handleSubmit}
  return (
    <>
      {initialData && initialData !== undefined && initialData !== null ? (
        <>
          <Form className="form-bg" onSubmit={formik.handleSubmit}>
            <Row>
              <Col sm={12} md={6} className="pt-2">
                <Form.Group controlId="agencyName" className="floating-input">
                  <FloatingLabel className="floating-input">
                    Name of Agency
                  </FloatingLabel>
                  {isEditing ? (
                    <Form.Control
                      name="agencyName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.agencyName}
                      className="floating-input"
                    />
                  ) : (
                    <Form.Control
                      name="agencyName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.agencyName}
                      className="floating-input"
                      disabled
                    />
                  )}

                  <Form.Text className="text-danger">
                    {formik.touched.agencyName && formik.errors.agencyName ? (
                      <div className="text-danger">
                        {formik.errors.agencyName}
                      </div>
                    ) : null}
                  </Form.Text>
                </Form.Group>
              </Col>

              <Col sm={12} md={6} className="pt-2">
                <Form.Group controlId="agencyType" className="floating-input">
                  <FloatingLabel>Agency Type</FloatingLabel>

                  {isEditing ? (
                    disabledType ? (
                      <Form.Control
                        name="agencyType"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.agencyType}
                        className="floating-input"
                        disabled
                      />
                    ) : (
                      <Select
                        defaultValue={defType}
                        options={agencyTypeValues}
                        onChange={handleAgencyTypeChange}
                        isSearchable={false}
                        isMulti={false}
                        value={formik.values.agencyType}
                      />
                    )
                  ) : (
                    <Form.Control
                      name="agencyType"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.agencyType}
                      className="floating-input"
                      disabled
                    />
                  )}

                  <Form.Text className="text-danger">
                    {formik.touched.agencyType && formik.errors.agencyType ? (
                      <div className="text-danger">
                        {formik.errors.agencyType}
                      </div>
                    ) : null}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col sm={12} md={6} className="pt-2">
                <Form.Group
                  controlId="lineOfBusiness"
                  className="floating-input"
                >
                  <FloatingLabel className="floating-input">
                    Line of Business
                  </FloatingLabel>
                  {isEditing ? (
                    <Select
                      defaultValue={defValues}
                      options={lobValues}
                      onChange={handleLobChange}
                      isSearchable={false}
                      isMulti={true}
                      value={formik.values.lineOfBusiness}
                    />
                  ) : (
                    <Form.Control
                      name="lineOfBusiness"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lineOfBusiness}
                      className="floating-input"
                      disabled
                    />
                  )}

                  <Form.Text className="text-danger">
                    {formik.touched.lineOfBusiness &&
                    formik.errors.lineOfBusiness ? (
                      <div className="text-danger">
                        {formik.errors.lineOfBusiness}
                      </div>
                    ) : null}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} className="d-flex pt-4">
                {!isEditing ? (
                  <Button
                    className="primary-btn"
                    style={{ marginRight: "5px" }}
                    type="submit"
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    className="primary-btn"
                    style={{ alignItems: "right", marginRight: "5px" }}
                    type="submit"
                    onClick={formik.handleSubmit}
                  >
                    Save
                  </Button>
                )}
                {!isEditing ? (
                  formik.values.status === "ACTIVE" ? (
                    <Button
                      type="button"
                      className="btn-secondary"
                      onClick={handleDeactivate}
                    >
                      Deactivate
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      className="btn-secondary"
                      onClick={handleDeactivate}
                    >
                      Activate
                    </Button>
                  )
                ) : (
                  <>
                    <Button
                      type="button"
                      className="btn-secondary"
                      onClick={handleCancel}
                    >
                      {}
                      Cancel
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Form>
          {showPopup && (
            <Popup
              // message={popupMessage}
              // message1={popupMessage1}
              onClose={handleClosePopup}
              show={true}
            />
          )}
        </>
      ) : (
        <span>Data not found</span>
      )}
    </>
  );
}
