import React, { useEffect, useState } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import {
  Table,
  Button,
  Row,
  Pagination,
  Form,
} from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link, useHistory } from "react-router-dom";
import { decryptData } from "../../../services/auth-service";

import "./paginations.css"; // Assuming this file contains custom styles

export default function ProposalList() {
  const [quotes, setQuotes] = useState([
    {
      id: 1,
      purchasedOn: "2024-06-20",
      insuredName: "John Doe",
      policyPurchasedFor: "Car",
      policyNumber: "ABC123",
      policyStartDate: "2024-07-01",
      policyEndDate: "2025-07-01",
      logo: "https://example.com/logo1.png",
    },
    {
      id: 2,
      purchasedOn: "2024-06-21",
      insuredName: "Jane Smith",
      policyPurchasedFor: "Home",
      policyNumber: "XYZ456",
      policyStartDate: "2024-07-05",
      policyEndDate: "2025-07-05",
      logo: "https://example.com/logo2.png",
    },
    // Add more objects as needed
  ]);

  const [pageSize, setPageSize] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNo, setpageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const history = useHistory();
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  // Placeholder for future data fetching
  // useEffect(() => {
  //   fetchData();
  // }, [user, currentPage, pageSize]);

  // const fetchData = async () => {
  //   try {
  //     const data = { pageSize, pageNo, createdBy: user.email };
  //     const response = await AgentService.getQuotes(data);
  //     setQuotes(response.data.data.content);
  //     setTotalPages(response.data.data.totalPages);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const handlePageChange = (page) => {
  //   setpageNo(page - 1);
  //   setCurrentPage(page);
  // };

  // const handlePageSizeChange = (e) => {
  //   setPageSize(parseInt(e.target.value));
  //   fetchData();
  // };

  // const handleExportPDF = () => {
  //   const doc = new jsPDF("p", "pt", "a4");
  //   const table = document.getElementById("data-table");
  //   var res = doc.autoTableHtmlToJson(table);
  //   doc.text("List Of Quotes", 300, 50, "center");
  //   let str = new Date().toLocaleDateString();
  //   doc.setFontSize(8);
  //   doc.text("Date:" + str, 14, 30);
  //   res.columns.pop();
  //   let columns = res.columns;
  //   let rows = [];
  //   quotes.forEach((quote) => rows.push(Object.values(quote)));
  //   doc.autoTable(columns, rows, {
  //     theme: "grid",
  //     startY: 70,
  //     headStyles: { fillColor: "#f7d0d6" },
  //     showHead: "firstPage",
  //     styles: { overflow: "hidden", fontSize: 7 },
  //   });
  //   doc.save("quotes_report");
  // };

  // const filteredQuotes = quotes.filter((quote) =>
  //   Object.values(quote).some((value) =>
  //     typeof value === "string" ? value.toLowerCase().includes(searchTerm.toLowerCase()) :
  //       typeof value === "number" ? value === parseFloat(searchTerm) : false
  //   )
  // );

  // useEffect(() => {
  //   setQuotes(filteredQuotes);
  // }, [filteredQuotes]);

  return (
    <div>
      <React.Fragment>
        <Container>
          <Row>
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                marginBottom: "10px",
                width: "250px",
                marginRight: "41%",
              }}
            />

            <Button
              // onClick={handleExportPDF}
              className="primary-btn float-end"
              size="sm"
              style={{
                marginBottom: "10px",
                marginLeft: "10px",
                width: "200px",
                alignItems: "right",
              }}
            >
              Export PDF
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.location.reload(false);
              }}
              className=" float-end "
              variant="secondary"
              size="sm"
              style={{
                marginBottom: "10px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              Refresh
            </Button>

            <Table
              className="basic-table"
              striped
              bordered
              hover
              id="data-table"
            >
              <thead>
                <tr>
                <th></th>
                    <th>Proposal Date</th>
                    <th>Insured Name</th>
                    <th>Proposal Number</th>
                    <th>Policy Start Date</th>
                    <th>Policy End Date</th>
                    <th>Premium(RS)</th>
                    <th></th>
                </tr>
              </thead>
              <tbody>
                {quotes.map((quote, index) => (
                  <tr key={index}>
                    <td>
                      <img src={quote.logo} alt="Logo" style={{ width: "50px" }} />
                    </td>
                    <td>{quote.purchasedOn}</td>
                    <td>{quote.insuredName}</td>
                    <td>{quote.policyPurchasedFor}</td>
                    <td>{quote.policyNumber}</td>
                    <td>{quote.policyStartDate}</td>
                    <td>{quote.policyEndDate}</td>
                    <td>
                      <a href="/">Download</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div>
              <label htmlFor="pageSizeDropdown">Show Records:</label>
              <select
                id="pageSizeDropdown"
                value={pageSize}
                // onChange={handlePageSizeChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <Pagination className="justify-content-end">
              <Pagination.Prev
                // onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {[...Array(totalPages).keys()].map((page) => (
                <Pagination.Item
                  key={page + 1}
                  active={page + 1 === currentPage}
                  //   onClick={() => handlePageChange(page + 1)}
                >
                  {page + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                // onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  );
}
