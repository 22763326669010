import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom'
import Logo from "../../components/style/assets/img/logo/logo.png"
export const Footer = () => {

    const [isMobileView, setMobileView] = useState(window.innerWidth <= 991);
    const [activeAccordion, setActiveAccordion] = useState(null);

    // Update mobile view state on window resize
    useEffect(() => {
        const handleResize = () => {
            setMobileView(window.innerWidth <= 991);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Toggle accordion dropdown
    const toggleAccordion = (section) => {
        if (isMobileView) {
            setActiveAccordion((prev) => (prev === section ? null : section));
        }
    };

    return (
        <>




            <footer class="bb-footer mt-[30px] max-[1199px]:mt-[33px] bg-[#f8f8fb] text-[#fff]">
                <div class="footer-containers  border-t-[1px] border-solid border-[#eee]">
                    <div class="footer-top py-[50px] max-[1199px]:py-[35px]">
                        <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
                            <div class="flex flex-wrap w-full max-[991px]:mb-[-30px]" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                                <div class="footer-accordion min-[992px]:w-[25%] max-[991px]:w-full w-full px-[12px] bb-footer-toggle bb-footer-cat">
                                    <div class="bb-footer-widget bb-footer-company flex flex-col max-[991px]:mb-[24px]">
                                        {/* <img src={Logo} class="bb-footer-logo max-w-[144px] mb-[30px] max-[767px]:max-w-[130px]" alt="footer logo" /> */}
                                        <p class="bb-footer-detail max-w-[400px] mb-[30px] p-[0] font-Poppins text-[14px] leading-[27px] font-normal text-[#686e7d] inline-block relative max-[1399px]:text-[15px] max-[1199px]:text-[14px]" align="justify" style={{ textAlign: 'left' }}>
                                            As a Composite Broker, we work with all insurers across private and public sectors, offering General, Life, and Reinsurance services.<br /><br/>
                                            <p style={{ display: 'inline', whiteSpace: 'nowrap' }}>IRDA LICENSE NO : CB/446/09/2021</p><br />
                                            <a href="assets/img/category/isnp-certificate.pdf" target="_blank" style={{ display: 'inline', textAlign: 'justify' }}><p>ISNP Certificate</p></a>
                                        </p>
                                    </div>
                                </div>
                                <div class="min-[992px]:w-[16.66%] max-[991px]:w-full w-full px-[12px] bb-footer-toggle bb-footer-info">
                                    <div class="bb-footer-widget text-center">
                                        <h4 class="bb-footer-heading font-quicksand leading-[1.2] text-[18px] font-bold mb-[20px] text-[#3d4750] tracking-[0] relative block w-full pb-[15px] capitalize border-b-[1px] border-solid border-[#eee] max-[991px]:text-[14px]" style={{ textAlign: 'left' }} onClick={() => toggleAccordion("insurance")}>Insurance
                                            <div className="bb-heading-res">
                                                <i
                                                    className={
                                                        activeAccordion === "insurance"
                                                            ? "ri-arrow-up-s-line"
                                                            : "ri-arrow-down-s-line"
                                                    }
                                                ></i>
                                            </div>
                                        </h4>
                                        {/* <div class="bb-footer-links bb-footer-dropdown hidden max-[991px]:mb-[35px]"> */}
                                        <div
                                            className={`bb-footer-links bb-footer-dropdown max-[991px]:mb-[35px] ${activeAccordion === "insurance" ? "" : "hidden"
                                                }`}
                                            style={{
                                                display: isMobileView && activeAccordion === "insurance" ? "block" : "none",
                                            }}
                                        >
                                            <ul class="align-items-center pl-0">
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/car" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Car Insurance</a>
                                                </li>
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/bike" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Bike Insurance </a>
                                                </li>
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/health" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Health Insurance</a>
                                                </li>
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/term-life" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Term Life </a>
                                                </li>
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/travel-insurance" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Travel Insurance </a>
                                                </li>
                                                <li class="bb-footer-link leading-[1.5] flex items-center">
                                                    <a href="/business" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Business</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="min-[992px]:w-[16.66%] max-[991px]:w-full w-full px-[12px] bb-footer-toggle bb-footer-service">
                                    <div class="bb-footer-widget">
                                        <h4 class="bb-footer-heading font-quicksand leading-[1.2] text-[18px] font-bold mb-[20px] text-[#3d4750] tracking-[0] relative block w-full pb-[15px] capitalize border-b-[1px] border-solid border-[#eee] max-[991px]:text-[14px]" style={{ textAlign: 'left' }} onClick={() => toggleAccordion("company")}>Company
                                            <div className="bb-heading-res">
                                                <i
                                                    className={
                                                        activeAccordion === "company"
                                                            ? "ri-arrow-up-s-line"
                                                            : "ri-arrow-down-s-line"
                                                    }
                                                ></i>
                                            </div>

                                        </h4>
                                        {/* <div class="bb-footer-links bb-footer-dropdown hidden max-[991px]:mb-[35px]"> */}
                                        <div
                                            className={`bb-footer-links bb-footer-dropdown max-[991px]:mb-[35px] ${activeAccordion === "company" ? "" : "hidden"
                                                }`}
                                            style={{
                                                display: isMobileView && activeAccordion === "company" ? "block" : "none",
                                            }}
                                        >
                                            <ul class="align-items-center pl-0">
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/about" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">About Us</a>
                                                </li>

                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/Career" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Career</a>
                                                </li>
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="https://policies365.com/onBoardingPage" target="_blank" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Become POSP</a>
                                                </li>
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/contact" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Contact Us</a>
                                                </li>
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/login" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Login</a>
                                                </li>
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/sitemap" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Sitemap</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="min-[992px]:w-[16.66%] max-[991px]:w-full w-full px-[12px] bb-footer-toggle bb-footer-account">
                                    <div class="bb-footer-widget">
                                        <h4 class="bb-footer-heading font-quicksand leading-[1.2] text-[18px] font-bold mb-[20px] text-[#3d4750] tracking-[0] relative block w-full pb-[15px] capitalize border-b-[1px] border-solid border-[#eee] max-[991px]:text-[14px]" style={{ textAlign: 'left' }} onClick={() => toggleAccordion("quicklinks")}> Quick Links

                                            <div className="bb-heading-res">
                                                <i
                                                    className={
                                                        activeAccordion === "quicklinks"
                                                            ? "ri-arrow-up-s-line"
                                                            : "ri-arrow-down-s-line"
                                                    }
                                                ></i>
                                            </div>
                                        </h4>
                                        {/* <div class="bb-footer-links bb-footer-dropdown hidden max-[991px]:mb-[35px]"> */}
                                        <div
                                            className={`bb-footer-links bb-footer-dropdown max-[991px]:mb-[35px] ${activeAccordion === "quicklinks" ? "" : "hidden"
                                                }`}
                                            style={{
                                                display: isMobileView && activeAccordion === "quicklinks" ? "block" : "none",
                                            }}
                                        >
                                            <ul class="align-items-center pl-0">
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/privacyPolicy" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Privacy Policy</a>
                                                </li>
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/disclaimer" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Disclaimer</a>
                                                </li>
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/security" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Security</a>
                                                </li>
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/TermsAndConditions" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline">Terms and Conditions</a>
                                                </li>
                                                <li class="bb-footer-link leading-[1.5] flex items-center mb-[10px] max-[991px]:mb-[15px]">
                                                    <a href="/GrievanceRedressalPolicy" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] hover:text-[#6c7fd8] mb-[0] inline-block break-all tracking-[0] font-normal no-underline text-left">Grievance Redressal <br /> Policy</a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="min-[992px]:w-[25%] max-[991px]:w-full w-full px-[12px] bb-footer-toggle bb-footer-cont-social">
                                    <div class="bb-footer-contact mb-[30px]">
                                        <div class="bb-footer-widget">
                                            <h4 class="bb-footer-heading font-quicksand leading-[1.2] text-[18px] font-bold mb-[20px] text-[#3d4750] tracking-[0] relative block w-full pb-[15px] capitalize border-b-[1px] border-solid border-[#eee] max-[991px]:text-[14px]" style={{ textAlign: 'left' }} onClick={() => toggleAccordion("contact")}>Contact
                                                <div className="bb-heading-res">
                                                    <i
                                                        className={
                                                            activeAccordion === "contact"
                                                                ? "ri-arrow-up-s-line"
                                                                : "ri-arrow-down-s-line"
                                                        }
                                                    ></i>
                                                </div>
                                            </h4>

                                            {/* <div class="bb-footer-links bb-footer-dropdown hidden max-[991px]:mb-[35px]"> */}
                                            <div
                                                className={`bb-footer-links bb-footer-dropdown max-[991px]:mb-[35px] ${activeAccordion === "contact" ? "" : "hidden"
                                                    }`}
                                                style={{
                                                    display: isMobileView && activeAccordion === "contact" ? "block" : "none",
                                                }}
                                            >
                                                <ul class="align-items-center pl-0">
                                                    <li class="bb-footer-link bb-foo-location flex items-start max-[991px]:mb-[15px] mb-[16px]">
                                                        <span class="mt-[5px] w-[25px] basis-[auto] grow-[0] shrink-[0]">
                                                            <i class="ri-map-pin-line leading-[0] text-[18px] text-[#6c7fd8]"></i>
                                                        </span>
                                                        <p class="m-[0] font-Poppins text-[14px] text-[#686e7d] font-normal leading-[28px] tracking-[0.03rem]" style={{ textAlign: 'left' }}>First FLoor, Sangam Complex, C&B Square, 102, Chakala, Andheri East, Mumbai, Maharashtra 400059

                                                        </p>
                                                    </li>

                                                    <li class="bb-footer-link bb-foo-call flex items-start max-[991px]:mb-[15px] mb-[16px]">
                                                        <span class="w-[25px] basis-[auto] grow-[0] shrink-[0]">
                                                            <i class="ri-whatsapp-line leading-[0] text-[18px] text-[#6c7fd8]"></i>
                                                        </span>
                                                        <a href="tel:02262526252" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] inline-block relative break-all tracking-[0] font-normal max-[1399px]:text-[15px] max-[1199px]:text-[14px] no-underline">022-62526252</a>
                                                    </li>
                                                    <li class="bb-footer-link bb-foo-mail flex">
                                                        <span class="w-[25px] basis-[auto] grow-[0] shrink-[0]">
                                                            <i class="ri-mail-line leading-[0] text-[18px] text-[#6c7fd8]"></i>
                                                        </span>
                                                        <a href="mailto:contact@policies365.com" class="transition-all duration-[0.3s] ease-in-out font-Poppins text-[14px] leading-[20px] text-[#686e7d] inline-block relative break-all tracking-[0] font-normal max-[1399px]:text-[15px] max-[1199px]:text-[14px] no-underline">contact@policies365.com</a>
                                                    </li>
                                                </ul>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bb-footer-social">
                                        <div class="bb-footer-widget">
                                            <div class="bb-footer-links bb-footer-dropdown  max-[991px]:mb-[35px]">
                                                <ul class="align-items-center flex flex-wrap items-center pl-0">
                                                    <li class="bb-footer-link leading-[1.5] flex items-center pr-[5px]">
                                                        <a href="https://www.facebook.com/navnitinsurancebroking" target="_blank"  class="transition-all duration-[0.3s] ease-in-out w-[30px] h-[30px] rounded-[5px] bg-[#3d4750] hover:bg-[#6c7fd8] capitalize flex items-center justify-center text-[15px] leading-[20px] text-[#686e7d] relative break-all font-normal no-underline"><i class="ri-facebook-fill text-[16px] text-[#fff]"></i></a>
                                                    </li>

                                                    <li class="bb-footer-link leading-[1.5] flex items-center pr-[5px]">
                                                        <a href="https://www.instagram.com/navnitinsurancebroking/" target="_blank"  class="transition-all duration-[0.3s] ease-in-out w-[30px] h-[30px] rounded-[5px] bg-[#3d4750] hover:bg-[#6c7fd8] capitalize flex items-center justify-center text-[15px] leading-[20px] text-[#686e7d] relative break-all font-normal no-underline"><i class="ri-instagram-line text-[16px] text-[#fff]"></i></a>
                                                    </li>

                                                    <li class="bb-footer-link leading-[1.5] flex items-center pr-[5px]">
                                                        <a href="https://www.linkedin.com/company/88037881/admin/" target="_blank"  class="transition-all duration-[0.3s] ease-in-out w-[30px] h-[30px] rounded-[5px] bg-[#3d4750] hover:bg-[#6c7fd8] capitalize flex items-center justify-center text-[15px] leading-[20px] text-[#686e7d] relative break-all font-normal no-underline"><i class="ri-linkedin-fill text-[16px] text-[#fff]"></i></a>
                                                    </li>

                                                    <li class="bb-footer-link leading-[1.5] flex items-center pr-[5px]">
                                                        <a href="https://twitter.com/InsuranceNavnit" target="_blank"  class="transition-all duration-[0.3s] ease-in-out w-[30px] h-[30px] rounded-[5px] bg-[#3d4750] hover:bg-[#6c7fd8] capitalize flex items-center justify-center text-[15px] leading-[20px] text-[#686e7d] relative break-all font-normal no-underline"><i class="ri-twitter-x-line text-[16px] text-[#fff]"></i></a>
                                                    </li>

                                                    <li class="bb-footer-link leading-[1.5] flex items-center pr-[5px]">
                                                        <a href="https://www.youtube.com/channel/UCBmAVSGr1xSmVnc4MySsr2w" target="_blank"  class="transition-all duration-[0.3s] ease-in-out w-[30px] h-[30px] rounded-[5px] bg-[#3d4750] hover:bg-[#6c7fd8] capitalize flex items-center justify-center text-[15px] leading-[20px] text-[#686e7d] relative break-all font-normal no-underline"><i class="ri-youtube-fill text-[16px] text-[#fff]"></i></a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="footer-bottom py-[10px] border-t-[1px] border-solid border-[#eee] max-[991px]:py-[15px]">
                        <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
                            <div class="flex flex-wrap w-full">
                                <div class="bb-bottom-info w-full flex min-[992px]:flex-row items-center justify-between max-[991px]:flex-col  px-[12px]">
                                    <div class="footer-copy max-[991px]:mb-[15px]">
                                        <div class="footer-bottom-copy max-[991px]:text-center">
                                            <div class="bb-copy text-[#686e7d] text-[13px] tracking-[1px] text-center font-normal leading-[2]">Copyright © 2024 All rights reserved by Navnit Insurance Broking Pvt. Ltd.
                                                {/* <a class="site-name transition-all duration-[0.3s] ease-in-out font-medium text-[#6c7fd8] hover:text-[#3d4750] font-Poppins text-[15px] leading-[28px] tracking-[0.03rem]">All rights reserved by Navnit Insurance Broking PVT. LTD</a>   */}
                                            </div>
                                        </div>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
