

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import health from "../../../components/style/assets/img/banners/health-insurance.jpg";
import Form from "react-bootstrap/Form";
import ErrorMessage from "../../commonModules/ErrorMessage";
import InputFieldText from "../../commonModules/InputFieldText";
import InputFieldDropdown from "../../commonModules/InputFieldDropdown";
import * as Yup from "yup";
import { useFormik } from "formik";
import { yearValidation } from "../../commonModules/CommonCode";
import AdhocService from "../../../services/adhoc.service";
import { BsCheckCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function HealthPage() {
  const [showContent, setShowContent] = useState(true);
  const history = useHistory();  
  const optionArray = [
    { value: "5L", label: "5L" },
    { value: "10L", label: "10L" },
    { value: "20L", label: "20L" },
    { value: "50L", label: "50L" },
    { value: "1Cr", label: "1Cr" },
    { value: "2Cr", label: "2Cr" },
  ];
  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    sumInsured: "5L",
    dob: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(" First Name Is Required")
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),

    lastName: Yup.string()
      .required(" Last Name Is Required")
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),

    email: Yup.string()
      .required(" Email Is Required")
      .email("Wrong email format")
      .max(50, "Maximum 50 characters allowed"),

    phoneNumber: Yup.string()
      .required(" Phone Number Is Required")
      .matches(/^[0-9\b]+$/, "Invalid Phone number !")
      .min(10, "Minimum 10 digit required")
      .max(10, "Maximum 10 digit required"),

    dob: Yup.string()
      .trim()
      .required("DOB Is Required")
      .test("not blank", "Select value", (value) => value !== "")
      .test("valid date", "Future date is not allowed", (value) => {
        const currentDate = new Date();
        const inputDate = new Date(value);
        return inputDate <= currentDate;
      })
      .test(
        "valid range",
        "Date should be within the past 100 years",
        (value) => {
          return yearValidation(value, 100);
        }
      ),
  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const requestBody = {
        module: "Health",
        customerName: values.firstName + values.lastName,
        mobileNumber: values.phoneNumber,
        emailID: values.email,
        dateOfBirth: values.dob,
        sumInsured: values.sumInsured,
      };
      await AdhocService.addLead(requestBody)
      .then((res) => {
        setShowContent(false);
        history.push("/health-Thankyou");  
      })
      .catch((error) => {
      });
  },
});
  // ---- --useEffect---------
  useEffect(() => {
    document.body.classList.add("car-page");
    return () => {
      document.body.classList.remove("car-page");
    };
  }, []);

  return (
    <React.Fragment>
      <style>
        {`
          [type="radio"]:checked,
          [type="radio"]:not(:checked)
          {
            opacity: unset;
            display: unset;
          }
        `}
      </style>

      <section class="section-breadcrumb mb-[50px] max-[1199px]:mb-[35px] border-b-[1px] border-solid border-[#eee] bg-[#f8f8fb]">
        <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
          <div class="flex flex-wrap w-full">
            <div class="w-full px-[12px]">
              <div class="flex flex-wrap w-full bb-breadcrumb-inner m-[0] py-[20px] items-center">
                <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                  <h2 class="max-[767px]:justify-start min-[767px]:flex bb-breadcrumb-title font-quicksand tracking-[0.03rem] leading-[1.2] text-[16px] font-bold text-[#3d4750] max-[767px]:text-center max-[767px]:mb-[10px]">
                    Health Insurance
                  </h2>
                </div>
                <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                  <ul class="bb-breadcrumb-list mx-[-5px] mb-0 flex justify-end min-[767px]:justify-end max-[768px]:justify-center">
                    <li class="bb-breadcrumb-item text-[14px] font-normal px-[5px]">
                      <a
                        href="index.html"
                        class="font-Poppins text-[14px] leading-[28px] tracking-[0.03rem] font-semibold text-[#686e7d] no-underline"
                      >
                        Home
                      </a>
                    </li>
                    <li class="text-[14px] font-normal px-[5px]">
                      <i class="ri-arrow-right-double-fill text-[14px] font-semibold leading-[28px]"></i>
                    </li>
                    <li class="bb-breadcrumb-item font-Poppins text-[#686e7d] text-[14px] leading-[28px] font-normal tracking-[0.03rem] px-[5px] active">
                      Health Insurance
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Container className="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px] py-[50px] max-[1199px]:py-[35px]">
        <Row className="align-items-center">
          <Col sm={12} lg={5} md={12} xs={12}>
            <div className="inner-blog-details-contact max-[992px]:mb-[30px]">
              <h3 class="sub-title flex justify-start font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[30px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                Health Insurance (Mediclaim)
              </h3>
              <br />
              <img src={health} alt="" width={"100%"} />
            </div>
          </Col>

          {showContent && (
          <Col  sm={12} lg={7} md={12} xs={12}>
              <div class="bb-blog-sidebar mb-[-24px]">
                <div
                  class="blog-inner-contact p-[30px] border-[1px] border-solid border-[#eee] max-[992px]:mb-[24px] rounded-[20px] max-[575px]:p-[15px]"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div class="blog-sidebar-title mb-[20px]">
                    <h4 class="font-quicksand  flex justify-start text-left tracking-[0.03rem] leading-[1.2] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[18px]">
                    Secure Your Health Now – Get Quotes from Experts today!

                    </h4>
                  </div>
                  <Form
                    as="div"
                    className="form-bg"
                    onSubmit={formik.handleSubmit}
                  >
                    <Row>
                      <Col md={6} sm={12} className="mb-3">
                        <InputFieldText
                          formikFieldName="firstName"
                          placeholder="First name*"
                          formik={formik}
                        />
                        <ErrorMessage formik={formik} fieldValue="firstName" />
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <InputFieldText
                          formikFieldName="lastName"
                          placeholder="Last name*"
                          formik={formik}
                        />
                        <ErrorMessage formik={formik} fieldValue="lastName" />
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <InputFieldText
                          formikFieldName="email"
                          placeholder="Email Id*"
                          formik={formik}
                        />
                        <ErrorMessage formik={formik} fieldValue="email" />
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <InputFieldText
                          formikFieldName="phoneNumber"
                          placeholder="Phone Number*"
                          formik={formik}
                          onlyNumber={true}
                        />
                        <ErrorMessage
                          formik={formik}
                          fieldValue="phoneNumber"
                        />
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <InputFieldText
                          formikFieldName="dob"
                          placeholder="Date of birth*"
                          formik={formik}
                          type="date"
                        />
                        <ErrorMessage formik={formik} fieldValue="dob" />
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <InputFieldDropdown
                          formikFieldName="sumInsured"
                          label="Sum Insured*"
                          optionsArray={optionArray}
                          formik={formik}
                        />
                        <ErrorMessage formik={formik} fieldValue="sumInsured" />
                      </Col>
                    </Row>
                    <Button
                      type="submit"
                      onClick={() => {
                        formik?.handleSubmit();
                      }}
                      className="mt-4 primary-btn"
                      disabled={!showContent}
                    >
                      {showContent ? "Submit" : "Submitted"}
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col col={12}>
          <div class="inner-blog-details-contact mb-[30px] mt-[50px] text-left">
                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                    Comprehensive Health Insurance Starting at Just ₹790/Month* – Protect Your Family Today!


                    </h3>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                    Affordable, Reliable, and Tailored Plans to Meet Your Needs. Get Instant Quotes and Hassle-Free Claims!
                    </h3>

                    {/* <p class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"></p> */}
                    <br />
                    
                    <h3 class="sub-title font-quicksand tracking-[0.03rem] mb-[20px] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                    Why Choose Policies 365 for Your Health Insurance?


                    </h3>

                    <div class="bb-single-list mb-[30px]">
                      <ul class="my-[-8px] pl-[18px]">
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                         <strong className="font-semibold"> Affordable Premiums:</strong> Plans starting at ₹790/month*, ensuring quality healthcare for all.

                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                          <strong className="font-semibold">Wide Coverage:</strong> Includes hospital bills, pre & post-hospitalization, critical illnesses, and more.

                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                          <strong className="font-semibold">Cashless Claims: 
                          </strong> Over 10,000+ network hospitals across India for seamless cashless treatments.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                          <strong className="font-semibold">Customizable Plans:
                          </strong> Choose the coverage and benefits that suit you and your family.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                          <strong className="font-semibold">Tax Benefits:
                          </strong> Save up to ₹75,000 under Section 80D of the Income Tax Act.
                          </span>
                        </li>
                      </ul>
                    </div>
                    </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
