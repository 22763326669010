import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContainerForLeadView from "../CommonComponents/ContainerForLeadView";
import { useHistory, useParams } from "react-router-dom";
import { decryptData } from "../../../services/auth-service";

export default function GetLeadInfo(props) {

const leadParams =useParams();
let decodedData = 
  { rtoNo: "MH01AA6719", module: "motor" }


  const history = useHistory();
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        if(user1.role!=="AGENT" && user1.role!=="MANAGER" && user1.role!=="POSP")
          history.push("/accessdenied");
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <h4 className="mb-5 mt-5">Lead Details</h4>

          <Col className="mb-3">
            <ContainerForLeadView  rtoNo={decodedData.rtoNo} module={decodedData.module}/>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}