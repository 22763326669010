import React from "react";
import UseFormContext from "../../context/UseFormContext";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "react-bootstrap";
import InputFieldText from "../commonModules/InputFieldText";
import { Margin } from "react-to-pdf";
import { useEffect } from "react";
import { useState, useRef } from "react";

export default function PaymentForm(props, proposalNo) {
  const [netPremiumForBrekupvalue, setnetPremiumForBrekupvalue] = useState();
  const ref = useRef();
  useEffect(() => {
    window.location.hash = "paymentform";
  }, []);
  const formContext = UseFormContext();

  useEffect(() => {
    openQuotePopup(formContext.singleQuotesData);
  }, []);

  const openQuotePopup = (data) => {
    var premium = 0;
    var odpremium = 0;
    // for new india we get array for net premium and for idv so we need this logic
    if (typeof data?.netPremium === "object") {
      data?.netPremium?.forEach((foreachdata, index) => {
        premium = premium + foreachdata;
      });
    } else {
      premium = data?.netPremium;
    }

    if (typeof data?.odpremium === "object") {
      data?.odpremium?.forEach((foreachdata, index) => {
        odpremium = odpremium + foreachdata;
      });
    } else {
      odpremium = data?.odpremium;
    }
    setnetPremiumForBrekupvalue({ premium: premium, odpremium: odpremium });
    formContext.setsingleQuotesData(data);
  };

  const expiryDate = formContext?.quotesPageFormikData?.expiryDate
    ?.split("-")
    ?.reverse()
    ?.join("/");
  let policyTerms = localStorage.getItem("policyTerms");
  const quotePremium = (
    <div>
      <div className="premium-container" ref={ref}>
        <div className="topSection">
          <div className="premium-amount-wrap">
            <div className="premium-amount-box">
              <span className="fieldset">Proposal Number</span>
              <h3>{formContext?.iciciData?.AdditionalInfo1}</h3>
            </div>
          </div>
          <div className="premium-amount-wrap">
            <div className="premium-amount-box">
              <span className="fieldset">Idv</span>
              <h3>₹ {formContext.singleQuotesData?.insuredDeclareValue}</h3>
            </div>
          </div>
          <div className="premium-amount-wrap">
            <div className="premium-amount-box">
              <span className="fieldset">Premium</span>
              <h3>
                ₹{" "}
                {Number(formContext.singleQuotesData?.grossPremium)?.toFixed(0)}
              </h3>
            </div>
            <span className="help-text">Exclusive of GST</span>
          </div>
        </div>

        <div className="tab-section">
          <div className="tabs">
            <span className="tab-text">Premium Details</span>
          </div>
          <div className="tab-content">
            {netPremiumForBrekupvalue?.odpremium !== 0 && (
              <div className="tab-row">
                <p>OD premium</p>
                {typeof netPremiumForBrekupvalue?.odpremium === "number" && (
                  <p>
                    ₹ {Number(netPremiumForBrekupvalue?.odpremium)?.toFixed(0)}
                  </p>
                )}
                {typeof netPremiumForBrekupvalue?.odpremium === "string" && (
                  <p>
                    ₹{" "}
                    {Number(
                      netPremiumForBrekupvalue?.odpremium.replace("INR", "")
                    )?.toFixed(0)}
                  </p>
                )}
              </div>
            )}
            {formContext.singleQuotesData?.insuranceCompany ===
            "New India Assurance"
              ? formContext.singleQuotesData?.tppremium !== 0 && (
                  <div className="tab-row">
                    <p>TP premium</p>
                    {typeof formContext.singleQuotesData?.tppremium ===
                      "number" && (
                      <p>
                        ₹{" "}
                        {Number(
                          formContext.singleQuotesData?.tppremium
                        )?.toFixed(0) * Number(policyTerms)}
                      </p>
                    )}
                    {typeof formContext.singleQuotesData?.tppremium ===
                      "string" && (
                      <p>
                        ₹{" "}
                        {Number(
                          formContext.singleQuotesData?.tppremium.replace(
                            "INR",
                            ""
                          )
                        )?.toFixed(0) * Number(policyTerms)?.toFixed(0)}
                      </p>
                    )}
                  </div>
                )
              : formContext.singleQuotesData?.tppremium !== 0 && (
                  <div className="tab-row">
                    <p>TP premium</p>
                    {typeof formContext.singleQuotesData?.tppremium ===
                      "number" && (
                      <p>
                        ₹{" "}
                        {Number(
                          formContext.singleQuotesData?.tppremium
                        )?.toFixed(0)}
                      </p>
                    )}
                    {typeof formContext.singleQuotesData?.tppremium ===
                      "string" && (
                      <p>
                        ₹{" "}
                        {Number(
                          formContext.singleQuotesData?.tppremium.replace(
                            "INR",
                            ""
                          )
                        )?.toFixed(0)}
                      </p>
                    )}
                  </div>
                )}

            {formContext.singleQuotesData?.ridersList?.map((mapdata) => {
              // Check if riderValue is non-zero and valid
              if (
                mapdata?.riderValue &&
                mapdata?.riderValue !== 0 &&
                mapdata?.riderValue !== "0" &&
                mapdata?.riderValue !== null &&
                mapdata?.riderValue !== undefined
              ) {
                return (
                  <div className="tab-row" key={mapdata.riderId}>
                    <p>{mapdata?.riderName}</p>
                    {mapdata?.riderValue?.toString().includes("INR") ? (
                      <p>
                        ₹{" "}
                        {Number(mapdata?.riderValue.replace("INR", "")).toFixed(
                          0
                        )}
                      </p>
                    ) : (
                      <p>₹ {Number(mapdata?.riderValue).toFixed(0)}</p>
                    )}
                  </div>
                );
              }
              return null; // Return null if riderValue is 0
            })}

            {formContext.carbikeformikValues?.formtype === "bike"
              ? formContext.singleQuotesData?.discountList?.map(
                  (mapdataNcb) => {
                    const isDiscount = mapdataNcb?.type?.includes("Discount");
                    const sign = isDiscount ? "-" : "";

                    // If the type is undefined, skip rendering
                    if (!mapdataNcb?.type) {
                      return null;
                    } else if (!mapdataNcb?.discountAmount) {
                      return null;
                    } else {
                      // If the conditions for skipping the discount are met, skip rendering
                      if (
                        formContext.singleQuotesData?.insuranceCompany ===
                          "New India Assurance" &&
                        formContext.singleQuotesData?.policyType !== "new" &&
                        mapdataNcb?.type === "OD Discount" &&
                        policyTerms !== "1" &&
                        (policyTerms === "2" || policyTerms === "3")
                      ) {
                        let data = mapdataNcb?.discountAmount?.reduce(
                          (acc, curr) => acc + curr,
                          0
                        );
                        return (
                          <div className="tab-row" key={mapdataNcb?.type}>
                            <p>{mapdataNcb?.type}</p>
                            <p>
                              {" "}
                              {sign} ₹{data?.toFixed(0)}
                            </p>
                          </div>
                        );
                      } else {
                        // Render the discount item
                        return (
                          <div className="tab-row" key={mapdataNcb?.type}>
                            <p>{mapdataNcb?.type}</p>
                            <p>
                              {" "}
                              {sign} ₹
                              {Number(mapdataNcb?.discountAmount)?.toFixed(0)}
                            </p>
                          </div>
                        );
                      }
                    }
                  }
                )
              : formContext.singleQuotesData?.discountList?.map(
                  (mapdataNcb) => {
                    const isDiscount = mapdataNcb?.type?.includes("Discount");
                    const sign = isDiscount ? "-" : "";

                    // If the type is undefined, skip rendering
                    if (!mapdataNcb?.type) {
                      return null;
                    } else if (!mapdataNcb?.discountAmount) {
                      return null;
                    } else {
                      // If the conditions for skipping the discount are met, skip rendering
                      if (
                        (formContext.singleQuotesData?.insuranceCompany ===
                          "Reliance General Insurance Co. Ltd" ||
                          formContext.singleQuotesData?.insuranceCompany ===
                            "New India Assurance" ||
                          formContext.singleQuotesData?.insuranceCompany ===
                            "Future Generali India Insurance Co. Ltd") &&
                        mapdataNcb?.type === "OD Discount"
                      ) {
                        return null;
                      } else {
                        // Render the discount item
                        return (
                          <div className="tab-row" key={mapdataNcb?.type}>
                            <p>{mapdataNcb?.type}</p>
                            <p>
                              {" "}
                              {sign} ₹
                              {Number(mapdataNcb?.discountAmount)?.toFixed(0)}
                            </p>
                          </div>
                        );
                      }
                    }
                  }
                )}

            <div className="tab-row borderTop ">
              <p className="font-weight-bold">Net premium</p>
              <p className="font-weight-bold">
                ₹ {Number(netPremiumForBrekupvalue?.premium)?.toFixed(0)}
              </p>
            </div>

            <div className="tab-row">
              <p>GST 18% </p>
              <p>
                ₹ {Number(formContext.singleQuotesData?.serviceTax)?.toFixed(0)}
              </p>
            </div>
            <div className="tab-row borderTop total-wrap">
              <p>Total Premium</p>
              <p>
                ₹{" "}
                {Number(formContext.singleQuotesData?.grossPremium)?.toFixed(0)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div>
        <h2>Click the finish button to Proceed for payment</h2>
        {formContext?.singleQuotesData.insuranceCompany ===
          "ICICI Lombard General Insurance Co. Ltd" &&
          !formContext.loaderStatus && (
            // <div>
            //   <Card style={{marginTop:"10%"}}>
            //     <CardHeader>
            //       <h5>Proposal Details</h5>
            //     </CardHeader>
            //     <CardBody>
            //     <Row>
            //       <Col>
            //         <Card.Text>
            //           <span className="help-text">Proposal Number : </span>
            //           <span>{formContext?.iciciData?.AdditionalInfo1}

            //           </span>
            //         </Card.Text>
            //       </Col>

            //       <Col>
            //         <Card.Text>
            //           {" "}
            //           <span className="help-text">Poposal Amount : </span>
            //           <span>{formContext?.iciciData?.Amount}

            //           </span>
            //         </Card.Text>
            //       </Col>
            //     </Row>
            //       <Row>

            //       </Row>
            //     </CardBody>
            //   </Card>
            // </div>
            <>{quotePremium}</>
          )}

        {formContext.loaderStatus &&
          formContext?.singleQuotesData.insuranceCompany ===
            "ICICI Lombard General Insurance Co. Ltd" &&
          formContext?.isLastStep && (
            <div>
              {formContext.spinner}
              <br />
              please wait while we create Proposal .
            </div>
          )}
        {formContext.loaderStatus &&
          formContext?.singleQuotesData.insuranceCompany !==
            "ICICI Lombard General Insurance Co. Ltd" && (
            <div>
              {formContext.spinner}
              <br />
              please wait, we are redirecting you to payment page.
            </div>
          )}
      </div>
    </React.Fragment>
  );
}
