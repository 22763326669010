import { useState, useEffect, useContext } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "./Popup.css";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import AdminService from "../../../services/admin.service";
import UserContext from "../../Context/UserContext";
import InputFieldDropdown from "../../commonModules/InputFieldDropdown";
import ErrorMessage from "../../commonModules/ErrorMessage";
import Validations from "../../../validations/validations";
import { decryptData } from "../../../services/auth-service";
import MailService from "../../../services/mail.service";
import Logo from "../../../images/logo.png";

export default function ContainerForAgent(props) {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState(null);
  const [popupMessage1, setPopupMessage1] = useState(null);
  const [managerList, setManagerList] = useState(null);
  const [allAgencies, setAllAgencies] = useState([]);
  const [branches, setBranches] = useState([]);

  const history = useHistory();
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  useEffect(() => {
    fetchManagers();
    fetchAgencies();
  }, []);

  const [loaderForSave, setloaderForSave] = useState(false);
  const [loaderForID, setloaderForID] = useState(false);
  const [loader, setloader] = useState(false);
  const spinner = (
    <Spinner
      animation="border"
      style={{ width: "5rem", height: "5rem" }}
      variant="danger"
    />
  );
  const spinner1 = <Spinner animation="border" variant="danger" />;

  const { userRole } = useContext(UserContext);
  const [gender, setGender] = useState([
    { value: "", label: "Please Select Gender" }, // Set the default value to blank
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ]);

  const [maritalStatus, setMaritalStatus] = useState([
    { value: "", label: "Please Select" }, // Set the default value to blank
    { value: "married", label: "Married" },
    { value: "single", label: "Single" },
  ]);

  const fetchManagers = async () => {
    const data = { role: "MANAGER" };
    try {
      await AdminService.listUsersByRole(data).then((data) => {
       
        setManagerList(data.data.data);
      });
    } catch (error) {
      
    }
  };

  const fetchAgencies = async () => {
    try {
      await AdminService.listAgency().then((response) => {
        setAllAgencies(response.data.data);
      });
    } catch (error) {
    }
  };

  let initialData = {
    firstName: "",
    lastName: "",
    agencyName: "",
    agencyId: "",
    branchId: "",
    phoneNumber: "",
    address: "",
    role: "Agent",
    reportingTo: "",
    email: "",
    createdBy: user?.email ? user.email : "",
    status: "ACTIVE",
    maritalStatus: "",
    gender: "",
    callerId: "",
    imCode: "",
   
  };

  const validationSchema = Validations.AddAgentValidation;

  const [value, setValue] = useState("");
  const maxLength = 256;
  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setValue(inputValue);
    }
  };

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleSubmit();
    },
  });

  const setId = async (value) => {
    setloaderForID(true);
    if (value !== "") {
      const agency = allAgencies.filter(
        (agency) => agency.agencyName === value
      );
      formik.setFieldValue("agencyId", agency[0].id);
      try {
        const data = { agencyId: agency[0].id };
        await AdminService.getAllBranchByAgencyId(data).then((response) => {
          setBranches(response.data.data);
          setloaderForID(false);
        });
      } catch (error) {
        setloaderForID(false);
      }
    }
  };

  const handleSubmit = async () => {
    setloader(true);
    //e.preventDefault();
    const data = formik.values;
    data.createdBy = user?.email;
    try {
      await AdminService.createUpdateUser(data).then(async (response) => {
        setPopupMessage(response.data.data.email);
        setPopupMessage1(response.data.data.password);
        setShowPopup(true);
        setloader(false);
      });
    } catch (error) {
      console.error("Error:", error);
      alert(error?.response?.data?.message);
      // let errorObj = error.response.data.data;
      // alert(Object.values(errorObj));
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    window.location.href = "/ListOfAgents";
  };

  return (
    <>
      {/* {spinner} */}
      <Form className="form-bg">
        <span>{userRole}</span>
        <Row style={{ textAlign: "left" }}>
          {/* <Spinner animation="border" variant="danger" /> */}

          {/* <SkeletonCard /> */}
          {/* <h5 style={{alignContent: "left"}}>Basic Information</h5> */}
          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="firstName"
              label="First Name*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="firstName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                className="floating-input"
              />
            </FloatingLabel>
            <Form.Text className="text-danger">
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="text-danger">{formik.errors.firstName}</div>
              ) : null}
            </Form.Text>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="lastName"
              label="Last Name*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="lastName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                className="floating-input"
              />
            </FloatingLabel>
            <Form.Text className="text-danger">
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="text-danger">{formik.errors.lastName}</div>
              ) : null}
            </Form.Text>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <InputFieldDropdown
              formikFieldName="gender"
              label="Gender*"
              optionsArray={gender}
              formik={formik}
            />
            <ErrorMessage formik={formik} fieldValue="gender" />
          </Col>
          <Col md={6} sm={12} className="mb-3">
            <InputFieldDropdown
              formikFieldName="maritalStatus"
              label="Marital status*"
              optionsArray={maritalStatus}
              formik={formik}
            />
            <ErrorMessage formik={formik} fieldValue="maritalStatus" />
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="agencyName"
              label="Agency Name*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Select
                name="agencyName"
                onChange={(event) => {
                  formik.handleChange(event);
                  setId(event.target.value); // Call the setID function with the new value
                }}
                onBlur={formik.handleBlur}
                value={formik.values.agencyName}
                className="floating-input"
              >
                <option hidden>Select</option>
                {allAgencies.map((agency) => {
                  return (
                    <option key={agency.id} value={agency.agencyName}>
                      {agency.agencyName}
                    </option>
                  );
                })}
              </Form.Select>
            </FloatingLabel>
            <Form.Text className="text-danger">
              {formik.touched.agencyName && formik.errors.agencyName ? (
                <div className="text-danger">{formik.errors.agencyName}</div>
              ) : null}
            </Form.Text>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="agencyId"
              label={
                <>
                  Agency Id*
                  {loaderForID && spinner1}
                </>
              }
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="agencyId"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.agencyId}
                className="floating-input"
                disabled
              />
            </FloatingLabel>
            <Form.Text className="text-danger">
              {formik.touched.agencyId && formik.errors.agencyId ? (
                <div className="text-danger">{formik.errors.agencyId}</div>
              ) : null}
            </Form.Text>
          </Col>
          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="branchId"
              label="Branch Id*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Select
                name="branchId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.branchId}
                className="floating-input"
              >
                <option hidden>Select</option>
                {branches ? (
                  branches.map((branch) => {
                    return (
                      <option value={branch.branchId}>{branch.name}</option>
                    );
                  })
                ) : (
                  <option>No Branch Found</option>
                )}
              </Form.Select>
            </FloatingLabel>
            <Form.Text className="text-danger">
              {formik.touched.branchId && formik.errors.branchId ? (
                <div className="text-danger">{formik.errors.branchId}</div>
              ) : null}
            </Form.Text>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="phoneNumber"
              label="Phone Number*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="phoneNumber"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                className="floating-input"
              />
            </FloatingLabel>
            <Form.Text className="text-danger">
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="text-danger">{formik.errors.phoneNumber}</div>
              ) : null}
            </Form.Text>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="address"
              label="Address*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="address"
                type="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                className="floating-input"
                maxLength={maxLength}
              />
              <div
                style={{
                  marginTop: "5px",
                  fontSize: "0.6rem",
                  textAlign: "right",
                }}
              >
                {maxLength - formik.values.address.length}/256
              </div>

              {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
              <Form.Text className="text-danger">
                {formik.touched.address && formik.errors.address ? (
                  <div className="text-danger">{formik.errors.address}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="email"
              label="Email*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className="floating-input"
              />
              <Form.Text className="text-danger">
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-danger">{formik.errors.email}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="userName"
              label="Username*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="userName"
                type="userName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className="floating-input"
                disabled
              />
              {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
              <Form.Text className="text-danger">
                {formik.touched.userName && formik.errors.userName ? (
                  <div className="text-danger">{formik.errors.userName}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="role"
              label="Designation*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Select
                name="role"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.role}
                className="floating-input"
              >
                <option hidden>Select</option>
                {user?.role === "MANAGER" ? (
                  <option value="AGENT">Agent</option>
                ) : (
                  <>
                    <option value="ADMIN">Admin</option>
                    <option value="MANAGER">Manager</option>
                    <option value="AGENT">Agent</option>
                  </>
                )}
              </Form.Select>

              {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
              <Form.Text className="text-danger">
                {formik.touched.role && formik.errors.role ? (
                  <div className="text-danger">{formik.errors.role}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="reportingTo"
              label="Reporting To*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Select
                name="reportingTo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reportingTo}
                className="floating-input"
              >
                <option hidden>Select</option>
                {managerList ? (
                  <>
                    {managerList.map((option) => {
                      return (
                        <option value={option.email}>{option.name}</option>
                      );
                    })}
                  </>
                ) : (
                  <option>No Managers Found</option>
                )}
              </Form.Select>
              {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
              <Form.Text className="text-danger">
                {formik.touched.reportingTo && formik.errors.reportingTo ? (
                  <div className="text-danger">{formik.errors.reportingTo}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="callerId"
              label="Caller ID*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="callerId"
                type="callerId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.callerId}
                className="floating-input"
              />
              {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
              <Form.Text className="text-danger">
                {formik.touched.callerId && formik.errors.callerId ? (
                  <div className="text-danger">{formik.errors.callerId}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="imCode"
              label="IM ID (PP ICICI Agent)"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="imCode"
                type="imCode"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.imCode}
                className="floating-input"
              />
              {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
              <Form.Text className="text-danger">
                {formik.touched.imCode && formik.errors.imCode ? (
                  <div className="text-danger">{formik.errors.imCode}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>
          </Col>

          
        </Row>
        <Button
          className="primary-btn"
          style={{ alignItems: "right", marginRight: "5px" }}
          type="submit"
          onClick={formik.handleSubmit}
          disabled={loader}
        >
          Add Agent {loader && spinner1}
        </Button>

        <Button
          type="button"
          className="btn-secondary"
          disabled={!formik.dirty}
          onClick={() => {
            formik.resetForm();
            setloader(false);
          }}
        >
          Cancel
        </Button>
      </Form>

      {showPopup && (
        <Modal show={showPopup} onHide={handleClosePopup}>
          <Modal.Header closeButton>
            <Modal.Title>User Created</Modal.Title>
          </Modal.Header>
          <Modal.Body>Email : {popupMessage}</Modal.Body>
          <Modal.Body>Password : {popupMessage1}</Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="primary-btn"
              onClick={handleClosePopup}
            >
              Okay!
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
