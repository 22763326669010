import React, { useEffect, useState } from "react";
import UseFormContext from "../../../context/UseFormContext";
import KYCForm from "../../MultipleForms/KYCForm";
import OwnerContactInfo from "../../MultipleForms/OwnerContactInfo";
import Benefits from "../../MultipleForms/Benefits";
import PaymentForm from "../../MultipleForms/PaymentForm";
import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import UseMultistepForm from "../../MultipleForms/UseMultiStepForms";
import VerticallyCenteredModal from "../../commonModules/Popups/VerticallyCenteredModal";
import { BiSolidError } from "react-icons/bi";
import SVG from "react-inlinesvg";
import Done from "../../../images/Done.svg";
import { useHistory } from "react-router-dom";
import RiderSelection from "./RiderSelection";
import MemberDetailis from "./MemberDetails";
import AddressDetails from "./AddressDetails";
import ContactInfo from "./ContactInfo";
import MedicalQuestionnaire from "./MedicalQuestionnaire";

const MultistepProposal = (props) => {
  const formContext = UseFormContext();

  const [modalShow, setModalShow] = useState(false);
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const paymentdataDestructure = {};
  const history = useHistory();
  const [iframeUrl, setiframeUrl] = useState();
  const [iframeStatus, setiframeStatus] = useState(false);
  const [kycPopUpStatus, setkycPopUpStatus] = useState(false);
  const [iframeOpenedCarrier, setiframeOpenedCarrier] = useState();

  const initialData = {
    currentStepIndex: "0",

    policyTerm: props.healthQuoteFormikData?.policyTerm,
    sumInsured: props.healthQuoteFormikData?.sumInsured,

    kycNominePanNumber: "",
    kycNomineAadharNumber: "",
    kycNomineDob: "",
    kycNomineGender: "",
    kycNomineFullName: "",

    ownerPrefix: "",
    ownerFirstName: "",
    ownerLastName: "",
    ownerEmail: "",
    ownerPhoneNumber: "",
    ownerMaritialStus: "",
    ownerGstin: "",

    familyDetails: props.healthQuoteFormikData.healthInsurefor.map((member) => {
      if (member.relation === 'Self') {
        return {
          id: member.id,
          name: props.healthMainFormData.firstName +' '+ props.healthMainFormData.lastName,
          heightFt: "",
          heightIn: "",
          weight: "",
          age: member.age ?? "",
          dateOfBirth: member.dateOfBirth ?? "",
          relation: member.relation ?? "",
        };
      } else {
        return {
          id: member.id,
          name: "",
          heightFt: "",
          heightIn: "",
          weight: "",
          age: member.age ?? "",
          dateOfBirth: member.dateOfBirth ?? "",
          relation: member.relation ?? "",
        };
      }
    }),

    medicalQuestionnaire: "",

    nomineFirstName: "",
    nomineLastName: "",
    nomineDob: "",
    nomineRelation: "",

    ownerFlatNumber: "",
    ownerAddress: "",
    ownerLocation: "",
    ownerPincode: "",
    ownerCity: "",
    ownerState: "",
    isRegisteredAddressSame: false,
    ownerFlatNumberReg: "",
    ownerAddressReg: "",
    ownerLocationReg: "",
    ownerPincodeReg: "",
    ownerCityReg: "",
    ownerStateReg: "",
  };

  const formik = useFormik({
    initialValues: initialData,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      if (!isLastStep) {
        if (isFirstStep) {
          if (formContext?.singleQuotesData?.insuranceCompany === "Reliance General Insurance Co. Ltd") {
            // kycveryfy()
          } else {
            return next()
          }
        } else {
          return next();
        }
      }
      if (isLastStep) {
      }
    }
  });

  const titleArray = [
    "Add-ons and rider selection",
    "Required information for KYC verification",
    "Contact information of the proposer",
    "Family details of the proposer",
    "Person who will receive the benefit in case of death of proposer",
    "Address details of the proposer",
    "Payment Process",
  ];

  const pages = [
    <RiderSelection {...props} formik={formik} />,
    <KYCForm {...props} formik={formik} />,
    <ContactInfo {...props} formik={formik} />,
    <MemberDetailis {...props} formik={formik} />,
    <MedicalQuestionnaire {...props} formik={formik} />,
    <Benefits {...props} formik={formik} />, //Nominee
    <AddressDetails {...props} formik={formik} />,
    <PaymentForm {...props} formik={formik} />,
  ];

  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    UseMultistepForm(pages);

  useEffect(() => {
    formik.setFieldValue("currentStepIndex", currentStepIndex);
  }, [currentStepIndex]);

  return (
    <div className="forms-wrap">
      <div className="form-title">
        <p className="form-sub-title">{titleArray[currentStepIndex]}</p>
        <p className="page-nuber">
          ({currentStepIndex + 1} / {steps.length})
        </p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        {step}

        <div
          style={{
            display: "flex",
            gap: ".5rem",
            justifyContent: "flex-end",
            right: "10px",
            bottom: "20px",
          }}
          className="btn-spinner"
        >
          {!isFirstStep && (
            <Button onClick={back} className=" back-btn" disabled={formContext.loaderStatus}>
              Back
            </Button>
          )}
          < Button type="submit" className=" primary-btn" disabled={formContext.loaderStatus}>
            {isLastStep ? "Finish" : "Next"}{formContext.loaderStatus && formContext.spinner}
          </Button>
        </div>
        {/* Error Modal */}
        <VerticallyCenteredModal
          show={errorModalShow}
          onHide={() => setErrorModalShow(false)}
          heading="Error"
        >
          <p>{errorMessage}</p>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
            <Button className=" back-btn" onClick={() => setErrorModalShow(false)}>
              Cancel
            </Button>
            {/* You can add any logic or handle actions needed for the "OK" button */}
            <Button className=" primary-btn" onClick={() => setErrorModalShow(false)}>
              OK
            </Button>
          </div>
        </VerticallyCenteredModal>
      </form >
      <>
        <SuccessModal show={modalShow} onHide={() => setModalShow(false)} />

        {/* Iframe value popup */}
        <VerticallyCenteredModal
          show={iframeStatus}
          onHide={() => {
            // onIframeHide()
          }}
          heading="This step is mandatory to download the policy."
        >
          <div>
            <iframe
              src={iframeUrl}
              title="Please fill all data"
              width={1000}
              height={700}

            ></iframe>
          </div>
        </VerticallyCenteredModal>
        {/* for popup kyc */}
        <VerticallyCenteredModal
          show={kycPopUpStatus}
          onHide={() => {
            // onIframeHide()
          }}
          heading="This step is mandatory to download the policy."
        >
          <div className="kyc-failed-popup">
            <div className="text-center mt-2">
              <BiSolidError className="failed-icon" />
              <p className="mt-2" style={{ color: 'red' }}>{errorMessage}</p>
              <div className="text-center mt-3 mb-3 text-info-label">Click on below Button for KYC verification</div>
              <div className=" footer-btn-wrap">
                {/* <Button className="back-btn" onClick={() => { setkycPopUpStatus(false); return next() }}>Skip</Button> */}
                <Button className="primary-btn">Proceed for KYC </Button>
              </div>
            </div>
          </div>
        </VerticallyCenteredModal>
      </>
    </div >
  );
};

function SuccessModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="success-modal"
    >
      <div className="check-icon-wrap">
        <SVG src={Done} alt="" style={{ width: "80px" }} />
      </div>
      <h3 className="text-center mt-3">Done</h3>
      <Modal.Body className="p-0">
        <p className="text-center mt-0">register successfully!</p>
      </Modal.Body>
      <Modal.Footer className="modalfooter">
        <Button className="primary-btn" onClick={props.onHide}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MultistepProposal;
