import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import * as XLSX from "xlsx";
import { Modal, Row, Col, ToastContainer } from "react-bootstrap";

import Offcanvas from "react-bootstrap/Offcanvas";
// import Logo from "../../images/logo.png";
import Logo from "../../components/style/assets/img/logo/logo.png";
import carInsurance from "../../components/style/assets/img/category/car-insurance.png";
import bikeInsurance from "../../components/style/assets/img/category/bike-insurance.png";
import healthInsurance from "../../components/style/assets/img/category/health-insurance.png";
import termLife from "../../components/style/assets/img/category/term-life.png";
import travelInsurance from "../../components/style/assets/img/category/travel-insurance.png";
import business from "../../components/style/assets/img/category/Business-1.png";
import secureYourFuture from "../../components/style/assets/img/new-product/Secure-Your-Future.jpg";
import secure from "../../components/style/assets/img/new-product/secure.jpg";
import secureOne from "../../components/style/assets/img/new-product/secure-1.jpg";

import { NavLink, useLocation } from "react-router-dom";
import {
  routesArrayForAgent,
  routesArrayForAgentLobMotor,
  routesArrayForAgentLobLife,
  routesArrayForAgentLobHealth,
  routesArrayForPosp,
} from "../../routing/RoutingPaths";
import { routesArrayForAdmin } from "../../routing/RoutingPaths";
import {
  routesArrayForCustomer,
  routesArrayForCustomerAfterLogin,
} from "../../routing/RoutingPaths";
import { routesArrayForManager } from "../../routing/RoutingPaths";
import { decryptData } from "../../services/auth-service";
import AdminService from "../../services/admin.service";
import UseFormContext from "../../context/UseFormContext";
import AdhocService from "../../services/adhoc.service";
import toast from "react-hot-toast";

var CryptoJS = require("crypto-js");

function Header() {
  const location = useLocation();
  const [pathName, setPathName] = useState();
  const [user, setUser] = useState(null);
  const [role, setRole] = useState("");
  const [routesArray, setRoutesArray] = useState(routesArrayForCustomer);
  const [routesArrayTemp, setRoutesArrayTemp] = useState([]);
  const rerendered = useRef(false); // useRef to track if component has been rerendered
  const formContext = UseFormContext();

  const [menuVisible, setMenuVisible] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  // Function to toggle the menu visibility
  const toggleMenu = () => {
    setMenuVisible((prevState) => !prevState);
  };

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  useEffect(() => {
    const decryptUser = async () => {
      const encryptedUser = localStorage.getItem("user");
      const normalUser = localStorage.getItem("normalUser");
      if (normalUser) {
        setUser({ ...user, role: "CUSTOMER" });
      } else if (encryptedUser) {
        try {
         
          const decryptedUser = await decryptData(encryptedUser);
          setRole(decryptedUser);
          
          await AdminService.getUserInfoByEmail({
            email: decryptedUser.email,
          }).then(async (res) => {
            if (res.data?.data?.phoneNumber) {
              
              const phoneNumber = res.data?.data?.phoneNumber;
              const encryptNo = CryptoJS.AES.encrypt(
                phoneNumber,
                "dynamipass"
              ).toString();
              sessionStorage.setItem("userLoggin", encryptNo);
              sessionStorage.setItem("callerID", res.data?.data?.callerId);
              localStorage.setItem("ownerPhoneNumber1", phoneNumber);
            }
            if (res.data?.data?.id) {
              const id = res.data?.data?.id;
              sessionStorage.setItem("id", id);
            }
            if (res.data?.data?.agencyId) {
              const agencyId = res.data?.data?.agencyId;
              sessionStorage.setItem("agencyId", agencyId);
            }
            if (res.data?.data?.branchId) {
              const branchId = res.data?.data?.branchId;
              sessionStorage.setItem("branchId", branchId);
            }

            try {
              // Fetch branch data
              const branchResponse = await AdminService.getBranchByID({
                id: res?.data?.data?.branchId,
              });
              const branchData = branchResponse?.data?.data;

              // Fetch agency data
              const agencyResponse = await AdminService.getAgencyById({
                id: res?.data?.data?.agencyId,
              });
              const agencyData = agencyResponse?.data?.data;

              // Extract LOBs from branch and agency, assuming they are strings like "[HEALTH, LIFE]"
              const branchLobs = branchData?.lineOfBusiness
                .substring(1, branchData?.lineOfBusiness.length - 1)
                .split(", ");
              const agencyLobs = agencyData?.lineOfBusiness
                .substring(1, agencyData?.lineOfBusiness.length - 1)
                .split(", ");

              // Find the intersection of branchLobs and agencyLobs
              const combinedLobs = branchLobs.filter((lob) =>
                agencyLobs?.includes(lob)
              );
              localStorage.setItem("LOB", combinedLobs);
              
              // Get routes based on combined LOBs
              const concatenatedRoutes = getRoutesForLOBs(combinedLobs);

              // Set the routes array
              setRoutesArrayTemp(concatenatedRoutes);
            } catch (error) {
              console.error(error);
            }
          });

          setUser(decryptedUser);
        } catch (error) {
          console.error("Error decrypting user:", error);
        }
      } else {
      }
    };
    decryptUser();
  }, []);

  const getRoutesForLOBs = (lobs) => {
    let concatenatedRoutes = [];
    lobs.forEach((lob) => {
      switch (lob) {
        case "HEALTH":
          concatenatedRoutes = [
            ...concatenatedRoutes,
            ...routesArrayForAgentLobHealth,
          ];
          break;
        case "LIFE":
          concatenatedRoutes = [
            ...concatenatedRoutes,
            ...routesArrayForAgentLobLife,
          ];
          break;
        case "MOTOR":
          concatenatedRoutes = [
            ...concatenatedRoutes,
            ...routesArrayForAgentLobMotor,
          ];
          break;
        default:
          break;
      }
    });
    return concatenatedRoutes;
  };

  useEffect(() => {
    const setRoutes = async () => {
      
      if (location.pathname === "/myaccount" && !user) {
        setRoutesArray(routesArrayForCustomerAfterLogin);
      } else {
        switch (user?.role) {
          case "AGENT":
            let arr = [...routesArrayTemp, ...routesArrayForAgent];
            setRoutesArray(arr);
            break;
          case "ADMIN":
            setRoutesArray(routesArrayForAdmin);
            break;
          case "MANAGER":
            setRoutesArray(routesArrayForManager);
            break;
          case "CUSTOMER":
            setRoutesArray(routesArrayForCustomerAfterLogin);
            break;
          case "POSP":
            setRoutesArray(routesArrayForPosp);
            break;
          default:
            setRoutesArray(routesArrayForCustomer);
            break;
        }
      }
    };

    setRoutes();

    // Rerender the component only once after the switch is executed
    if (!rerendered.current) {
      rerendered.current = true;
      // Trigger a rerender by updating state
      setUser(user); // This will trigger a rerender but won't change the user state
    }
  }, [user]);

  const isLoginPage = () => {
    return pathName === "/Login";
  };

  const formatTimestampToDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toISOString().split("T")[0]; // Format: YYYY-MM-DD
  };

  const exportPdf = async () => {
    await AdhocService.getLead()
      .then((res) => {
        const data = res?.data?.data;
        
        if (data) {
          const formattedData = data.map((item) => ({
            id: item.id,
            module: item.module,
            customerName: item.customerName,
            mobileNumber: item.mobileNumber,
            emailID: item.emailID,
            dateOfBirth: item.dateOfBirth,
            sumInsured: item.sumInsured,
            location: item.location || "N/A", // Handle null values
            createdAt: formatTimestampToDate(item.createdAt),
            updatedAt: formatTimestampToDate(item.updatedAt),
          }));
          

          // Convert to worksheet
          const worksheet = XLSX.utils.json_to_sheet(formattedData);

          // Apply bold style to header row
          const headerKeys = Object.keys(formattedData[0]);
          headerKeys.forEach((key, index) => {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index }); // r: row, c: column
            if (!worksheet[cellAddress]) return;
            worksheet[cellAddress].s = {
              font: {
                bold: true,
              },
            };
          });

          // Create a new workbook
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

          // Write the workbook as a binary string
          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });

          // Create a Blob from the buffer
          const blob = new Blob([excelBuffer], {
            type: "application/octet-stream",
          });

          // Create a link to download the file
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "CustomerData.xlsx";

          // Append link to the body
          document.body.appendChild(link);

          // Trigger the download
          link.click();

          // Remove the link after the download
          document.body.removeChild(link);
        } else {
          toast.error("Error Occurred, Please try again later.");
        }
      })
      .catch((error) => {
        toast.error("Error Occured, Please try again later.");
        
      });
  };

  return (
    <>
      {/* <ToastContainer/> */}
      <Container fluid>
        <Row>
          <Col>
            <div class="bottom-header py-[20px] max-[991px]:py-[15px]">
              <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
                <div class="flex flex-wrap w-full">
                  <div class="w-full px-[12px]">
                    <div class="inner-bottom-header flex justify-between max-[767px]:flex-col">
                      <div class="cols bb-logo-detail flex max-[767px]:justify-between">
                        <div class="header-logo flex items-center max-[575px]:justify-between">
                          <a href="/" target="_self" rel="noopener noreferrer">
                            <img
                              className="light w-[150px] max-[991px]:w-[115px] block"
                              src={Logo}
                            />
                          </a>
                        </div>
                        <a
                          href="javascript:void(0)"
                          onClick={handleShow}
                          class="bb-sidebar-toggle bb-category-toggle min-[991px]:hidden max-[991px]:flex max-[991px]:items-center max-[991px]:ml-[20px] max-[991px]:border-[1px] max-[991px]:border-solid max-[991px]:border-[#eee] max-[991px]:w-[40px] max-[991px]:h-[40px] max-[991px]:rounded-[15px] justify-center transition-all duration-[0.3s] ease-in-out font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                        >
                          <svg
                            class="svg-icon h-[30px] w-[30px] max-[991px]:w-[22px] max-[991px]:h-[22px]"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              class="fill-[#6c7fd8]"
                              d="M384 928H192a96 96 0 0 1-96-96V640a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v192a96 96 0 0 1-96 96zM192 608a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h192a32 32 0 0 0 32-32V640a32 32 0 0 0-32-32H192zM784 928H640a96 96 0 0 1-96-96V640a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v144a32 32 0 0 1-64 0V640a32 32 0 0 0-32-32H640a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h144a32 32 0 0 1 0 64zM384 480H192a96 96 0 0 1-96-96V192a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v192a96 96 0 0 1-96 96zM192 160a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h192a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32H192zM832 480H640a96 96 0 0 1-96-96V192a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v192a96 96 0 0 1-96 96zM640 160a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h192a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32H640z"
                            />
                          </svg>
                        </a>
                      </div>
                      <div class="cols bb-icons flex justify-end">
                        <div class="bb-flex-justify max-[575px]:flex max-[575px]:justify-between">
                          <div class="bb-header-buttons h-full flex justify-end items-center">
                            {/* <div class="bb-acc-drop relative">
                              <a href="javascript:void(0)" class="bb-header-btn bb-header-user dropdown-toggle bb-user-toggle transition-all duration-[0.3s] ease-in-out relative flex w-[auto] items-center whitespace-nowrap ml-[30px] max-[1199px]:ml-[20px] max-[767px]:ml-[0]" title="Account">
                                <div class="header-icon relative flex">
                                  <svg class="svg-icon w-[30px] h-[30px] max-[1199px]:w-[25px] max-[1199px]:h-[25px] max-[991px]:w-[22px] max-[991px]:h-[22px]" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <path class="fill-[#6c7fd8]" d="M512.476 648.247c-170.169 0-308.118-136.411-308.118-304.681 0-168.271 137.949-304.681 308.118-304.681 170.169 0 308.119 136.411 308.119 304.681C820.594 511.837 682.645 648.247 512.476 648.247L512.476 648.247zM512.476 100.186c-135.713 0-246.12 109.178-246.12 243.381 0 134.202 110.407 243.381 246.12 243.381 135.719 0 246.126-109.179 246.126-243.381C758.602 209.364 648.195 100.186 512.476 100.186L512.476 100.186zM935.867 985.115l-26.164 0c-9.648 0-17.779-6.941-19.384-16.35-2.646-15.426-6.277-30.52-11.142-44.95-24.769-87.686-81.337-164.13-159.104-214.266-63.232 35.203-134.235 53.64-207.597 53.64-73.555 0-144.73-18.537-208.084-53.922-78 50.131-134.75 126.68-159.564 214.549 0 0-4.893 18.172-11.795 46.4-2.136 8.723-10.035 14.9-19.112 14.9L88.133 985.116c-9.415 0-16.693-8.214-15.47-17.452C91.698 824.084 181.099 702.474 305.51 637.615c58.682 40.472 129.996 64.267 206.966 64.267 76.799 0 147.968-23.684 206.584-63.991 124.123 64.932 213.281 186.403 232.277 329.772C952.56 976.901 945.287 985.115 935.867 985.115L935.867 985.115z" />
                                  </svg>
                                </div>
                                <div class="bb-btn-desc flex flex-col ml-[10px] max-[1199px]:hidden">
                                  <span class="bb-btn-title font-Poppins transition-all duration-[0.3s] ease-in-out text-[12px] leading-[1] text-[#3d4750] mb-[4px] tracking-[0.6px] capitalize font-medium whitespace-nowrap">Account</span>
                                  <span class="bb-btn-stitle font-Poppins transition-all duration-[0.3s] ease-in-out text-[14px] leading-[16px] font-semibold text-[#3d4750]  tracking-[0.03rem] whitespace-nowrap text-left">Login</span>
                                </div>
                              </a>




                              <ul class="bb-dropdown-menu min-w-[150px] py-[10px] px-[5px] transition-all duration-[0.3s] ease-in-out mt-[25px] absolute z-[16] text-left opacity-[0] right-[auto] bg-[#fff] border-[1px] border-solid border-[#eee] block rounded-[10px]">
                                <li class="py-[4px] px-[15px] m-[0] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"><a class="dropdown-item transition-all duration-[0.3s] ease-in-out font-Poppins text-[13px] hover:text-[#6c7fd8] leading-[22px] block w-full font-normal tracking-[0.03rem]" href="#">As a Customer</a></li>

                                <li class="py-[4px] px-[15px] m-[0] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"><a class="dropdown-item transition-all duration-[0.3s] ease-in-out font-Poppins text-[13px] hover:text-[#6c7fd8] leading-[22px] block w-full font-normal tracking-[0.03rem]" href="#">As an Agent</a></li>
                              </ul>
                            </div> */}

                            <div class="bb-acc-drop relative">
                              <a
                                href="/myaccount"
                                class="bb-header-btn bb-header-user transition-all duration-[0.3s] ease-in-out relative flex w-[auto] items-center whitespace-nowrap ml-[30px] max-[1199px]:ml-[20px] max-[767px]:ml-[0]"
                                title="Account"
                              >
                                <div class="header-icon relative flex">
                                  <svg
                                    class="svg-icon w-[30px] h-[30px] max-[1199px]:w-[25px] max-[1199px]:h-[25px] max-[991px]:w-[22px] max-[991px]:h-[22px]"
                                    viewBox="0 0 1024 1024"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      class="fill-[#6c7fd8]"
                                      d="M512.476 648.247c-170.169 0-308.118-136.411-308.118-304.681 0-168.271 137.949-304.681 308.118-304.681 170.169 0 308.119 136.411 308.119 304.681C820.594 511.837 682.645 648.247 512.476 648.247L512.476 648.247zM512.476 100.186c-135.713 0-246.12 109.178-246.12 243.381 0 134.202 110.407 243.381 246.12 243.381 135.719 0 246.126-109.179 246.126-243.381C758.602 209.364 648.195 100.186 512.476 100.186L512.476 100.186zM935.867 985.115l-26.164 0c-9.648 0-17.779-6.941-19.384-16.35-2.646-15.426-6.277-30.52-11.142-44.95-24.769-87.686-81.337-164.13-159.104-214.266-63.232 35.203-134.235 53.64-207.597 53.64-73.555 0-144.73-18.537-208.084-53.922-78 50.131-134.75 126.68-159.564 214.549 0 0-4.893 18.172-11.795 46.4-2.136 8.723-10.035 14.9-19.112 14.9L88.133 985.116c-9.415 0-16.693-8.214-15.47-17.452C91.698 824.084 181.099 702.474 305.51 637.615c58.682 40.472 129.996 64.267 206.966 64.267 76.799 0 147.968-23.684 206.584-63.991 124.123 64.932 213.281 186.403 232.277 329.772C952.56 976.901 945.287 985.115 935.867 985.115L935.867 985.115z"
                                    />
                                  </svg>
                                </div>
                                <div class="bb-btn-desc flex flex-col ml-[10px] max-[1199px]:hidden">
                                  {/* <span class="bb-btn-title font-Poppins transition-all duration-[0.3s] ease-in-out text-[12px] leading-[1] text-[#3d4750] mb-[4px] tracking-[0.6px] capitalize font-medium whitespace-nowrap">
                                    Account
                                  </span> */}
                                  <span class="bb-btn-stitle font-Poppins transition-all duration-[0.3s] ease-in-out text-[14px] leading-[16px] font-semibold text-[#3d4750] tracking-[0.03rem] whitespace-nowrap text-left">
                                    My Account
                                  </span>
                                </div>
                              </a>
                            </div>

                            {/* <a href="javascript:void(0)" class="bb-toggle-menu hidden max-[991px]:flex max-[991px]:ml-[20px]"
                         onClick={toggleMenu} >
                          <div class="header-icon">
                            <i class="ri-menu-3-fill text-[22px] text-[#6c7fd8]"></i>
                          </div>
                        </a> */}

                            {/* {menuVisible && (
        <div id="bb-mobile-menu" className="bb-mobile-menu">
          <button
            type="button"
            className="bb-close-menu relative border-[0] text-[30px] leading-[1] text-[#ff0000] bg-transparent"
            onClick={toggleMenu}
          >
            ×
          </button>
        </div>
      )} */}

                            <>
                              {["lg"].map((expand) => (
                                <Navbar
                                  key={expand}
                                  expand={expand}
                                  className="navbar-mobile-only"
                                >
                                  <Navbar.Toggle
                                    aria-controls={`offcanvasNavbar-expand-${expand}`}
                                    class="bb-toggle-menu"
                                    style={{ outline: 0, border: 0 }}
                                  >
                                    <div class="header-icon">
                                      <i class="ri-menu-3-fill text-[22px] text-[#6c7fd8]"></i>
                                    </div>
                                  </Navbar.Toggle>

                                  <Navbar.Offcanvas
                                    id={`offcanvasNavbar-expand-${expand}`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                    placement="start"
                                  >
                                    <Offcanvas.Header closeButton>
                                      <Offcanvas.Title
                                        id={`offcanvasNavbarLabel-expand-${expand}`}
                                      >
                                        <span className="menu_title font-Poppins flex items-center text-[16px] text-[#3d4750] font-semibold leading-[26px] tracking-[0.02rem]">
                                          My Menu
                                        </span>
                                      </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body className="pt-0">
                                      {!isLoginPage() && (
                                        <div className="">
                                          <Nav
                                            style={{ gap: 0 }}
                                            className="justify-content-end flex-grow-1 pe-3"
                                          >
                                            {routesArray?.map((data) => (
                                              <NavLink
                                                className={`font-Poppins nav-text transition-all duration-[0.3s] ease-in-out mb-[12px] p-[12px] block font-Poppins capitalize text-[#686e7d] border-[1px] border-solid border-[#eee] rounded-[10px] text-[15px] font-medium leading-[28px] tracking-[0.03rem] ${
                                                  formContext
                                                    .carbikeformikValues
                                                    ?.formtype === data?.type &&
                                                  (pathName === "/quotes" ||
                                                    pathName === "/proposal" ||
                                                    pathName === "/quotes/1" ||
                                                    pathName === "/proposal/2")
                                                    ? "active"
                                                    : ""
                                                }`}
                                                to={data.path}
                                                key={data.heading}
                                                onClick={() => {
                                                  if (
                                                    data?.type === "myaccount"
                                                  ) {
                                                    formContext.setPages(
                                                      "Myprofile"
                                                    );
                                                  } else if (
                                                    data?.type === "agencylogin"
                                                  ) {
                                                    formContext.setPages(
                                                      "Agencylogin"
                                                    );
                                                  }
                                                }}
                                              >
                                                {data.heading}
                                              </NavLink>
                                            ))}
                                          </Nav>
                                        </div>
                                      )}
                                      <div class="header-res-lan-curr">
                                        <div class="header-res-social mt-[15px]">
                                          <div class="header-top-social">
                                            <ul class="flex flex-row justify-center mb-[0]">
                                              <li class="list-inline-item w-[30px] h-[30px] flex items-center justify-center bg-[#3d4750] rounded-[10px] mr-[.5rem]">
                                                <a
                                                  href="https://www.facebook.com/navnitinsurancebroking"
                                                  class="transition-all duration-[0.3s] ease-in-out"
                                                >
                                                  <i class="ri-facebook-fill text-[#fff] text-[15px]"></i>
                                                </a>
                                              </li>
                                              <li class="list-inline-item w-[30px] h-[30px] flex items-center justify-center bg-[#3d4750] rounded-[10px] mr-[.5rem]">
                                                <a
                                                  href="https://www.instagram.com/navnitinsurancebroking/"
                                                  class="transition-all duration-[0.3s] ease-in-out"
                                                >
                                                  <i class="ri-instagram-line text-[#fff] text-[15px]"></i>
                                                </a>
                                              </li>
                                              <li class="list-inline-item w-[30px] h-[30px] flex items-center justify-center bg-[#3d4750] rounded-[10px]">
                                                <a
                                                  href="https://www.linkedin.com/company/88037881/admin/"
                                                  class="transition-all duration-[0.3s] ease-in-out"
                                                >
                                                  <i class="ri-linkedin-fill text-[#fff] text-[15px]"></i>
                                                </a>
                                              </li>
                                              &nbsp;&nbsp;
                                              <li class="list-inline-item w-[30px] h-[30px] flex items-center justify-center bg-[#3d4750] rounded-[10px] mr-[.5rem]">
                                                <a
                                                  href="https://twitter.com/InsuranceNavnit"
                                                  class="transition-all duration-[0.3s] ease-in-out"
                                                >
                                                  <i class="ri-twitter-x-line text-[#fff] text-[15px]"></i>
                                                </a>
                                              </li>
                                              <li class="list-inline-item w-[30px] h-[30px] flex items-center justify-center bg-[#3d4750] rounded-[10px] mr-[.5rem]">
                                                <a
                                                  href="https://www.youtube.com/channel/UCBmAVSGr1xSmVnc4MySsr2w"
                                                  class="transition-all duration-[0.3s] ease-in-out"
                                                >
                                                  <i class="ri-youtube-fill text-[#fff] text-[15px]"></i>
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </Offcanvas.Body>
                                  </Navbar.Offcanvas>
                                </Navbar>
                              ))}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div class="bb-main-menu-desk bg-[#fff] py-[5px] border-t-[1px] border-solid border-[#eee] max-[991px]:hidden">
        <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
          <div class="flex flex-wrap w-full">
            <div class="w-full px-[12px]">
              <div class="bb-inner-menu-desk flex max-[1199px]:relative max-[991px]:justify-between align-items-center">
                <a
                  href="javascript:void(0)"
                  onClick={handleShow}
                  class="bb-header-btn bb-sidebar-toggle bb-category-toggle transition-all duration-[0.3s] ease-in-out h-[45px] w-[45px] mr-[30px] p-[8px] flex items-center justify-center bg-[#fff] border-[1px] border-solid border-[#eee] rounded-[10px] relative max-[767px]:m-[0] max-[575px]:hidden"
                >
                  <svg
                    class="svg-icon w-[25px] h-[25px]"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="fill-[#6c7fd8]"
                      d="M384 928H192a96 96 0 0 1-96-96V640a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v192a96 96 0 0 1-96 96zM192 608a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h192a32 32 0 0 0 32-32V640a32 32 0 0 0-32-32H192zM784 928H640a96 96 0 0 1-96-96V640a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v144a32 32 0 0 1-64 0V640a32 32 0 0 0-32-32H640a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h144a32 32 0 0 1 0 64zM384 480H192a96 96 0 0 1-96-96V192a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v192a96 96 0 0 1-96 96zM192 160a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h192a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32H192zM832 480H640a96 96 0 0 1-96-96V192a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v192a96 96 0 0 1-96 96zM640 160a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h192a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32H640z"
                    />
                  </svg>
                </a>
                <button
                  class="navbar-toggler shadow-none hidden"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i class="ri-menu-2-line"></i>
                </button>
                <div
                  class="bb-main-menu relative flex flex-[auto] justify-start max-[991px]:hidden"
                  id="navbarSupportedContent"
                >
                  {!isLoginPage() && (
                    <div className="pages-tab">
                      <Navbar id="basic-navbar-nav">
                        <Nav
                          className="me-auto flex-wrap flex-row justify-start"
                          style={{
                            gap: {
                              rowGap: "20px",
                            },
                          }}
                        >
                          {routesArray?.map((data) => (
                            <NavLink
                              style={{
                                gap: {
                                  rowGap: "20px",
                                },
                              }}
                              className={`nav-link ps-0 pe-0 p-0 font-Poppins leading-[28px] text-[15px] font-medium text-[#3d4750] tracking-[0.03rem] block nav-text ${
                                formContext.carbikeformikValues?.formtype ===
                                  data?.type &&
                                (pathName === "/quotes" ||
                                  pathName === "/proposal" ||
                                  pathName === "/quotes/1" ||
                                  pathName === "/proposal/2")
                                  ? "active"
                                  : ""
                              }`}
                              to={data.path}
                              key={data.heading}
                              onClick={() => {
                                if (data?.type === "myaccount") {
                                  formContext.setPages("Myprofile");
                                } else if (data?.type === "agencylogin") {
                                  formContext.setPages("Agencylogin");
                                }
                              }}
                            >
                              {data.heading}
                            </NavLink>
                          ))}
                        </Nav>
                      </Navbar>
                    </div>
                  )}
                </div>
               {(user?.role==="ADMIN") &&<button
                  onClick={() => {
                    exportPdf();
                  }}
                >
                  Export Lead PDF
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="bb-main-menu-desk bg-[#fff] py-[5px] border-t-[1px] border-solid border-[#eee] max-[991px]:hidden">
        <div expand="lg" className="header-wrap p-0">


          <Container className="flex flex-wrap justify-start relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">

            <div class="bb-main-menu-desk bg-[#fff]  max-[991px]:hidden">
              <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
                <div class="flex flex-wrap w-full">
                  <div class="w-full">
                    <div class="bb-inner-menu-desk flex max-[1199px]:relative max-[991px]:justify-between">


                      <a href="javascript:void(0)" onClick={handleShow} class="bb-header-btn bb-sidebar-toggle bb-category-toggle transition-all duration-[0.3s] ease-in-out h-[45px] w-[45px] mr-[30px] p-[8px] flex items-center justify-center bg-[#fff] border-[1px] border-solid border-[#eee] rounded-[10px] relative max-[767px]:m-[0] max-[575px]:hidden">
                        <svg class="svg-icon w-[25px] h-[25px]" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                          <path class="fill-[#6c7fd8]" d="M384 928H192a96 96 0 0 1-96-96V640a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v192a96 96 0 0 1-96 96zM192 608a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h192a32 32 0 0 0 32-32V640a32 32 0 0 0-32-32H192zM784 928H640a96 96 0 0 1-96-96V640a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v144a32 32 0 0 1-64 0V640a32 32 0 0 0-32-32H640a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h144a32 32 0 0 1 0 64zM384 480H192a96 96 0 0 1-96-96V192a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v192a96 96 0 0 1-96 96zM192 160a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h192a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32H192zM832 480H640a96 96 0 0 1-96-96V192a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v192a96 96 0 0 1-96 96zM640 160a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h192a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32H640z" />
                        </svg>
                      </a>

                      {!isLoginPage() && (
                        <div className="pages-tab">
                          <Navbar id="basic-navbar-nav">
                            <Nav
                              className="me-auto"
                              style={{
                                gap: {
                                  rowGap: "20px",
                                },
                              }}
                            >
                              {routesArray?.map((data) => (
                                <NavLink
                                  style={{
                                    gap: {
                                      rowGap: "20px",
                                    },
                                  }}
                                  className={`nav-text ${formContext.carbikeformikValues?.formtype ===
                                    data?.type &&
                                    (pathName === "/quotes" ||
                                      pathName === "/proposal" ||
                                      pathName === "/quotes/1" ||
                                      pathName === "/proposal/2")
                                    ? "active"
                                    : ""
                                    }`}
                                  to={data.path}
                                  key={data.heading}
                                  onClick={() => {
                                    if (data?.type === "myaccount") {
                                      formContext.setPages("Myprofile");
                                    } else if (data?.type === "agencylogin") {
                                      formContext.setPages("Agencylogin");
                                    }
                                  }}
                                >
                                  <strong>{data.heading}</strong>
                                </NavLink>
                              ))}
                            </Nav>
                          </Navbar>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div> */}

      {/* Modal */}
      <Modal
        scrollable
        size="xl"
        show={showModal}
        onHide={handleClose}
        centered
        className="rounded-[30px] large-popup"
        style={{ overflow: "hidden", borderRadius: "30px" }}
      >
        {/* <Modal.Header closeButton className="p-0 border-0">
      </Modal.Header> */}
        <button
          type="button"
          onClick={handleClose}
          class="bb-category-close transition-all duration-[0.3s] ease-in-out w-[16px] h-[20px] absolute top-[-3px] max-[577px]:top-[-3px] right-[30px] bg-[#e04e4eb3] rounded-[10px] cursor-pointer hover:bg-[#e04e4e] hover:[top-[-5px]]"
          title="Close"
        ></button>

        <Modal.Body className="p-0 rounded-[30px] relative">
          <div class="bb-category-sidebar transition-all duration-[0.3s] ease-in-out w-full h-full">
            <div class="category-sidebar w-[calc(100%-0px)] max-[1199px]:h-[calc(100vh-60px)] max-w-[1200px] my-[15px] mx-[auto]  text-[14px] font-normal transition-all duration-[0.5s] ease-in-out delay-[0s] bg-[#fff]  rounded-[30px]">
              <div class="w-full mx-auto">
                <div class="flex flex-wrap w-full mb-[-24px]">
                  <div class="w-full px-[12px]">
                    <div class="bb-category-tags mb-[24px]">
                      <div class="sub-title mb-[20px] flex justify-between">
                        <h4 class="font-quicksand tracking-[0.03rem] leading-[1.2] text-[20px] font-bold text-[#3d4750] capitalize">
                          Explore Policies
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="w-full">
                    <div class="flex flex-wrap w-full">
                      <div class="min-[1200px]:w-[16.66%] min-[768px]:w-[33.33%] min-[576px]:w-[50%] w-full px-[12px] mb-[24px]">
                        <div class="bb-category-box p-[30px] rounded-[20px] flex flex-col items-center text-center max-[1399px]:p-[20px] category-items-1 bg-[#fef1f1]">
                          <div class="category-image mb-[12px]">
                            <img
                              src={carInsurance}
                              alt="category"
                              class="w-[50px] h-[50px] max-[1399px]:h-[65px] max-[1399px]:w-[65px] max-[1199px]:h-[50px] max-[1199px]:w-[50px]"
                            />
                          </div>
                          <div class="category-sub-contact">
                            <h5 class="mb-[2px] text-[16px] font-quicksand text-[#3d4750] font-semibold tracking-[0.03rem] leading-[1.2]">
                              <a
                                href="/car"
                                class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize"
                              >
                                Car Insurance
                              </a>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div class="min-[1200px]:w-[16.66%] min-[768px]:w-[33.33%] min-[576px]:w-[50%] w-full px-[12px] mb-[24px]">
                        <div class="bb-category-box p-[30px] rounded-[20px] flex flex-col items-center text-center max-[1399px]:p-[20px] category-items-2 bg-[#e1fcf2]">
                          <div class="category-image mb-[12px]">
                            <img
                              src={bikeInsurance}
                              alt="category"
                              class="w-[50px] h-[50px] max-[1399px]:h-[65px] max-[1399px]:w-[65px] max-[1199px]:h-[50px] max-[1199px]:w-[50px]"
                            />
                          </div>
                          <div class="category-sub-contact">
                            <h5 class="mb-[2px] text-[16px] font-quicksand text-[#3d4750] font-semibold tracking-[0.03rem] leading-[1.2]">
                              <a
                                href="/bike"
                                class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize"
                              >
                                Bike Insurance
                              </a>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div class="min-[1200px]:w-[16.66%] min-[768px]:w-[33.33%] min-[576px]:w-[50%] w-full px-[12px] mb-[24px]">
                        <div class="bb-category-box p-[30px] rounded-[20px] flex flex-col items-center text-center max-[1399px]:p-[20px] category-items-3 bg-[#f4f1fe]">
                          <div class="category-image mb-[12px]">
                            <img
                              src={healthInsurance}
                              alt="category"
                              class="w-[50px] h-[50px] max-[1399px]:h-[65px] max-[1399px]:w-[65px] max-[1199px]:h-[50px] max-[1199px]:w-[50px]"
                            />
                          </div>
                          <div class="category-sub-contact">
                            <h5 class="mb-[2px] text-[16px] font-quicksand text-[#3d4750] font-semibold tracking-[0.03rem] leading-[1.2]">
                              <a
                                href="/health"
                                class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize"
                              >
                                Health Insurance
                              </a>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div class="min-[1200px]:w-[16.66%] min-[768px]:w-[33.33%] min-[576px]:w-[50%] w-full px-[12px] mb-[24px]">
                        <div class="bb-category-box p-[30px] rounded-[20px] flex flex-col items-center text-center max-[1399px]:p-[20px] category-items-4 bg-[#fbf9e4]">
                          <div class="category-image mb-[12px]">
                            <img
                              src={termLife}
                              alt="category"
                              class="w-[50px] h-[50px] max-[1399px]:h-[65px] max-[1399px]:w-[65px] max-[1199px]:h-[50px] max-[1199px]:w-[50px]"
                            />
                          </div>
                          <div class="category-sub-contact">
                            <h5 class="mb-[2px] text-[16px] font-quicksand text-[#3d4750] font-semibold tracking-[0.03rem] leading-[1.2]">
                              <a
                                href="/termlife"
                                class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize"
                              >
                                Term Life
                              </a>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div class="min-[1200px]:w-[16.66%] min-[768px]:w-[33.33%] min-[576px]:w-[50%] w-full px-[12px] mb-[24px]">
                        <div class="bb-category-box p-[30px] rounded-[20px] flex flex-col items-center text-center max-[1399px]:p-[20px] category-items-2 bg-[#e1fcf2]">
                          <div class="category-image mb-[12px]">
                            <img
                              src={travelInsurance}
                              alt="category"
                              class="w-[50px] h-[50px] max-[1399px]:h-[65px] max-[1399px]:w-[65px] max-[1199px]:h-[50px] max-[1199px]:w-[50px]"
                            />
                          </div>
                          <div class="category-sub-contact">
                            <h5 class="mb-[2px] text-[16px] font-quicksand text-[#3d4750] font-semibold tracking-[0.03rem] leading-[1.2]">
                              <a
                                href="/travel"
                                class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize"
                              >
                                Travel Insurance
                              </a>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div class="min-[1200px]:w-[16.66%] min-[768px]:w-[33.33%] min-[576px]:w-[50%] w-full px-[12px] mb-[24px]">
                        <div class="bb-category-box p-[30px] rounded-[20px] flex flex-col items-center text-center max-[1399px]:p-[20px] category-items-3 bg-[#f4f1fe]">
                          <div class="category-image mb-[12px]">
                            <img
                              src={business}
                              alt="category"
                              class="w-[50px] h-[50px] max-[1399px]:h-[65px] max-[1399px]:w-[65px] max-[1199px]:h-[50px] max-[1199px]:w-[50px]"
                            />
                          </div>
                          <div class="category-sub-contact">
                            <h5 class="mb-[2px] text-[16px] font-quicksand text-[#3d4750] font-semibold tracking-[0.03rem] leading-[1.2]">
                              <a
                                href="business.html"
                                class="font-Poppins text-[16px] font-medium leading-[1.2] tracking-[0.03rem] text-[#3d4750] capitalize"
                              >
                                Business
                              </a>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="w-full">
                    <div class="flex flex-wrap w-full">
                      <div class="w-full px-[12px]">
                        <div class="sub-title mb-[20px] flex justify-between">
                          <h4 class="font-quicksand tracking-[0.03rem] leading-[1.2] text-[20px] font-bold text-[#3d4750] capitalize">
                            Present Offers
                          </h4>
                        </div>
                      </div>

                      <div class="min-[992px]:w-[33.33%] min-[576px]:w-[50%] w-full px-[12px] mb-[24px]">
                        <div class="bb-category-cart p-[15px] overflow-hidden bg-[#f8f8fb] border-[1px] border-solid border-[#eee] rounded-[10px] flex max-[767px]:flex-col">
                          <a
                            href="javascript:void(0)"
                            class="pro-img mr-[12px] max-[767px]:mb-[15px] max-[767px]:mr-[0]"
                          >
                            <img
                              src={secureYourFuture}
                              alt="new-product-1"
                              class="w-[80px] rounded-[10px] border-[1px] border-solid border-[#eee] max-[767px]:w-full"
                            />
                          </a>
                          <div class="side-contact flex flex-col">
                            <h4 class="bb-pro-title text-[15px]">
                              <a
                                href=""
                                class="transition-all duration-[0.3s] ease-in-out flex font-Poppins text-[15px] leading-[28px] tracking-[0.03rem] font-medium text-[#3d4750]"
                              >
                                Secure Your Future!
                              </a>
                            </h4>
                            <p class="font-Poppins font-light tracking-[0.03rem] mb-[8px] text-[14px] leading-[22px] text-[#686e7d]">
                              Become a Certified POSP
                            </p>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div class="min-[992px]:w-[33.33%] min-[576px]:w-[50%] w-full px-[12px] mb-[24px]">
                        <div class="bb-category-cart p-[15px] overflow-hidden bg-[#f8f8fb] border-[1px] border-solid border-[#eee] rounded-[10px] flex max-[767px]:flex-col">
                          <a
                            href="javascript:void(0)"
                            class="pro-img mr-[12px] max-[767px]:mb-[15px] max-[767px]:mr-[0]"
                          >
                            <img
                              src={secure}
                              alt="new-product-2"
                              class="w-[80px] rounded-[10px] border-[1px] border-solid border-[#eee] max-[767px]:w-full"
                            />
                          </a>
                          <div class="side-contact flex flex-col">
                            <h4 class="bb-pro-title text-[15px]">
                              <a
                                href=""
                                class="transition-all duration-[0.3s] ease-in-out flex font-Poppins text-[13px] leading-[28px] tracking-[0.03rem] font-medium text-[#3d4750]"
                              >
                                Invest ₹60k/annum for 12 yrs
                              </a>
                            </h4>
                            <p class="font-Poppins font-light tracking-[0.03rem] mb-[8px] text-[14px] leading-[22px] text-[#686e7d]">
                              Get Rs. 74,700 every year for next 25 years
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="min-[992px]:w-[33.33%] min-[576px]:w-[50%] w-full px-[12px] mb-[24px]">
                        <div class="bb-category-cart p-[15px] overflow-hidden bg-[#f8f8fb] border-[1px] border-solid border-[#eee] rounded-[10px] flex max-[767px]:flex-col">
                          <a
                            href="javascript:void(0)"
                            class="pro-img mr-[12px] max-[767px]:mb-[15px] max-[767px]:mr-[0]"
                          >
                            <img
                              src={secureOne}
                              alt="new-product-2"
                              class="w-[80px] rounded-[10px] border-[1px] border-solid border-[#eee] max-[767px]:w-full"
                            />
                          </a>
                          <div class="side-contact flex flex-col">
                            <h4 class="bb-pro-title text-[15px]">
                              <a
                                href=""
                                class="transition-all duration-[0.3s] ease-in-out flex font-Poppins text-[15px] leading-[28px] tracking-[0.03rem] font-medium text-[#3d4750]"
                              >
                                6.9L for 25 years <br />+ Maturity 60L + Tax
                                Benefit
                              </a>
                            </h4>
                            <p class="font-Poppins font-light tracking-[0.03rem] mb-[8px] text-[14px] leading-[22px] text-[#686e7d]">
                              Total - 2.3 Cr Returns
                            </p>{" "}
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
