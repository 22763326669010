import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Select from "../../commonModules/InputFieldMultipleSelect";
import crmService from "../../../services/crm.service";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Validations from "../../../validations/validations";
import { useHistory, useParams } from "react-router-dom";
import { decryptData } from "../../../services/auth-service";

const BuyInsurance = (props) => {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [hide, setHide] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupForRedirect, setShowPopupForRedirect] = useState(false);
  const [lobValues, setlobValues] = useState();
  const lobValues1 = [
    { label: "Motor", value: "MOTOR" },
    { label: "Health", value: "HEALTH" },
    { label: "Life", value: "LIFE" },
  ];
  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      history.push("/login");
    }
    filterLob(user1);
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        if(user1.role!=="AGENT" && user1.role!=="MANAGER" && user1.role!=="POSP")
          history.push("/accessdenied");
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };
  useEffect(() => {
    filterLob(user);
  }, [user]);

  const filterLob = async (user1) => {
    
    if (user1?.role === "AGENT" || user1?.role === "MANAGER") {
      let lob = localStorage.getItem("LOB").split(",");
      let filteredLobValues = lobValues1.filter((item) =>
        lob.includes(item.value)
      );
      setlobValues(filteredLobValues);
    }
    if (user1?.role === "POSP") {
      setlobValues(lobValues1);
    }
  };

  const validationSchema = Validations.BuyInsuranceValidation;

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      caller_ID: "",
      RTO_no: "",
      lineOfBusiness: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = async () => {
    try {
      if (formik.values.RTO_no !== "") {
        await crmService
          .createLeadInMotor({
            customerName:
              formik.values.firstName + " " + formik.values.lastName,
            mobileNumber: formik.values.mobile,
            //caller_name: "NIB068",
            rtoNo: formik.values.RTO_no,
            emailId: formik.values.email,
          })
          .then((response) => {
            if (response.data.status === 200) {
              setShowPopup(true);
              toast.success("Lead Generated successfully");
            }
          })
          .catch((error)=>{
            toast.error("Error : ",error)
          })

        await crmService
          .createLeadInMotorLocal({
            customerName:
              formik.values.firstName + " " + formik.values.lastName,
            mobileNumber: formik.values.mobile,
            //caller_name: "NIB068",
            rtoRegNo: formik.values.RTO_no,
            module: "motor",
            callerName: sessionStorage.getItem("callerID"),
            lineOfBusiness: "MOTOR",
            createdBy: user.email,
            emailID: formik.values.email,
          })
          .then((res) => {
          })
          .catch((error)=>{
            toast.error("Error : ",error)
          })

          if (formik.values.lineOfBusiness === "HEALTH")
            window.location.href = "/health";
          if (formik.values.lineOfBusiness === "LIFE")
            window.location.href = "/termlife";
          if (formik.values.lineOfBusiness === "MOTOR") {
            setShowPopupForRedirect(true);
          }
      
        } else if (
        formik.values.lineOfBusiness === "MOTOR" &&
        formik.values.RTO_no === ""
      ) {
        alert("Please enter Vehicle Registration Number");
      } else {
        if (formik.values.lineOfBusiness === "HEALTH") {
          const response = await crmService
            .createLeadInHealth({
              customerName:
                formik.values.firstName + " " + formik.values.lastName,
              mobileNumber: formik.values.mobile,
              emailId: formik.values.email,
              //   caller_name: "NIB068",
            })
            .then((response) => {
              if (response.data.status === 200) {
                setShowPopup(true);
                toast.success("Lead Generated successfully");
              }
            })
            .catch((error)=>{
              toast.error("Error : ",error)
            })

          await crmService
            .createLeadInMotorLocal({
              customerName:
                formik.values.firstName + " " + formik.values.lastName,
              mobileNumber: formik.values.mobile,
              module: "health",
              callerName: sessionStorage.getItem("callerID"),
              lineOfBusiness: "HEALTH",
              createdBy: user.email,
              emailID: formik.values.email,
            })
            .then((res) => {
            })
            .catch((error)=>{
              toast.error("Error : ",error)
            })

            if (formik.values.lineOfBusiness === "HEALTH")
              window.location.href = "/health";
            if (formik.values.lineOfBusiness === "LIFE")
              window.location.href = "/termlife";
            if (formik.values.lineOfBusiness === "MOTOR") {
              setShowPopupForRedirect(true);
            }
        } else if (formik.values.lineOfBusiness === "LIFE") {
          const response = await crmService
            .createLeadInLife({
              customerName:
                formik.values.firstName + " " + formik.values.lastName,
              mobileNumber: formik.values.mobile,
              emailId: formik.values.email,
              //   caller_name: "NIB068",
            })
            .then((response) => {
              if (response.data.status === 200) {
                setShowPopup(true);
                toast.success("Lead Generated successfully");
              }
            });

          await crmService
            .createLeadInMotorLocal({
              customerName:
                formik.values.firstName + " " + formik.values.lastName,
              mobileNo: formik.values.mobile,
              module: "life",
              callerName: sessionStorage.getItem("callerID"),
              lineOfBusiness: "LIFE",
              createdBy: user.email,
              emailID: formik.values.email,
            })
            .then((res) => {
            });

            if (formik.values.lineOfBusiness === "HEALTH")
              window.location.href = "/health";
            if (formik.values.lineOfBusiness === "LIFE")
              window.location.href = "/termlife";
            if (formik.values.lineOfBusiness === "MOTOR") {
              setShowPopupForRedirect(true);
            }
        }
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const handleSkip = async () => {
    if (!formik.values.lineOfBusiness) {
      alert("Please select LOB");
      toast.error("Please select a Line of Business before proceeding.");
      return;
    }
    if (formik.values.lineOfBusiness === "HEALTH")
      window.location.href = "/health";
    if (formik.values.lineOfBusiness === "LIFE")
      window.location.href = "/termlife";
    if (formik.values.lineOfBusiness === "MOTOR") {
      setShowPopupForRedirect(true);
    }
  };

  useEffect(() => {
    // Add the "car-page" class to the body element when the component mounts
    document.body.classList.add("car-page");
    // Clean up function: Remove the "car-page" class when the component unmounts
    return () => {
      document.body.classList.remove("car-page");
    };
  }, []);

  const handleLobChange = (value) => {
    formik.setFieldValue("lineOfBusiness", value.value);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    if (formik.values.lineOfBusiness === "HEALTH")
      window.location.href = "/health";
    if (formik.values.lineOfBusiness === "LIFE")
      window.location.href = "/termlife";
    if (formik.values.lineOfBusiness === "MOTOR") {
      setShowPopupForRedirect(true);
    }
  };

  const [selectedOption, setSelectedOption] = useState("car"); // default selection

  const handleClosePopup1 = () => setShowPopupForRedirect(false);

  const handleOkButtonClick = () => {
    if (selectedOption === "car") {
      window.location.href = "/car";
    } else if (selectedOption === "bike") {
      window.location.href = "/bike";
    }
    handleClosePopup1();
  };

  return (
    <>
      <Container>
        <Row>
          <h4 className="mb-5 mt-5">Create Lead</h4>
          <Col className="mb-3">
            <Form className="form-bg" onSubmit={formik.handleSubmit}>
              <Row>
                <Col sm={12} md={6} className="pt-2">
                  <FloatingLabel controlId="firstName" label="First Name">
                    <Form.Control
                      name="firstName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      className="floating-input"
                      placeholder="First Name"
                    />
                  </FloatingLabel>
                  <Form.Text className="text-danger">
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div className="text-danger">
                        {formik.errors.firstName}
                      </div>
                    ) : null}
                  </Form.Text>
                </Col>
                <Col sm={12} md={6} className="pt-2">
                  <FloatingLabel controlId="lastName" label="Last Name">
                    <Form.Control
                      name="lastName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      className="floating-input"
                      placeholder="Last Name"
                    />
                  </FloatingLabel>
                  <Form.Text className="text-danger">
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div className="text-danger">
                        {formik.errors.lastName}
                      </div>
                    ) : null}
                  </Form.Text>
                </Col>
                <Col sm={12} md={6} className="pt-2">
                  <FloatingLabel controlId="email" label="Email">
                    <Form.Control
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="floating-input"
                      placeholder="Email"
                    />
                  </FloatingLabel>
                  <Form.Text className="text-danger">
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-danger">{formik.errors.email}</div>
                    ) : null}
                  </Form.Text>
                </Col>
                <Col sm={12} md={6} className="pt-2">
                  <FloatingLabel controlId="mobile" label="Mobile">
                    <Form.Control
                      name="mobile"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobile}
                      className="floating-input"
                      placeholder="Mobile Number"
                    />
                  </FloatingLabel>
                  <Form.Text className="text-danger">
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <div className="text-danger">{formik.errors.mobile}</div>
                    ) : null}
                  </Form.Text>
                </Col>
                <Col sm={12} md={6} className="pt-2">
                  <FloatingLabel>Line of Business</FloatingLabel>
                  <Select
                    options={lobValues}
                    onChange={handleLobChange}
                    isSearchable={false}
                    value={formik.values.lineOfBusiness}
                  />
                  <Form.Text className="text-danger">
                    {formik.touched.lineOfBusiness &&
                    formik.errors.lineOfBusiness ? (
                      <div className="text-danger">
                        {formik.errors.lineOfBusiness}
                      </div>
                    ) : null}
                  </Form.Text>
                </Col>
                {formik.values.lineOfBusiness === "MOTOR" && (
                  <Col sm={12} md={6} className="pt-2">
                    <FloatingLabel
                      controlId="RTO_no"
                      label="Vehicle Registration Number"
                    >
                      <Form.Control
                        name="RTO_no"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.RTO_no}
                        className="floating-input"
                        placeholder="Vehicle Registration Number"
                      />
                    </FloatingLabel>
                    <Form.Text className="text-danger">
                      {formik.touched.firstName && formik.errors.RTO_no ? (
                        <div className="text-danger">
                          {formik.errors.RTO_no}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Col>
                )}
                <Col sm={12} md={12} className="d-flex pt-4"></Col>
              </Row>
              <Button
                className="primary-btn"
                style={{
                  alignItems: "right",
                  marginRight: "5px",
                  width: "200px",
                }}
                type="submit"
                onClick={formik.handleSubmit}
              >
                Create Lead and Proceed
              </Button>
              {user?.role === "AGENT" ? null : (
                <Button
                  type="button"
                  className="btn-secondary"
                  onClick={handleSkip}
                >
                  Skip and Proceed
                </Button>
              )}
            </Form>
          </Col>
        </Row>
      </Container>

      {showPopup && (
        <Modal show={showPopup} onHide={handleClosePopup}>
          <Modal.Header closeButton>
            <Modal.Title>Lead was created successfully.</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              type="button"
              className="primary-btn"
              onClick={handleClosePopup}
            >
              Okay!
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal show={showPopupForRedirect} onHide={handleClosePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Please Select</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Check
              type="radio"
              id="carRadio"
              label="Car"
              value="car"
              checked={selectedOption === "car"}
              onChange={() => setSelectedOption("car")}
            />
            <Form.Check
              type="radio"
              id="bikeRadio"
              label="Bike"
              value="bike"
              checked={selectedOption === "bike"}
              onChange={() => setSelectedOption("bike")}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>
            Close
          </Button>
          <Button className="primary-btn" onClick={handleOkButtonClick}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BuyInsurance;
