import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContainerForLeadUpdate from "../CommonComponents/ContainerForLeadUpdate";
import { useHistory, useParams } from "react-router-dom";
import { decryptData } from "../../../services/auth-service";
import { use } from "react";

export default function UpdateLead() {
const id =useParams();
console.log("dycrpt",id)
// const id=leadParams.id.split("-")[1]
  const history = useHistory();
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        if(user1.role!=="AGENT" && user1.role!=="MANAGER" && user1.role!=="POSP")
          history.push("/accessdenied");
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <h4 className="mb-5 mt-5">Update Lead Details</h4>

          <Col className="mb-3">
            <ContainerForLeadUpdate id={id}/>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}