// import "./App.css";
import { useState, useEffect } from 'react'
import { Route, } from "react-router-dom";
import { routesArray } from "../routing/RoutingPaths";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../src/style.scss";
import { MyProfile } from "../components/pages/MyProfile";
import { MyProfileAdmin } from "../components/pages/MyprofileAdmin";
import { useLocation } from "react-router";
import UseFormContext from "../context/UseFormContext";
import {PaymentSuccess} from "../components/pages/Payments/PaymentSuccess"
import {PaymentSuccessAdmin} from "../components/pages/Payments/PaymentSuccessAdmin"

export function RoutingMap() {

    const [userType, setuserType] = useState(sessionStorage.getItem("isAdmin"));
    let location = useLocation();
    const formContext = UseFormContext();

    useEffect(() => {
    }, [formContext.loginType]);
    return (<>
        {routesArray.map((route, index) => (<>
            
            <Route
                path={route.path}
                exact={route.exact}
                component={route?.componentName === "MyProfile"
                    ? (sessionStorage.getItem("isAdmin") === "yes" ? MyProfileAdmin : MyProfile)
                    : route?.componentName === "PaymentSuccess"
                        ? (sessionStorage.getItem("isAdmin") === "yes" ? PaymentSuccessAdmin : PaymentSuccess)
                        : route.component}
                key={index}
            />
        </>
        ))
        }
    </>


    );
}

export default RoutingMap;
