import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

// Initialize Google Analytics
ReactGA.initialize("G-2JD6WYDX0P"); // Replace with your tracking ID

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

export default usePageTracking;