import BikesPage from "../components/pages/Bikes/BikePage";
import CarPage from "../components/pages/Cars/CarPage";
import HealthPage from "../components/pages/Health/HealthPage";
import Home from "../components/pages/Home";
import ProposalPage from "../components/pages/ProposalPage";
import QuotesPage from "../components/pages/QuotesPage";
import SignIn from "../components/pages/SignIn";
import Login from "../components/pages/Login";
import Contact from "../components/pages/contact";
import PaymentSuccessFailure from "../components/pages/PaymentSuccessFailure";
import { MyProfile } from "../components/pages/MyProfile";
import { MyProfileAdmin } from "../components/pages/MyprofileAdmin";
import { PaymentSuccess } from "../components/pages/Payments/PaymentSuccess";
import { PaymentFailed } from "../components/pages/Payments/PaymentFailed";
import { PaymentRedirect } from "../components/pages/Payments/PaymentRedirect";
import { FutureQuotesPayment } from "../components/pages/Payments/FutureQuotesPayment";
import { HDFCQuotesPayment } from "../components/pages/Payments/HDFCQuotesPayment";
import AddAgent from "../components/pages/Manager/AddAgent";
import AddBranch from "../components/pages/Admin/AddBranch";
import ResetPassword from "../components/pages/ResetPassword";
import AddAgency from "../components/pages/Admin/AddAgency";
import BuyInsurance from "../components/pages/Agent/BuyInsurance";
import MyCustomers from "../components/pages/Agent/Mycustomers";
import ListOfAgent from "../components/pages/Manager/ListOfAgent";
import ViewAgent from "../components/pages/Admin/ViewAgent";
import ViewBranch from "../components/pages/Admin/ViewBranch";
import ListBranch from "../components/pages/Admin/ListBranch";
import ListAgency from "../components/pages/Admin/ListAgency";
import ViewAgency from "../components/pages/Admin/ViewAgency";
import OnBoardingPage from "../components/pages/POSP/OnBoardingPage";
import HealthQuote from "../components/pages/Health/HealthQuote";
import HealthProposal from "../components/pages/Health/HealthProposal";
import UpdatePassword from "../components/pages/UpdatePassword";
import AccessDenied from "../components/pages/AccessDenied";
import decryptData from "../services/auth-service";
import ViewLead from "../components/pages/Agent/ViewLeadslist"
import GetLeadInfo from "../components/pages/Agent/GetLeadInfo"
import { components } from "react-select";
import ListQuoteOrPolicies from "../components/pages/Agent/ListQuoteOrPolicies"
import UpdateLead from "../components/pages/Agent/UpdateLeadInfo"
import ProposalPageForRedirection from "../components/pages/Agent/ProposalPageForRedirection"
import DownloadFileFromCRM from "../components/pages/DownloadFileFromCRM";
import ListPospUsers from "../components/pages/Admin/ListPospUsers"
import ViewPosp from "../components/pages/Admin/ViewPosp"
import Travel from "../components/pages/Travel/Travel";
import TermLife from "../components/pages/TermLife/TermLife";
import About from "../components/pages/About";
import Career from "../components/pages/Career";
import PrivacyPolicy from "../components/pages/PrivacyPolicy";
import Disclaimer from "../components/pages/Disclaimer";
import Security from "../components/pages/security";
import TermsAndConditions from "../components/pages/TermsAndConditions";
import GrievanceRedressalPolicy from "../components/pages/GrievanceRedressalPolicy";
import Testimonials from "../components/pages/Testimonial";
import Business from "../components/pages/Business/Business";
import Thankyou from "../components/pages/ThankYou";


export const routesArray = [
  {
    path: "/bike/:id?",
    component: BikesPage,//all
    exact: true,
  },
  {
    path: "/",
    component: Home,//all
    exact: true,
  },
  {
    path: "/car/:id?",
    component: CarPage,//all
    exact: true,
  },
  {
    path: "/quotes/:id?",
    component: QuotesPage,//all
    exact: true,
  },
  {
    path: "/signin",
    component: SignIn,//all
    exact: true,
  },
  {
    path: "/login",
    component: Login,//all
    exact: true,
  },
  {
    path: "/proposal/:id?",
    component: ProposalPage,//all
    exact: true,
  },
  {
    path: "/proposal/:id/:proposalID",
    component: ProposalPage,//all
    exact: true,
  },
  {
    path: "/home",
    component: Home,//all
    exact: true,
  },
  {
    path: "/contact",
    component: Contact,//all
    exact: true,
  },
  {
    path: "/health-Thankyou",
    component: Thankyou ,//all
    exact: true,
  },
  {
    path: "/life-Thankyou",
    component: Thankyou ,//all
    exact: true,
  },
  {
    path: "/travel-Thankyou",
    component: Thankyou ,//all
    exact: true,
  },
  {
    path: "/business-Thankyou",
    component: Thankyou ,//all
    exact: true,
  },
  {
    path: "/about",
    component: About,//all
    exact: true,
  },
  {
    path: "/career",
    component: Career,//all
    exact: true,
  },
  {
    path: "/privacyPolicy",
    component: PrivacyPolicy,//all
    exact: true,
  },
  {
    path: "/disclaimer",
    component: Disclaimer,//all
    exact: true,
  },
  {
    path: "/security",
    component: Security,//all
    exact: true,
  },
  {
    path: "/termsAndConditions",
    component: TermsAndConditions,//all
    exact: true,
  },
  {
    path: "/grievanceRedressalPolicy",
    component: GrievanceRedressalPolicy,//all
    exact: true,
  },
  {
    path: "/paymentStatus",
    component: PaymentSuccessFailure,//all
    exact: true,
  },
  {
    path: "/paymentStatus",
    component: PaymentSuccessFailure,//all
    exact: true,
  },
  {
    path: "/myaccount/:id?",
    component: MyProfile,//all
    exact: true,
    componentName:"MyProfile"
  },
  {
    path: "/myaccount/:tab",
    component: MyProfile,//all
    exact: true,
    componentName:"MyProfile"

  },
  {
    path: "/paymentSuccess",
    component: PaymentSuccess,//all
    exact: true,
  },
  {
    path: "/paymentFailed/:id?",
    component: PaymentFailed,//all
    exact: true,
  },
  {
    path: "/paymentRedirect",
    component: PaymentRedirect,//all
    exact: true,
  },
  {
    path: "/futureinsurance",
    component: FutureQuotesPayment,//all
    exact: true,
  },
  {
    path: "/hdfcinsurance",
    component: HDFCQuotesPayment,//all
    exact: true,
  },
  {
    path: "/resetPassword",
    component: ResetPassword,//all
    exact: true,
  },
  {
    path: "/updatePassword",
    component: UpdatePassword,//all

    exact: true,
  },
  {
    path: "/addAgent",
    component: AddAgent,//admin,manager
    exact: true,
  },

  {
    path: "/addbranch",
    component: AddBranch,//admin,manager
  },
  {
    path: "/addAgency",
    component: AddAgency,//admin

    exact: true,
  },
  {
    path: "/myCustomers",
    component: MyCustomers,//agent , manager

    exact: true,
  },

  {
    path: "/listOfAgents",
    component: ListOfAgent,//admin, manager

    exact: true,
  },
  {
    path: "/listOfBranch",
    component: ListBranch,//admin,manager

    exact: true,
  },
  {
    path: "/ListAgency",
    component: ListAgency,//admin
    exact: true,
  },
  {
    path: "/viewAgent/:email",
    component: ViewAgent,//admin,manager
    exact: true,
  },
  {
    path: "/viewBranch/:id",
    component: ViewBranch,//admin,manager
    exact: true,
  },
  {
    path: "/viewAgency/:id",
    component: ViewAgency,//admin
    exact: true,
  },
  {
    path: "/buyInsurance",
    component: BuyInsurance,//agent, ,manager, posp
    exact: true,
  },

  {
    path: "/OnBoardingPage",
    component: OnBoardingPage,//customer
    exact: true,
  },
  {
    path: "/healthQuotes",
    component: HealthQuote,//all
    exact: true,
  },
  {
    path: "/health/:id?",
    component: HealthPage,//all
    exact: true,
  },
  {
    path: "/healthProposal",
    component: HealthProposal,//all
    exact: true,
  },
  {
    path: "/viewLead",
    component: ViewLead,//Agent, manager, posp
    exact: true
  },
  {
    path: "/getLeadInfo",
    component: GetLeadInfo,//agent, manager, posp
    exact: true
  },
  {
    path:"/updateLead/:id",
    component: UpdateLead,//agent, manager, posp
    exact: true
  },
  {
    path:"/ListQuoteOrPolicies",
    component: ListQuoteOrPolicies,//all
    exact: true
  },
  {
    path:"/ListQuoteOrPolicies#policies/id",
    component: ListQuoteOrPolicies,//all
    exact:true
  },
  {
    path:"/dowloadFileForCRM/:mobileNo/:pKey",
    component: DownloadFileFromCRM,//all
    exact: true
  },
  {
    path: "/pospusers",
    component: ListPospUsers,//all
    exact: true
  },
  {
    path:"/viewPosp/:email",
    component: ViewPosp,//all
    exact:true
  },
{
  path:"/termlife",
  component: TermLife,//all
  exact:true
},
{
  path:"/travel",
  component: Travel,//all
  exact:true
},
{
  path:"/testimonials",
  component: Testimonials,//all
  exact:true
},
{
  path:"/business",
  component: Business,//all
  exact: true
},
{
  path:"/accessdenied",
  component: AccessDenied,
  exact: true
}

];

export const routesArrayForCustomer = [
  {
    path: "/home",
    heading: "Home",
    type: "home",
  },
  {
    path: "/car",
    heading: "Car",
    type: "car",
  },
  {
    path: "/bike",
    heading: "Bike",
    type: "bike",
  },
  {
    path: "/health",
    heading: "Health",
    type: "health",
  },
  {
    path: "/termlife",
    heading: "Term Life",
    type: "termlife",
  },
  {
    path: "/travel",
    heading: "Travel",
    type: "travel",
  },
  {
    path: "/business",
    heading: "Business",
    type: "business",
  },
  {
    path: "/myaccount",
    heading: "Login",
    type: "myaccount",
  },
  {
    path: '/onBoardingPage',
    heading: "Join Us As Agent",
    type: "agencylogin"
  },
  {
    path: '/Thankyou',
    type: "thankyou"
  }
  

];

export const routesArrayForCustomerAfterLogin = [
  {
    path: "/home",
    heading: "Home",
    type: "home",
  },
  {
    path: "/car",
    heading: "Car",
    type: "car",
  },
  {
    path: "/bike",
    heading: "Bike",
    type: "bike",
  },
  {
    path: "/health",
    heading: "Health",
    type: "health",
  },
  {
    path: "/termlife",
    heading: "Term Life",
    type: "termlife",
  },
  {
    path: "/travel",
    heading: "Travel",
    type: "travel",
  },
  {
    path: "/myaccount",
    heading: "My Account",
    type: "myaccount",
  },
  {
    path: '/onBoardingPage',
    heading: "Join Us As Agent",
    type: "agencylogin"
  }
];

export const routesArrayForAdmin = [
  {
    path: "/home",
    heading: "Home",
    type: "home",
  },
  {
    path: "/ListAgency",
    heading: "Agencies",
    type: "agencies",
  },
  {
    path: "/listOfBranch",
    heading: "Branches",
    type: "branches",
  },
  {
    path: "/listOfAgents",
    heading: "Agents",
    type: "agents",
  },
  // {
  //   path: "/buyinsurance",
  //   heading: "Buy Insurance",
  //   type: "buyinsurance",
  // },
  {
    path: "/pospusers",
    heading: "POSP Users",
    type: "pospusers",
  },
  {
    path: "/myaccount",
    heading: "My Account",
    type: "myaccount",
  },
];

export const routesArrayForManager = [
  {
    path: "/home",
    heading: "Home",
    type: "home",
  },
  {
    path: "/ListAgency",
    heading: "Agencies",
    type: "agencies",
  },
  {
    path: "/listOfBranch",
    heading: "Branches",
    type: "branches",
  },
  {
    path: "/listOfAgents",
    heading: "Agents",
    type: "agents",
  },
  {
    path: "/buyinsurance",
    heading: "Buy Insurance",
    type: "buyinsurance",
  },
  // {
  //   path: "/activitylog",
  //   heading: "Activity Log",
  //   type: "activitylog",
  // },
  {
    path: "/mycustomers",
    heading: "My Customers",
    type: "mycustomers",
  },
  {
    path: "/myaccount",
    heading: "My Account",
    type: "myaccount",
  },
];

export const routesArrayForAgent = [

  {
    path: "/dashboard",
    heading: "DashBoard",
    type: "dashboard",
  },
  {
    path: "/buyinsurance",
    heading: "Buy Insurance",
    type: "buyinsurance",
  },
  // {
  //   path: "/getquotes",
  //   heading: "Get Quotes",
  //   type: "getquotes",
  // },
  // {
  //   path:"/viewLead",
  //   heading: "View Leads",
  //   type: "viewleads",
  // },
  // {
  //   path: "/pendingproposal",
  //   heading: "Pending Proposal",
  //   type: "pendingproposal",
  // },
  // {
  //   path: "/pendingpayment",
  //   heading: "Pending Payment",
  //   type: "pendingpayment",
  // },
  {
    path: "/mycustomers",
    heading: "My Customers",
    type: "mycustomers",
  },
  {
    path: "/myaccount",
    heading: "My Account",
    type: "myaccount",
  }
];

export const routesArrayForPosp = [

  {
    path: "/car",
    heading: "Car",
    type: "car",
  },
  {
    path: "/bike",
    heading: "Bike",
    type: "bike",
  },
  {
    path: "/health",
    heading: "Health",
    type: "health",
  },
  {
    path: "/termlife",
    heading: "Term Life",
    type: "termlife",
  },
  {
    path: "/travel",
    heading: "Travel",
    type: "travel",
  },
  {
    path: "/buyinsurance",
    heading: "Buy Insurance",
    type: "buyinsurance",
  },
  {
    path: "/mycustomers",
    heading: "My Customers",
    type: "mycustomers",
  },
  {
    path: "/myaccount",
    heading: "My Account",
    type: "myaccount",
  }
];

export const routesArrayForAgentLobMotor = [
  {
    path: "/car",
    heading: "Car",
    type: "car",
  },
  {
    path: "/bike",
    heading: "Bike",
    type: "bike",
  },
];

export const routesArrayForAgentLobHealth = [
  {
    path: "/health",
    heading: "Health",
    type: "health",
  },
];

export const routesArrayForAgentLobLife = [
  {
    path: "/termlife",
    heading: "Term Life",
    type: "termlife",
  },
];
