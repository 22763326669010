import axios from "axios";
import CryptoJS from "crypto-js";

const AGENCY_URL = process.env.REACT_APP_AGENCY_URL;
const REACT_AGENCY_TOKEN = process.env.REACT_APP_AGENCY_TOKEN_URL;
const username = process.env.REACT_APP_CRM_API_USERNAME;
const password = process.env.REACT_APP_CRM_API_PASSWORD;
const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

//alert(encryptionKey);

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
};

export const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

class AuthService {
  onBoarding(data) {
    return axios.post(AGENCY_URL + "/createUpdatePospUser", data);
  }

  login(username, password, loginAs) {
    return axios
      .post(AGENCY_URL + "/Authentication", { username, password, loginAs })
      .then((response) => {
        
        if (response.data.data.jwtToken) {
          const encryptedUser = encryptData(response.data.data);
          
          if(response.data.data.imCode && response.data.data.imCode!=="NA"){
            localStorage.setItem("dealId",response.data.data.imCode)
          }
         
          localStorage.setItem("user", encryptedUser);
           window.location.href = "/";
        }
        return response.data.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  generatePassword(data) {
    return axios.post(AGENCY_URL + "/ForgotPassword/generate-otp", data);
  }

  forgotPassword(data) {
    return axios.post(AGENCY_URL + "/ForgotPassword", data);
  }

  CRMLogin() {
    return axios
      .post(`${REACT_AGENCY_TOKEN}/get_token`, { username, password })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("CRMtoken", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("CRM Login error:", error);
        throw error; // Rethrow the error for further handling
      });
  }

  CRMLogout() {
    localStorage.removeItem("CRMtoken");
  }
}

export default new AuthService();
