import axios from "axios";
import AuthHeader from "./auth-header";

const AGENCY_URL = process.env.REACT_APP_AGENCY_URL;
class AdhocService {

  addLead(addLeadRequest) {
    return axios.post(`${AGENCY_URL}/createCustomerReachabilityInfo`, addLeadRequest);
  }
  getLead(){
    return axios.get(`${AGENCY_URL}/listCustomerReachabilityInfo`, {
      headers: AuthHeader(),
    });
  }
}



export default new AdhocService();
